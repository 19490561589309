import {IValidator} from "./IValidator";

export class ModelSuffixValidator extends IValidator<string> {
    isValid(value: string): boolean {
        const max = 500;
        if (value.length > max) {
            this.message = `Max atteint ${value.length} / ${max}`
            return false;
        }

        return true
    }
}
import React, {PropsWithChildren} from 'react';
import {Bot, Info, TriangleAlert} from 'lucide-react';
import {AlertType} from "../../type/component/alert-type";
import {Divider} from "../divider/Divider";

type AlertProps =  {
    type?: AlertType;
    title: string;
    message?: string
} & PropsWithChildren

export const Alert = ({type = 'neutral', title, message, children = null}: AlertProps) => {
    const typeColorMapping = {
        info: 'blue-500',
        success: 'green-500',
        warning: 'red-500',
        neutral: 'black-500',
    }

    const textColor = `text-${typeColorMapping[type]}`
    const bgColor = `bg-white`
    const borderColor = `border-${typeColorMapping[type]}/30`


    const Icon = () => {
        const className = `mr-4 fi ${textColor}/90`
        if (type === 'warning') {
            return <TriangleAlert className={className}/>
        }

        if (type === 'info') {
            return <Info className={className}/>
        }

        if (type === 'success') {
            return <Bot className={className}/>
        }

        return <></>
    }

    const hasContent = children || message;

    return <div role="alert" className={`relative w-full rounded-lg border p-4 ${borderColor} ${bgColor} ${textColor}`}>
        <div className={'flex items-center'}>
            {<Icon/>}
            <h5 className={`font-medium leading-none tracking-tight text-${textColor}`}>{title}</h5>
        </div>
        {hasContent && <Divider direction={"x"} size={'xs'}/>}
        {hasContent && <div className={`text-sm [&amp;_p]:leading-relaxed ${textColor}`}>{children || message}</div>}
    </div>
};
import {createSlice} from '@reduxjs/toolkit'

interface LoadingSliceState {
    value: boolean,
}

const initialState: LoadingSliceState = {
    value: false,
}


export const loadingSlice = createSlice({
    name: 'authLoading',
    initialState: initialState,
    reducers: {
        active: (state) => ({
            value : true
        }),
        inactive: (state) => ({
            value : false
        }),
        reset: () => initialState,
    },
})
import React from 'react';
import {CreditInput} from "./component/CreditInput";
import {Divider} from "../../../component/divider/Divider";

type CreditPageProps = {}

export const CreditPage = (props: CreditPageProps) => {
    return <div className={'flex'}>
        <CreditInput />
        <Divider direction={'y'} size={'xs'} />
    </div>
};
import {AppClient} from "../AppClient";
import {ListItemModel} from "../../../../model/Model";

type Input = {
    id: string
}

type OutputData = {
    value: ListItemModel;
};

export class AppGetModelClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`ai-model/${params.id}`), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


export enum AppCodeException {
    // Auth
    USER_EMAIL_NOT_FOUND = '10',
    ACCOUNT_DELETED = '11',
    BAD_CREDENTIAL = '12',

    MODEL_NOT_FOUND_FOR_USER = '30',
    TOPICS_REQUIRED = '32',

    ACCOUNT_ALREADY_EXIST = '13',
    UNKNOWN = 'UNKNOWN',
    INSUFFICIENT_CREDIT = '51',


    CLIENT_ERROR = -1,
    CLIENT_TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    CLIENT_TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
    CLIENT_NO_CREDIT_GIVEN = 'NO_CREDIT_GIVEN',

    IDENTITY_NO_FOUND = '80',
}
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {feedbackSlice} from "../../feature/feedback/feedbackSlice";
import {AppCreateFeedbackClient} from "../../../http/api/app/feedback/AppCreateFeedbackClient";

export const createFeedBackThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState()
    const feedbackState = state.feedbackSlice

    if (feedbackState.value === null || feedbackState.loader === 'loading') {
        return;
    }

    dispatch(feedbackSlice.actions.handleLoader('loading'))

    const client = new AppCreateFeedbackClient();
    const response = await client.handleWithCredential({
        content: feedbackState.value,
    })

    dispatch(feedbackSlice.actions.handleLoader('idle'))

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: "Mince feedback n'a pas pu être enregistré, vous pouvez si vous le souhaiter nous l'adresser par email",
            type: 'warning',
        })
        return;
    }

    ToastHelper.add({
        content: "Merci beaucoup, vos feedback nous sont d'une grande aide",
        type: 'success',
    })

    dispatch(feedbackSlice.actions.reset())
}
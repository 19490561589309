import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState, LoadingStateWithoutLoaded} from "../../../type/component/loading-state";
import {FeedbackSliceState} from "../feedback/feedbackSlice";


export interface ManualTopicSliceState {
    loading: LoadingState;
    value: string|null;
    open: boolean,
}

const initialState: ManualTopicSliceState = {
    loading: 'idle',
    value: null,
    open: false,
}

export const manualTopicSlice = createSlice({
    name: 'manualTopic',
    initialState: initialState,
    reducers: {
        handleLoading: (state, action: PayloadAction<ManualTopicSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleValue: (state, action: PayloadAction<ManualTopicSliceState['value']>) => {
            state.value = action.payload
        },
        handleOpen: (state, action: PayloadAction<FeedbackSliceState['open']>) => {
            state.open = action.payload
        },
        reset: () => initialState
    },
})
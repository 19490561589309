import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {CountListItem, CountSliceState} from "../helper/count-helper";

const initialState: CountSliceState = {
    values: [],
}

export const postCountSlice = createSlice({
    name: 'postCount',
    initialState: initialState,
    reducers: {
        addItem: (state , action: PayloadAction<CountListItem>) => {
            state.values = state.values.concat(action.payload)
        },
        handleItem: (state, action: PayloadAction<CountListItem>) => {
            const index = state.values.findIndex(item => item.id === action.payload.id)

            if (index < 0) {
                return;
            }

            state.values[index] = action.payload;
        },
        handleItemLoad: (state, action: PayloadAction<{
            model: string;
            loading: LoadingState;
        }>) => {
            const index = state.values.findIndex(item => item.id === action.payload.model)

            if (index < 0) {
                return;
            }

            state.values[index].loading = action.payload.loading;
        },
        handleItemValue: (state, action: PayloadAction<{
            model: string;
            count: CountListItem['count'];
        }>) => {
            const index = state.values.findIndex(item => item.id === action.payload.model)

            if (index < 0) {
                return;
            }

            state.values[index].count = action.payload.count;
        },
    },
})
import React from 'react';
import {Loader} from "../loader/Loader";
import {LoadingState} from "../../type/component/loading-state";

type ListLoaderProps = {
    loader: LoadingState
}
export const ListLoader = ({loader}: ListLoaderProps) => {

    if (
        loader !== 'loading'
    ) {
        return <></>
    }

    return <div className={"fixed center inset-0 justify-center"}>
            <Loader/>
    </div>
};
import React from 'react';
import {useRouteError} from "react-router-dom";
import {isRouteErrorResponse} from "react-router-dom";
import {Title} from "component/title/Title";
import {Paragraph} from "component/paragraph/Paragraph";

type ErrorPageProps = {}

export const ErrorPage = (props: ErrorPageProps) => {
    const error = useRouteError()
    if (isRouteErrorResponse(error)) {
        return <div className={'center flex-col min-h-screen'}>
            <Title className={'text-center'}>Oops!</Title>
            {error.data && <Paragraph className={'text-center font-light'}>{error.statusText}</Paragraph>}
        </div>
    }

    return <div className={'center flex-col min-h-screen'}>
        <Title className={'text-center'}>Oops!</Title>
        <Paragraph className={'text-center font-light'}>
            Sorry, an unexpected error has occurred.
        </Paragraph>
    </div>

};
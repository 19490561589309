import React from 'react';
import {useAppCredit} from "../../hook/useAppCredit";
import {Card} from "../card/Card";

type NegativeCreditProps = {}

export const NegativeCredit = (props: NegativeCreditProps) => {
    const credit = useAppCredit()

    if (!credit.hasNegativeCredit) {
        return <></>
    }

    return <div className={"fixed inset-0 bg-white center"}>
        <Card base={{className: "max-w-96"}}>
            Votre solde de crédit est négatif. Si vous pensez que c'est une erreur veuillez nous contacter <a
                className={'text-blue-600 underline font-medium cursor-pointer'}
                target="_blank"
                href={`https://instagram.com/anexdotcom`}
            >ici</a>
        </Card>
    </div>
};
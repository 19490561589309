import React from 'react';
import {LucideInfo} from "lucide-react";
import {Divider} from "../../../../component/divider/Divider";
import {Link} from "../../../../component/link/Link";
import {routerPath} from "../../../routerPath";

type NoCreditProps = {}

export const NoCredit = (props: NoCreditProps) => {
    return <div className={'center h-96'}>
        <LucideInfo/>
        <Divider direction={'y'} size={'xs'}/>
        <i>Crédit insuffisant, pour recharger , c'est <Link
            href={routerPath.credit.base}>ici</Link></i>
    </div>
};
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {listModelsThunk} from "../../../../redux/thunk/model/list-models-thunk";
import {Divider} from "../../../../component/divider/Divider";
import {LucideInfo} from "lucide-react";
import {Link} from "../../../../component/link/Link";
import {routerPath} from "../../../routerPath";
import {ModelListLoader} from "./ModelListLoader";
import {ModelListItem} from "./ModelListItem";
import {ListView} from "../../../../component/list-view/ListView";

type ListProps = {}

export const ModelsList = (props: ListProps) => {
    const models = useAppSelector(state => state.modelsListSlice.values)
    const loading = useAppSelector(state => state.modelsListSlice.loading)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(listModelsThunk)
    }, [dispatch]);

    return <div className={'h-full pr-4'}>
        <ListView>
            {models.map((model, index) => <ModelListItem key={index} model={model}/>)}
        </ListView>

        <ModelListLoader/>

        {(loading === 'loaded' && models.length === 0) && <div className={'center h-96'}>
            <LucideInfo/>
            <Divider direction={'y'} size={'xs'}/>
            <i>Aucun assistant n'a été configuré, vous pouvez le faire <Link
                href={routerPath.model.create}>ici</Link></i>
        </div>}

    </div>
};
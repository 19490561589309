import {combineReducers} from "@reduxjs/toolkit";
import {emailSlice} from "./feature/auth/emailSlice";
import {usernameSlice} from "./feature/auth/usernameSlice";
import {passwordSlice} from "./feature/auth/passwordSlice";
import {passwordConfirmSlice} from "./feature/auth/passwordConfirmSlice";
import {loadingSlice} from "./feature/auth/loadingSlice";
import {modelSlice} from "./feature/model/modelSlice";
import {creditCountSlice} from "./feature/credit/creditCountSlice";
import {modelsListSlice} from "./feature/model/modelsListSlice";
import {topicsListsSlice} from "./feature/topic/topicsListsSlice";
import {postsListsSlice} from "./feature/post/postsListsSlice";
import {manualTopicSlice} from "./feature/topic/manualTopicValueSlice";
import {activeModelSlice} from "./feature/model/activeModelSlice";
import {seedsListsSlice} from "./feature/seed/seedsListsSlice";
import {addSeedValueSlice} from "./feature/seed/addSeedValueSlice";
import {optionActionSlice} from "./feature/optionActionSlice";
import {approachesListsSlice} from "./feature/approach/approachesListsSlice";
import {addApproachValueSlice} from "./feature/approach/addApproachValueSlice";
import {furthersInformationListsSlice} from "./feature/further-information/furthersInformationListsSlice";
import {approachCountSlice} from "./feature/approach/approchCountSlice";
import {furtherInformationCountSlice} from "./feature/further-information/furtherInformationCountSlice";
import {modelCountSlice} from "./feature/model/modelCountSlice";
import {postCountSlice} from "./feature/post/postCountSlice";
import {seedCountSlice} from "./feature/seed/seedCountSlice";
import {topicCountSlice} from "./feature/topic/topicCountSlice";
import {feedbackSlice} from "./feature/feedback/feedbackSlice";
import {verificationSlice} from "./feature/verification/verificationStatusSlice";

export const rootReducer = combineReducers({
    userEmailSlice: emailSlice.reducer,
    usernameSlice: usernameSlice.reducer,
    passwordSlice: passwordSlice.reducer,
    passwordConfirmSlice: passwordConfirmSlice.reducer,
    loadingSlice: loadingSlice.reducer,
    modelSlice: modelSlice.reducer,
    creditCountSlice: creditCountSlice.reducer,
    modelsListSlice: modelsListSlice.reducer,
    seedsListsSlice: seedsListsSlice.reducer,
    topicsListsSlice: topicsListsSlice.reducer,
    furthersInformationListsSlice: furthersInformationListsSlice.reducer,
    approachesListsSlice: approachesListsSlice.reducer,
    addApproachValueSlice: addApproachValueSlice.reducer,
    postsListsSlice: postsListsSlice.reducer,
    manualTopicSlice: manualTopicSlice.reducer,
    activeModelSlice: activeModelSlice.reducer,
    addSeedValueSlice: addSeedValueSlice.reducer,
    optionActionSlice: optionActionSlice.reducer,
    approachCountSlice: approachCountSlice.reducer,
    furtherInformationCountSlice: furtherInformationCountSlice.reducer,
    modelCountSlice: modelCountSlice.reducer,
    postCountSlice: postCountSlice.reducer,
    seedCountSlice: seedCountSlice.reducer,
    topicCountSlice: topicCountSlice.reducer,
    feedbackSlice: feedbackSlice.reducer,
    verificationSlice: verificationSlice.reducer
})
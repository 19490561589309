import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux'
import {rootReducer} from "./reducer";

export const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['furthersInformationLists/handleItems', 'postsLists/handleItem', 'postsLists/handleItems'],
                ignoredActionPaths: ['payload.value.createdAt'],
                ignoredPaths: ['furthersInformationListsSlice.values', 'payload.value', 'postsListsSlice.lists'],
            },
        })

    }
)

createListenerMiddleware()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

import React from 'react';
import {NativeProps} from "../../type/component/native-props";
import {InputMessage} from "../input-message/InputMessage";

type InputProps = NativeProps<{
    label?: string;
    errorMessage?: string | null;
}, React.InputHTMLAttributes<HTMLInputElement>>

export const Input = (props: InputProps) => {
    return <div>
        {props.custom?.label && <label htmlFor={props?.base?.name}
                className={`block text-sm font-medium leading-6 ${props.base?.disabled ? 'text-gray-500' : 'text-gray-900'}`}>{props.custom?.label}</label>}
        <div className="relative mt-2 rounded-md shadow-sm">
            <input
                {...props.base}
                id={props?.base?.name}
                className="block w-full rounded-md border-0 py-1.5 pl-5 pr-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
        {props.custom?.errorMessage !== null  && <InputMessage>{props.custom?.errorMessage}</InputMessage>}
    </div>
};
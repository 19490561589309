import React, {CSSProperties, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {CircleX} from "lucide-react";
import {Divider} from "../divider/Divider";

type SideModalProps = { open: boolean; onClose?: () => void } & PropsWithChildren

export const SideModal = (props: SideModalProps) => {
    const [open, setOpen] = useState<boolean>();
    const timeoutRef = useRef<any>(null);
    const mountedRef = useRef<boolean>(false);

    useEffect(() => {
        if (mountedRef.current) return;

        timeoutRef.current = onDelayClose();

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, []);


    useEffect(() => {
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setOpen(props.open)
    }, [props.open]);

    const computeOpenModal = () => {
        const style: CSSProperties = {}
        style.transition = 'transform 0.2s ease-out 0.1s'

        if (open) {
            style.transform = 'translateX(0)';
        } else {
            style.transform = 'translateX(105%)';
        }
        return style
    }


    const computeOverlay = () => {
        const style: CSSProperties = {}
        style.transition = ['transform 0.2s ease-out', 'backgroundColor 0.2s ease-out 0.1s'].join(', ');

        if (open) {
            style.transform = 'translateY(0)';
            style.backgroundColor = 'rgb(17 24 39 / 0.6)';
        } else {
            style.transform = 'translateY(-100%)';
            style.backgroundColor = 'transparent';
        }
        return style
    }

    const onClose = () => {
        setOpen(false);
        if(timeoutRef.current) {
            clearInterval(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            props.onClose?.();
        }, 300)
    }

    function onDelayClose() {
        return setTimeout(() => setOpen(false), 2000)
    }

    return <div>
        <div style={computeOverlay()} className={'inset-0 fixed'} onClick={onClose}></div>
        <div
            style={computeOpenModal()}
            className={'p-4 w-full max-w-96 fixed right-2 bottom-2 top-2 bg-white overflow-y-auto rounded-md shadow-md'}
        >
            <div className={'flex justify-end'}><CircleX className={'cursor-pointer'} onClick={onClose}/></div>
            <Divider direction={'x'} size={"md"}/>
            {props.children}
        </div>
    </div>
};
import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import {LucideProps} from "lucide-react";
import * as react from "react";

type NavigationBarItemProps = {
    href?: string;
    active?: boolean;
    onClick?: () => void;
    hoverable?: boolean;
    icon?: react.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & react.RefAttributes<SVGSVGElement>>;
} & PropsWithChildren;

type ItemProps =  Omit<NavigationBarItemProps, "href">
const Item = (props: ItemProps) => {
    return <div
        onClick={props.onClick}
        className={`flex items-center${props.hoverable ? 'hover:bg-gray-200 cursor-pointer' : 'cursor-default text-gray-400'}  px-4 py-2 rounded-md transition-colors duration-300 ${props.active ? 'font-bold bg-gray-200' : ''}`}
    > {props.icon && <div className={'md:mr-2 center'}>
        <props.icon size={15}/>
    </div>}<div className={"hidden md:block"}>{props.children}</div></div>
}


export const NavigationBarItem = (props: NavigationBarItemProps) => {
    if (props.href) {
        return <Link onClick={props.onClick} to={props.href}><Item icon={props.icon}  hoverable={true}
                                                                   active={props.active}>{props.children}</Item></Link>
    }
    return <Item  icon={props.icon} active={props.active} onClick={props.onClick}>{props.children}</Item>
}
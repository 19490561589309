import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {seedsListsSlice} from "../../feature/seed/seedsListsSlice";
import {AppRemoveSeedsClient} from "../../../http/api/app/seed/AppRemoveSeedsClient";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";
import {seedCountSlice} from "../../feature/seed/seedCountSlice";

export const removeSeedsThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const seedsListState = state.seedsListsSlice;
    const model = state.activeModelSlice.id
    const selectState = state.optionActionSlice;

    if (!model) {
        return
    }

    const seeds = ListsHelper.getList({
        lists: seedsListState.values,
        id: model,
    })

    if (
        !seeds ||
        seeds.values.length === 0 ||
        seedsListState.loading === 'loading'
    ) {
        return;
    }

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(seedsListsSlice.actions.handleLoading('loading'))

    const ids = seeds.values.filter(v => v.selected).map(t => t.id)
    const client = new AppRemoveSeedsClient();
    const response = await client.handleWithCredential({
        model: model,
        seeds: ids
    })

    dispatch(seedsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(seedsListsSlice.actions.handleItems({
        values: seeds.values.filter(seed => !seed.selected),
        id: model,
    }))

    dispatch(seedsListsSlice.actions.handleUnselectAll({
        model: model,
    }))

    dispatch(optionActionSlice.actions.reset())

    const seedCount = ListHelper.getItem(model, state.seedCountSlice.values)
    if(seedCount &&  seedCount.count !== null) {
        dispatch(seedCountSlice.actions.handleItemValue({
            model: model,
            count: seedCount.count - response.data.count
        }))
    }

    ToastHelper.add({
        content: `${ids.length} Inspiration${ids.length > 1 ? 's' : ''} supprimé${ids.length > 1 ? 's' : ''}`,
        type: 'success',
    })

}

import {IAppErrorResponse, IAppResponse, IAppSuccessResponse} from "./IAppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";

export class AppSuccessResponse<T> implements IAppSuccessResponse<T> {
    public readonly success = true;

    constructor(
        public readonly statusCode: number,
        public readonly data: T,
    ) {
    }
}

export class AppErrorResponse implements IAppErrorResponse {
    public readonly success = false;

    constructor(
        public readonly code: string | null,
        public readonly statusCode: number,
    ) {
    }
}

export function asAppResponse<T extends object>(response: T): AppSuccessResponse<T> | AppErrorResponse {
    if (
        isAppErrorResponse(response)
    ) {
        return response;
    }

    if (
        isAppSuccessResponse<T>(response)
    ) {
        return response;
    }

    if (response instanceof Response) {
        return new AppErrorResponse(
            response.status.toString(),
            response.status,
        )
    }

    return new AppErrorResponse(
        AppCodeException.UNKNOWN,
        AppCodeException.CLIENT_ERROR,
    );
}

export function isAppErrorResponse(response: IAppResponse): response is AppErrorResponse {
    return typeof response === 'object' &&
        'success' in response &&
        response.success === false &&
        'statusCode' in response &&
        typeof response.statusCode === 'number' && (
            ('code' in response && typeof response.code === 'string') ||
            ('message' in response && typeof response.message === "object")
        )

        ;
}

export function isAppSuccessResponse<T>(response: IAppResponse): response is AppSuccessResponse<T> {
    return typeof response === 'object' &&
        'success' in response &&
        response.success === true &&
        'statusCode' in response &&
        typeof response.statusCode === 'number' &&
        'data' in response &&
        typeof response.data === 'object';
}

export function isNativeResponse(response: IAppResponse): response is Response {
    return typeof response === 'object' &&
        'statusCode' in response &&
        typeof response.statusCode === 'number' &&
        'code' in response &&
        typeof response.code === 'number'
        ;
}
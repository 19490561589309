import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Divider} from "../../../../component/divider/Divider";
import {SideModal} from "../../../../component/side-modal/SideModal";
import {modelsListSlice} from "../../../../redux/feature/model/modelsListSlice";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {Gender} from "../../../../model/Gender";
import {
    FilePlus2,
    MicVocal
} from "lucide-react";
import {routerPath} from "../../../routerPath";
import {Platform} from "../../../../model/Platform";
import {LinkedInLogoIcon, TwitterLogoIcon} from "@radix-ui/react-icons";
import {Link} from "react-router-dom";
import {CountLinkAction} from "./CountLinkAction";
import {readCountThunk} from "../../../../redux/thunk/count/read-count-thunk";
import {postCountSlice} from "../../../../redux/feature/post/postCountSlice";
import {topicCountSlice} from "../../../../redux/feature/topic/topicCountSlice";
import {approachCountSlice} from "../../../../redux/feature/approach/approchCountSlice";
import {seedCountSlice} from "../../../../redux/feature/seed/seedCountSlice";
import {furtherInformationCountSlice} from "../../../../redux/feature/further-information/furtherInformationCountSlice";


type ListProps = {}

export const ModelDetail = (props: ListProps) => {
    const modelDetail = useAppSelector(state => state.modelsListSlice.detail)
    const createdAt = modelDetail?.createdAt.toString().split('T')[0] || ''
    const hour = modelDetail?.createdAt.toString().split('T')[1].split('.')[0] || ''

    const postsCountState = useAppSelector(state => state.postCountSlice.values)
    const postCountState = postsCountState.find(p => modelDetail && p.id === modelDetail.id)

    const topicsCountState = useAppSelector(state => state.topicCountSlice.values)
    const topicCountState = topicsCountState.find(t => modelDetail && t.id === modelDetail.id)

    const seedsCountState = useAppSelector(state => state.seedCountSlice.values)
    const seedCountState = seedsCountState.find(s => modelDetail && s.id === modelDetail.id)

    const approachesCountState = useAppSelector(state => state.approachCountSlice.values)
    const approachCountState = approachesCountState.find(a => modelDetail && a.id === modelDetail.id)

    const dispatch = useAppDispatch()


    useEffect(() => {
        if (!modelDetail) {
            return
        }

        dispatch((dispatch, getState) => readCountThunk(dispatch, getState, {
            entity: 'post',
            state: () => ({
                load: postCountState?.loading || 'idle',
                value: postCountState?.count || null,
            }),
            setLoadingState: () => dispatch(postCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loading",
            })),
            setLaodedState: () => dispatch(postCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loaded",
            })),
            setValue: (count) => dispatch(postCountSlice.actions.addItem({
                id: modelDetail.id,
                count: count,
                loading: 'loaded',
            })),
        }))

        dispatch((dispatch, getState) => readCountThunk(dispatch, getState, {
            entity: 'topic',
            state: () => ({
                load: topicCountState?.loading || 'idle',
                value: topicCountState?.count || null,
            }),
            setLoadingState: () => dispatch(topicCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loading",
            })),
            setLaodedState: () => dispatch(topicCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loaded",
            })),
            setValue: (count) => dispatch(topicCountSlice.actions.addItem({
                id: modelDetail.id,
                count: count,
                loading: 'loaded',
            })),
        }))

        dispatch((dispatch, getState) => readCountThunk(dispatch, getState, {
            entity: 'approach',
            state: () => ({
                load: approachCountState?.loading || 'idle',
                value: approachCountState?.count || null,
            }),
            setLoadingState: () => dispatch(approachCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loading",
            })),
            setLaodedState: () => dispatch(approachCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loaded",
            })),
            setValue: (count) => dispatch(approachCountSlice.actions.addItem({
                id: modelDetail.id,
                count: count,
                loading: 'loaded',
            })),
        }))

        dispatch((dispatch, getState) => readCountThunk(dispatch, getState, {
            entity: 'seed',
            state: () => ({
                load: seedCountState?.loading || 'idle',
                value: seedCountState?.count || null,
            }),
            setLoadingState: () => dispatch(seedCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loading",
            })),
            setLaodedState: () => dispatch(seedCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loaded",
            })),
            setValue: (count) => dispatch(seedCountSlice.actions.addItem({
                id: modelDetail.id,
                count: count,
                loading: 'loaded',
            })),
        }))


        dispatch((dispatch, getState) => readCountThunk(dispatch, getState, {
            entity: 'furtherInformation',
            state: () => ({
                load: postCountState?.loading || 'idle',
                value: postCountState?.count || null,
            }),
            setLoadingState: () => dispatch(furtherInformationCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loading",
            })),
            setLaodedState: () => dispatch(furtherInformationCountSlice.actions.handleItemLoad({
                model: modelDetail.id,
                loading: "loaded",
            })),
            setValue: (count) => dispatch(furtherInformationCountSlice.actions.addItem({
                id: modelDetail.id,
                count: count,
                loading: 'loaded',
            })),
        }))
    }, [modelDetail]);

    return <SideModal
        open={modelDetail !== null}
        onClose={() => dispatch(modelsListSlice.actions.handleDetail(null))}
    >

        {modelDetail && <div className={'flex justify-end'}>
            <Link to={routerPath.model.createUpdateRoute(modelDetail.id)}
                  className={"underline text-gray-500"}>Modifier</Link>
        </div>}
        <Divider direction={'x'} size={"md"}/>

        {modelDetail && topicCountState && <CountLinkAction icon={FilePlus2}
                                                            count={topicCountState.count}
                                                            model={modelDetail}
                                                            label={{
                                                                plural: "Sujets",
                                                                singular: 'Sujet',
                                                                zeroValue: 'Ajouter un sujet',
                                                                prefix: 'Connait',
                                                            }} isLoading={topicCountState.loading !== "loaded"}
                                                            link={routerPath.topic.base}
        />}

        {modelDetail && seedCountState && <CountLinkAction icon={FilePlus2}
                                                           count={seedCountState.count}
                                                           model={modelDetail}
                                                           label={{
                                                               plural: "Inspiratons",
                                                               singular: 'Inspiration',
                                                               zeroValue: 'Ajouter une inspiration',
                                                               prefix: 'Connait',
                                                           }} isLoading={seedCountState.loading !== "loaded"}
                                                           link={routerPath.seed.base}

        />}
        {modelDetail && approachCountState && <CountLinkAction icon={FilePlus2}
                                                               count={approachCountState.count}
                                                               model={modelDetail}
                                                               label={{
                                                                   plural: "Angles d'approche",
                                                                   singular: "Angle d'approche",
                                                                   zeroValue: "Ajouter un angle d'approche",
                                                                   prefix: 'Connait',
                                                               }} isLoading={approachCountState.loading !== "loaded"}
                                                               link={routerPath.approach.base}

        />}
        {modelDetail && postCountState && <CountLinkAction icon={FilePlus2}
                                                           count={postCountState.count}
                                                           model={modelDetail}
                                                           label={{
                                                               plural: "Posts",
                                                               singular: 'Post',
                                                               zeroValue: 'Demander un post',
                                                               prefix: 'A crée',
                                                           }} isLoading={postCountState.loading !== "loaded"}
                                                           link={routerPath.post.base}

        />}

        <Divider direction={'x'} size={'md'}/>

        {modelDetail && <Paragraph className={'m-0 flex'}>
            {modelDetail.theme}
        </Paragraph>}
        <Divider direction={'x'} size={'md'}/>

        {modelDetail && <p className={'m-0 flex'}>
            <MicVocal className={'w-4'}/>
            <span className={'mx-1'}></span>
            {modelDetail.gender === Gender.MALE ? 'Homme' : 'Femme'}
        </p>}
        <Divider direction={'x'} size={'xs'}/>

        {modelDetail && <p className={'m-0 flex items-center'}>
            {modelDetail.platform === Platform.LINKEDIN && <><LinkedInLogoIcon/> <Divider direction={'y'}
                                                                                          size={'xs'}/> LinkedIn</>}
            {modelDetail.platform === Platform.TWITTER && <><TwitterLogoIcon/><Divider direction={'y'}
                                                                                       size={'xs'}/> Twitter</>}
        </p>}

        <Divider direction={'x'} size={'md'}/>

        {modelDetail && <i className={'text-gray-500 m-0 text-sm flex'}>
            {createdAt} - {hour}
        </i>}
        <Divider direction={'x'} size={'md'}/>
    </SideModal>
};
import {IAlert} from "../type/component/alert-type";
import {toast} from "sonner";


export abstract class ToastHelper {
    static add(params: {
        content: string, type: keyof IAlert, action?: {
            label: string,
            onClick: () => void,
        }
    }) {
        // const classNameMapping: IAlert = {
        //     info: '',
        //     neutral: '',
        //     success: 'bg-green text-white',
        //     warning: ''
        // };
        //

        toast(params.content, {
            description: "A l'instant",
            action: {
                label: params.action?.label || "Fermer",
                onClick: () => params.action?.onClick(),
            },
            position: 'bottom-right',
        })
    }
}
import React, {PropsWithChildren} from 'react';
import {Card} from "../../../../component/card/Card";
import {Divider} from "../../../../component/divider/Divider";
import {useAppSelector} from "../../../../redux/store";

type CardContainerProps = { className?: string } & PropsWithChildren

export const CardContainer = ({children, className}: CardContainerProps) => {
    const loading = useAppSelector(state => state.modelSlice.loading);

    return <>
        <Card
            custom={{
                disabled: loading,
            }}
            base={{
                className: className ?? ''
            }}
        >{children}</Card>
        <Divider direction={'x'} size={'sm'}/>
    </>
};
export abstract class IValidator<T> {
    protected message: string | null = null;

    abstract isValid(value: T): boolean;

    public getMessage() {
        if(this.message === null) {
            return null;
        }

        return this.message.trim().length > 0 ? this.message : null
    };
}
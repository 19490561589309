import {AppClient} from "../AppClient";
import {ListItemModel, Model} from "../../../../model/Model";
import {Tone} from "../../../../model/Tone";

type Input = Required<Pick<Model, 'name' | 'thou' | 'theme' | 'platform' | 'gender' | 'suffix'>> & {
    tones: Required<Tone>[],
} & { id?: Model['id'] }

type OutputData = {
    value: ListItemModel
};

export class AppUpdateModelClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('ai-model/update'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


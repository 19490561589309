import React from 'react';
import {Outlet, redirect} from "react-router-dom";
import {TokenStorage} from "../../service/storage/TokenStorage";
import {TokenService} from "../../service/token/TokenService";
import {routerPath} from "../routerPath";
import {useLocation} from "react-router";
import {MenuSideBar} from "../../component/menu-side-bar/MenuSideBar";
import {ListModelsPage} from "../page/list-models/ListModelsPage";
import {TopBar} from "../../component/top-bar/TopBar";
import {Paragraph} from "../../component/paragraph/Paragraph";

export function loader() {
    const tokenStorage = new TokenStorage();
    const token = tokenStorage.getData()?.value;
    if (!token) {
        return redirect(routerPath.authentication.login)
    }

    const tokenService = new TokenService(token);
    if (!tokenService.isValid() || tokenService.isExired()) {
        tokenStorage.removeData()
        return redirect(routerPath.authentication.login)
    }

    //TODO Do call to check if token is valid and redirect

    return null;
}

export const AuthenticatedRoute = () => {
    const location = useLocation();

    return <div className={'flex h-screen resize'}>
        <MenuSideBar/>
        <div className={'h-screen w-full p-4 py-6'}>
            <TopBar/>
            <div>
                {location.pathname === routerPath.authentication.base ? <ListModelsPage/> : <Outlet/>}
            </div>
        </div>
    </div>
};
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppAddOneTopicModelClient} from "../../../http/api/app/topic/AppAddOneTopicModelClient";
import {manualTopicSlice} from "../../feature/topic/manualTopicValueSlice";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {ToastHelper} from "../../../helper/ToastHelper";
import {StringHelper} from "../../../helper/StringHelper";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";

export const addOneTopicModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState()
    const topicState = state.topicsListsSlice;
    const manualTopicState = state.manualTopicSlice;
    const model = state.activeModelSlice.id
    if (model === null || manualTopicState.value === null || manualTopicState.loading === 'loading') {
        return;
    }

    dispatch(manualTopicSlice.actions.handleLoading('loading'))
    dispatch(optionActionSlice.actions.handleLoading('loading'))

    const client = new AppAddOneTopicModelClient();
    const response = await client.handleWithCredential({
        model: model,
        topic: manualTopicState.value,
    })

    dispatch(manualTopicSlice.actions.handleLoading('idle'))
    dispatch(optionActionSlice.actions.handleLoading('idle'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    ToastHelper.add({
        content: `Ajout de "${StringHelper.truncate(manualTopicState.value, 20, {
            addDots: true,
        })}"`,
        type: 'success',
    })

    const list = topicState.lists.find(list => list.model === model)?.topics || []
    dispatch(topicsListsSlice.actions.handleItems({
        model: model,
        topics: [{
            ...response.data.value,
            selected: false,
            model: model,
            relevant: true,
        }, ...list],
    }))

    const topicCount = ListHelper.getItem(model,  state.topicCountSlice.values)
    if(topicCount &&  topicCount.count !== null) {
        dispatch(topicCountSlice.actions.handleItemValue({
            model: model,
            count: topicCount.count + 1
        }))
    }

    dispatch(optionActionSlice.actions.reset())
    dispatch(manualTopicSlice.actions.reset())
}
import {AppClient} from "../AppClient";
import {Topic} from "../../../../model/Topic";


type Input = {
    model: string
}
type OutputData = {
    values : Required<Pick<Topic, 'id' |'value'|'createdAt'|'relevant'>>[]
};

export class AppGenerateTopicsModelClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('topic/generate'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {AppAddApproachClient} from "../../../http/api/app/approach/AppAddApproachClient";
import {addApproachValueSlice} from "../../feature/approach/addApproachValueSlice";
import {approachesListsSlice} from "../../feature/approach/approachesListsSlice";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {approachCountSlice} from "../../feature/approach/approchCountSlice";

export const createApproachThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState()
    const model = state.activeModelSlice.id
    const addApproachValueState = state.addApproachValueSlice

    if (!model || addApproachValueState.value === null || addApproachValueState.loading === 'loading') {
        return;
    }

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(addApproachValueSlice.actions.handleLoading('loading'))

    const client = new AppAddApproachClient();
    const response = await client.handleWithCredential({
        model: model,
        value: addApproachValueState.value,
    })

    dispatch(addApproachValueSlice.actions.handleLoading('idle'))
    dispatch(optionActionSlice.actions.handleLoading('idle'))

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: 'Une erreur est survenue',
            type: 'warning',
        })
        return;
    }

    dispatch(addApproachValueSlice.actions.reset())
    dispatch(approachesListsSlice.actions.addItem({
        id: model,
        value : {
            ...response.data.value,
            selected: false,
            relevant: true,
        },
    }))

    const approachCount = ListHelper.getItem(model,  state.approachCountSlice.values)
    if(approachCount &&  approachCount.count !== null) {
        dispatch(approachCountSlice.actions.handleItemValue({
            model: model,
            count: approachCount.count + 1
        }))
    }

    ToastHelper.add({
        content: 'Inspiration ajouté' ,
        type: 'success',
    })


}
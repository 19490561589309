import React, {PropsWithChildren} from 'react';

import {
    Card as CnCard,
} from "./component"
import {Overlay} from "../overlay/Overlay";
import {NativeProps} from "../../type/component/native-props";

type Custom = { disabled: boolean }
type CardProps = NativeProps<Custom, React.HTMLAttributes<HTMLDivElement>> & PropsWithChildren

export const Card = (props: CardProps) => {
    return <CnCard {...props.base} className={`px-4 py-6 rounded-md relative overflow-hidden w-full ${props.base?.className || ''}`}>
        {props.children}
        <Overlay custom={{
            active: props.custom?.disabled || false,
        }}/>
    </CnCard>
};
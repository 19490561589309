import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";

export interface VerificationSliceState {
    loading: LoadingState;
}

const initialState: VerificationSliceState = {
    loading: 'idle',
}

export const verificationSlice = createSlice({
    name: 'verification',
    initialState: initialState,
    reducers: {
        handleLoading: (state, action: PayloadAction<VerificationSliceState['loading']>) => {
            state.loading = action.payload
        },
        reset: () => initialState
    },
})
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {modelSlice} from "../../feature/model/modelSlice";
import {AppUpdateModelClient} from "../../../http/api/app/model/AppUpdateModelClient";
import {CreditCountStorage} from "../../../service/storage/CreditCountStorage";
import {CreditService} from "../../../service/credit/CreditService";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {modelsListSlice} from "../../feature/model/modelsListSlice";
import {ToastHelper} from "../../../helper/ToastHelper";
import {
    AppAddFurthersInformationClient
} from "../../../http/api/app/further-information/AppAddFurthersInformationClient";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {UNREGISTERED_FURTHER_INFORMATION_LIST_ID} from "../../../route/page/update-model/component/FurtherInformation";
import {furthersInformationListsSlice} from "../../feature/further-information/furthersInformationListsSlice";
import {Platform} from "../../../model/Platform";

type  UpdateModelParams = {
    onSuccess: () => void
}

export const updateModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: UpdateModelParams,
) => {

    const state = getState()

    const model = getState().modelSlice;
    if (!model.theme || model.theme.length === 0 || !model?.name || model?.name?.trim().length === 0) {
        return;
    }

    const furthersInformationListState = ListsHelper.getList({
        lists: state.furthersInformationListsSlice.values,
        id: model.id || UNREGISTERED_FURTHER_INFORMATION_LIST_ID
    })

    dispatch(modelSlice.actions.handleLoading(true))

    const updateModelClient = new AppUpdateModelClient();
    const updateModeClientResponse = await updateModelClient.handleWithCredential({
        tones: [],//model.tones.map((t) => ({id: t.id, label: t.label, recurrence: t.recurrence})),
        thou: model.thou,
        theme: model.theme,
        platform: Platform.LINKEDIN,//model.platform,
        gender: model.gender,
        name: model.name,
        id: model.id || undefined,
        suffix: model.suffix
    })

    dispatch(modelSlice.actions.handleLoading(false))

    if (isAppErrorResponse(updateModeClientResponse)) {
        let message: string = 'Une erreur est survenue';

        if (
            updateModeClientResponse.code === AppCodeException.INSUFFICIENT_CREDIT
        ) {
            message = 'Credits insuffisants'
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    if (furthersInformationListState && furthersInformationListState?.values && furthersInformationListState?.values.length > 0) {
        const addFurthersInformationClient = new AppAddFurthersInformationClient();
        const addFurthersInformationClientResponse = await addFurthersInformationClient.handleWithCredential({
            model: updateModeClientResponse.data.value.id,
            values: furthersInformationListState.values.map((furtherInformation) => furtherInformation.value),
        })

        if (isAppErrorResponse(addFurthersInformationClientResponse)) {
            ToastHelper.add({
                content: "Impossible d'ajouter les compléments d'information",
                type: 'warning',
            })
            dispatch(furthersInformationListsSlice.actions.reset())
        } else {
            dispatch(furthersInformationListsSlice.actions.handleItems({
                id: updateModeClientResponse.data.value.id,
                values: addFurthersInformationClientResponse.data.values.map((furtherInformation) => ({
                    id: furtherInformation.id,
                    value: furtherInformation.value,
                    createdAt: furtherInformation.createdAt,
                })),
            }))
        }
    }


    const creditCount = getState().creditCountSlice.value;

    if (!creditCount) {
        ToastHelper.add({
            content: AppCodeException.CLIENT_NO_CREDIT_GIVEN.toString(),
            type: 'warning',
        })
        return
    }

    // On creation
    if (!model.id) {
        const creditService = new CreditService(creditCount);
        creditService.consumeModelCreation();

        const newCountValue = creditService.getCount();

        dispatch(creditCountSlice.actions.handleValue(newCountValue))
        new CreditCountStorage().saveData({
            value: newCountValue,
        })
    }

    dispatch(modelSlice.actions.reset())

    ToastHelper.add({
        content: model.id ? 'Assistant mis à jour' : 'Nouvel assistant crée',
        type: 'success',
    })


    const data = updateModeClientResponse.data.value;
    model.id ? dispatch(modelsListSlice.actions.handleItem(data)) : dispatch(modelsListSlice.actions.addItem(data))

    api.onSuccess();

}
import React from 'react';
import {Button} from "../../../../component/button/Button";
import {useAppDetectDevice} from "../../../../hook/useAppDetectDevice";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {EmailValidator} from "../../../../validator/EmailValidator";
import {PasswordValidator} from "../../../../validator/PasswordValidator";
import {UsernameValidator} from "../../../../validator/UsernameValidator";
import {EqualValidator} from "../../../../validator/EqualValidator";
import {registerThunk} from "../../../../redux/thunk/auth/register-thunk";
import {Loader} from "../../../../component/loader/Loader";
import {ChildrenButton} from "../../auth/component/ChildrenButton";
import {useNavigate} from "react-router-dom";
import {LucideLogIn} from "lucide-react";
import {routerPath} from "../../../routerPath";


export const SubmitFormButton = () => {

    const email = useAppSelector((state) => state.userEmailSlice.value)
    const username = useAppSelector((state) => state.usernameSlice.value)
    const password = useAppSelector((state) => state.passwordSlice.value)
    const passwordConfirm = useAppSelector((state) => state.passwordConfirmSlice.value)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const device = useAppDetectDevice();

    const emailValidator = new EmailValidator();
    const usernameValidator = new UsernameValidator();
    const passwordValidator = new PasswordValidator();
    const equalValidator = new EqualValidator();

    const hasError = !emailValidator.isValid(email ?? '') ||
        !usernameValidator.isValid(username ?? '') ||
        !passwordValidator.isValid(password ?? '') ||
        !passwordValidator.isValid(passwordConfirm ?? '') ||
        !equalValidator.isValid({valueA: password ?? '', valueB: passwordConfirm ?? ''});

    function handleSubmit() {

        if (hasError) {
            return;
        }

        if (
            email === null ||
            username === null ||
            password === null
        ) {
            return;
        }

        dispatch((dispatch, getState) => registerThunk(
                dispatch,
                getState,
                {
                    device: device.name,
                    onSuccess : () => navigate(routerPath.authentication.base)
                },
            )
        )
    }

    return <div className={'center'}>
        <Button
            base={{
                onClick: handleSubmit,
                "aria-disabled": hasError,
                disabled: hasError || loading,
            }}
            custom={{
                color: 'blue'
            }}
        >
            {loading ? <Loader/> : <ChildrenButton icon={LucideLogIn} label={'Register'} />}
        </Button>
    </div>
};
// TODO ajouter les validation de Zod pour etre sur de passer que quand la donné est bonne
import {IStorage} from "./IStoage";
import {StorageKey, StorageKeyVersion} from "./StorageKey";

export default abstract class IDataStorage<DataType> {
    protected abstract key: StorageKey;
    protected abstract version: number;

    static keys: string[] = []

    protected getStorage() : IStorage {
        return {
            set: (key: StorageKey, value: string) => window.localStorage.setItem(key, value),
            get: (key: StorageKey) => window.localStorage.getItem(key),
            remove: (key: StorageKey) => window.localStorage.removeItem(key),
        };
    }

    protected set<T>(key: StorageKey | StorageKeyVersion<StorageKey>, data: T): void {
        this.getStorage().set(key, JSON.stringify(data))
        IDataStorage.keys.push(key)
    }

    protected get<T>(key: StorageKey): T | null {
        const data = this.getStorage().get(key)

        if (data == null) {
            return null
        }

        try {
            return JSON.parse(data)
        } catch (error) {
            return null
        }
    }

    protected remove(key: StorageKey): void {
        this.getStorage().remove(key)
    }

    public saveData(data: DataType) {
        this.set<typeof data>(this.key, data)
        this.set<typeof this.version>(`${this.key}-v`, this.version)
    }

    public removeData() {
        this.remove(this.key)
    }

    public getData() {
        return this.get<DataType>(this.key) || null
    }
}
import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Button} from "../../../../component/button/Button";
import {generateTopicsModelThunk} from "../../../../redux/thunk/topic/generate-topics-model-thunk";
import {routerPath} from "../../../routerPath";
import {useLocation} from "react-router";
import {useAppModel} from "../../../../hook/useAppModel";
import {useAppCredit} from "../../../../hook/useAppCredit";
import {CreditCost} from "../../../../model/CreditCost";
import {ToastHelper} from "../../../../helper/ToastHelper";
import {generatePostThunk} from "../../../../redux/thunk/post/generate-post-thunk";
import {useNavigate} from "react-router-dom";

type AddMoreTopicsProps = {}

export const AddMoreTopics = (props: AddMoreTopicsProps) => {
    const model = useAppModel()
    const topicLoaderState = useAppSelector(state => state.topicsListsSlice.loading)
    const manualTopicState = useAppSelector(state => state.manualTopicSlice)
    const selectable = useAppSelector(state => state.topicsListsSlice.selectable)
    const credit = useAppCredit()
    const navigate = useNavigate()

    const location = useLocation();

    const isDisabled = model.id === null ||
        topicLoaderState === "loading" ||
        manualTopicState.loading === 'loading' ||
        selectable
    const isHidden = location.pathname !== routerPath.topic.base || !model.hasModels

    const dispatch = useAppDispatch();

    const onClick = () => {
        if (credit.value !== null && credit.value < CreditCost.TOPIC) {
            ToastHelper.add({
                type: 'warning',
                content: "Crédit insuffisant",
                action: {
                    label: 'Recharger',
                    onClick: () => navigate(routerPath.credit.base),
                }
            })
            return;
        }
        dispatch(generateTopicsModelThunk)
    }

    if (isHidden) {
        return <></>
    }

    return <Button
        custom={{
            color: 'blue',
        }}
        base={{
            className: 'center max-w-max',
            disabled: isDisabled,
            onClick: onClick,
        }}
    >
        Générer
    </Button>

};
import React from 'react';
import {Textarea} from "../../../../component/textarea/Textarea";
import {Divider} from "../../../../component/divider/Divider";
import {InputMessage} from "../../../../component/input-message/InputMessage";
import {Button} from "../../../../component/button/Button";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {useAppModel} from "../../../../hook/useAppModel";
import {ApproachValidator} from "../../../../validator/ApproachValidator";
import {addApproachValueSlice} from "../../../../redux/feature/approach/addApproachValueSlice";
import {createApproachThunk} from "../../../../redux/thunk/approach/create-approach-thunk";
import {Dialog} from "../../../../component/dialog/Dialog";
import {Loader} from "../../../../component/loader/Loader";

export const AddApproachInput = () => {
    const model = useAppModel()
    const state = useAppSelector(state => state.addApproachValueSlice)
    const optionActionState = useAppSelector(state => state.optionActionSlice)
    const approachesListStateLoader = useAppSelector(state => state.approachesListsSlice.loading)
    const dispatch = useAppDispatch();

    let errorMessage = null;
    const validator = new ApproachValidator()
    if (state.value !== null && !validator.isValid(state.value)) {
        errorMessage = validator.getMessage()
    }

    const loading = state.loading === "loading"

    const isButtonDisabled = errorMessage !== null ||
        state.loading === "loading" ||
        state.value === null ||
        !model.id ||
        optionActionState.loading === "loading" ||
        approachesListStateLoader === 'loading'
    const isTextareaDisabled = state.loading === "loading" ||
        !model.id ||
        optionActionState.loading === "loading" ||
        approachesListStateLoader === 'loading'

    if (!model.id) {
        return <></>
    }

    return <div className={'mr-4'}>

        <div className={'flex justify-end'}>
            <Dialog
                dialogCnProps={{
                    onOpenChange: (open) => dispatch(addApproachValueSlice.actions.handleOpen(open)),
                    open: state.open
                }}
                title={"Angle d'approche différente"}
                trigger={{
                    label: "Ajouter une approche", props: {
                        className: 'font-xs font-light'
                    }
                }}
            >
                <Textarea
                    onChange={(e) => dispatch(addApproachValueSlice.actions.handleValue(e.currentTarget.value))}
                    defaultValue={state.value || ''}
                    disabled={isTextareaDisabled}
                    id={'approach-field'}
                    name={'approach-field'}
                />

                {errorMessage !== null && <InputMessage>{errorMessage}</InputMessage>}

                <Divider direction={'x'} size={"xs"}/>
                <div>
                    <Button
                        base={{
                            onClick: () => dispatch(createApproachThunk),
                            disabled: isButtonDisabled,
                        }}
                        custom={{color: 'neutral'}}
                    >
                        {loading ? <Loader/> : "Submit"}
                    </Button>
                </div>
            </Dialog>

        </div>

        <Divider direction={'x'} size={'md'}/>
    </div>
};
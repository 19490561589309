import React from 'react';

type FurtherInformationProps = {}
export const UNREGISTERED_FURTHER_INFORMATION_LIST_ID = "no-created"

export const FurtherInformation = (props: FurtherInformationProps) => {
    return <></>
};


// import React, {
//     FormEvent, useEffect,
//     useState
// } from 'react';
// import {CardContainer} from "./CardContainer";
// import {CardContainerLabel} from "./CardContainerLabel";
// import {Divider} from "../../../../component/divider/Divider";
// import {InputMessage} from "../../../../component/input-message/InputMessage";
// import {ModelFurtherInformationValidator} from "../../../../validator/ModelFurtherInformationValidator";
// import {Input} from "../../../../component/input/Input";
// import {Card} from "../../../../component/card/Card";
// import {Button} from "../../../../component/button/Button";
// import {useAppDispatch, useAppSelector} from "../../../../redux/store";
// import {
//     furthersInformationListsSlice
// } from "../../../../redux/feature/further-information/furthersInformationListsSlice";
// import {useAppLists} from "../../../../hook/useAppLists";
// import {Paragraph} from "../../../../component/paragraph/Paragraph";
// import {Trash2} from "lucide-react";
// import {getFurthersInformationThunk} from "../../../../redux/thunk/further-information/get-furthers-information-thunk";
//
//

//
// export const FurtherInformation = () => {
//     const [inputValue, setInputValue] = useState<string | null>(null)
//     const model = useAppSelector(state => state.modelSlice)
//     const modelId = model?.id || UNREGISTERED_FURTHER_INFORMATION_LIST_ID;
//     const {list} = useAppLists(modelId, (state) => state.furthersInformationListsSlice.values)
//     const dispatch = useAppDispatch();
//
//     let errorMessage = null;
//     const validator = new ModelFurtherInformationValidator()
//     if (inputValue !== null && inputValue.trim().length > 0 && !validator.isValid(inputValue)) {
//         errorMessage = validator.getMessage()
//     }
//
//     const isButtonDisabled = !inputValue || errorMessage !== null || (list?.values && list?.values.length > 9)
//
//     useEffect(() => {
//         const model_ = model?.id
//         if (!model_) {
//             dispatch(furthersInformationListsSlice.actions.reset())
//             return
//         }
//
//         dispatch((dispach, getState) => getFurthersInformationThunk(dispach, getState, {
//             model: model_,
//         }))
//     }, [model?.id]);
//
//     const onChange = (value: string) => {
//         setInputValue(value)
//     }
//
//     const onAddItem = (e: FormEvent<HTMLFormElement>) => {
//         e.preventDefault();
//
//         if (!inputValue) {
//             return;
//         }
//
//         const date = new Date()
//
//         dispatch(furthersInformationListsSlice.actions.addItem({
//             id: modelId,
//             value: {
//                 value: inputValue,
//                 id: Date.now().toString(),
//                 createdAt: date,
//             },
//         }))
//
//         setInputValue(null);
//     }
//
//     const onRemoveItem = (id: string) => {
//         if (!list) {
//             return;
//         }
//
//         dispatch(furthersInformationListsSlice.actions.handleItems({
//             id: list.id,
//             values: list.values.filter(item => item.id !== id),
//         }))
//     }
//
//     return <CardContainer>
//         <CardContainerLabel htmlFor={'further-information'}>Que devrais-je savoir de plus ?</CardContainerLabel>
//         <form onSubmit={onAddItem} className={'flex'}>
//             <div className={'w-full'}>
//                 <Input
//                     base={{
//                         placeholder: 'John Doe est le fondateur de cet outil',
//                         name: 'further-information',
//                         id: 'further-information',
//                         value: inputValue || '',
//                         onChange: (e) => onChange(e.target.value),
//                         className: 'w-full'
//                     }}
//                 />
//             </div>
//
//             <Divider direction={'y'} size={'xs'}/>
//
//             <div className={'flex items-end'}>
//                 <Button base={{
//                     disabled: isButtonDisabled,
//                     "aria-disabled": isButtonDisabled,
//                 }}>Ajouter</Button>
//             </div>
//         </form>
//         {errorMessage && <InputMessage>{errorMessage}</InputMessage>}
//
//         {(list?.values && list.values?.length > 0) && <Divider direction={'x'} size={'xs'}/>}
//
//         {list?.values.map(fi => <Card key={fi.id} base={{className: 'py-2 mb-2'}}>
//             <div className={'flex h-full items-center'}>
//                 <div className={'w-full'}>
//                     <Paragraph>{fi.value}</Paragraph>
//                 </div>
//                 <button disabled={!list} className={'cursor-pointer p-2'} onClick={() => onRemoveItem(fi.id)}>
//                     <Trash2 className={' text-red-400'} size={15}/>
//                 </button>
//             </div>
//         </Card>)}
//
//
//     </CardContainer>
// };

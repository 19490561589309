import React from 'react';
import {LucideProps} from "lucide-react";
import {Divider} from "../../../../component/divider/Divider";
import * as react from "react";

type ChildrenButtonProps = {
    label:string;
    icon:  react.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & react.RefAttributes<SVGSVGElement>>
}

export const ChildrenButton = (props: ChildrenButtonProps) => {
    return <>
        {props.label}
        <Divider direction={'y'} size={'sm'} />
        <props.icon />
    </>
};
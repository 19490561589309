import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {AppUpdateApproachClient} from "../../../http/api/app/approach/AppUpdateApproachClient";
import {approachesListsSlice} from "../../feature/approach/approachesListsSlice";
import {approachCountSlice} from "../../feature/approach/approchCountSlice";

export const updateApproachThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: {
        relevant?: boolean;
        id: string;
    }
) => {
    const state = getState()
    const manualApproachValueState = state.addApproachValueSlice;
    const model = state.activeModelSlice.id

    if (model === null || manualApproachValueState.loading === 'loading') {
        return;
    }

    const client = new AppUpdateApproachClient();
    const response = await client.handleWithCredential({
        id: api.id,
        model: model,
        relevant: api.relevant,
    })

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: 'Une erreur est survenue',
            type: 'warning',
        })
        return;
    }

    if(api.relevant !== undefined) {
        ToastHelper.add({
            content: `Votre assistant va s'améliorer`,
            type: 'success',
        })
    }

    const itemToRemove = response.data.value
    dispatch(approachesListsSlice.actions.handleRemoveItem({
        id: model,
        value : {
            ...itemToRemove,
            selected: false,
        }
    }))

    const approachCount = ListHelper.getItem(model, state.approachCountSlice.values)
    if ( approachCount &&  approachCount.count !== null) {
        dispatch(approachCountSlice.actions.handleItemValue({
            model: model,
            count: approachCount.count - 1
        }))
    }
}
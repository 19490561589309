import {AppDispatch, RootState} from "../../store";
import {loadingSlice} from "../../feature/auth/loadingSlice";
import {AppLoginClient} from "../../../http/api/app/auth/AppLoginClient";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {TokenStorage} from "../../../service/storage/TokenStorage";
import {ToastHelper} from "../../../helper/ToastHelper";
import {emailSlice} from "../../feature/auth/emailSlice";
import {usernameSlice} from "../../feature/auth/usernameSlice";
import {passwordSlice} from "../../feature/auth/passwordSlice";
import {passwordConfirmSlice} from "../../feature/auth/passwordConfirmSlice";

type LoginParams = {
    device: string
    onSuccess : () => void;
}

export const loginThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {device, onSuccess}: LoginParams
) => {
    dispatch(loadingSlice.actions.active())

    const email = getState().userEmailSlice.value
    const password = getState().passwordSlice.value

    if (
        email === null ||
        password === null
    ) {
        return;
    }

    const client = new AppLoginClient();
    const response = await client.handle({
        email, password, device,
    })

    dispatch(loadingSlice.actions.inactive())

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        if (
            response.code === AppCodeException.USER_EMAIL_NOT_FOUND ||
            response.code === AppCodeException.ACCOUNT_DELETED ||
            response.code === AppCodeException.BAD_CREDENTIAL
        ) {
            message = 'Identifiants incorrects'
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    new TokenStorage().saveData({
        value: response.data.access_token
    })

    ToastHelper.add({
        content: 'Connexion réussie',
        type: 'success',
    })

    dispatch(emailSlice.actions.reset())
    dispatch(usernameSlice.actions.reset())
    dispatch(passwordSlice.actions.reset())
    dispatch(passwordConfirmSlice.actions.reset())
    dispatch(loadingSlice.actions.reset())
    onSuccess();
}
import {IValidator} from "./IValidator";

export class UsernameValidator extends IValidator<string> {
    isValid(value: string) {
        if (value.trim().length === 0) {
            this.message = 'Valeur requise';
            return false;
        }

        if (value.length < 3) {
            this.message = 'Nom trop court';
            return false;
        }

        if (value.length > 255) {
            this.message = 'Nom trop long';
            return false;
        }

        return true;
    }
}
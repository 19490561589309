import {IValidator} from "./IValidator";

export class CreditCountValidator extends IValidator<number> {
    isValid(value: number)  : boolean {
        if(value < 2) {
            this.message = 'La valeur minimale est 2'
            return false;
        }

        return true
    }
}
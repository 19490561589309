import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {EmailValidator} from "../../../../validator/EmailValidator";
import {Input} from "../../../../component/input/Input";
import {emailSlice} from "../../../../redux/feature/auth/emailSlice";

export const EmailInput = () => {
    const state = useAppSelector((state) => state.userEmailSlice)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const dispatch = useAppDispatch()

    let errorMessage = null;
    const validator = new EmailValidator()
    if (state.value !== null && !validator.isValid(state.value)) {
        errorMessage = validator.getMessage()
    }

    return <div>
        <Input
            custom={{
                label: 'Email',
                errorMessage: errorMessage,
            }}
            base={{
                type: 'email',
                placeholder: 'johndoe@domain.com',
                name: 'email',
                value: state.value ?? '',
                autoComplete: 'email',
                onChange: (e) => dispatch(emailSlice.actions.update({
                    value: e.target.value,
                })),
                disabled : loading
            }}
        />
    </div>
};
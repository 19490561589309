import {AppClient} from "../AppClient";


type InputData  = {
    content: string
}

type OutputData = {
    value: {
        id: string;
        content: string;
        createdAt: Date
    }
};

export class AppCreateFeedbackClient extends AppClient<InputData, OutputData> {
    async request(input: InputData) {
        return await fetch(this.buildEndpoint('feedback'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(input)
        })
    }
}


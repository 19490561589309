import React from 'react';
import {TopicsList} from "./component/TopicsList";
import {Divider} from "../../../component/divider/Divider";
import {Alert} from "../../../component/alert/Alert";
import {AddTopicInput} from "./component/AddTopicInput";

type TopicsPageProps = {}

export const TopicsPage = (props: TopicsPageProps) => {
    return <div>
        <div className={'pr-4'}>
            <Alert
                type={'neutral'}
                title={'Sujets'}
                message={'Gérez vos différents sujets pour que votre assistant puisse traiter les différents sujets du thème principal. Vous pouvez en générer ou les créer manuellement'}
            />
        </div>

        <Divider direction={'x'} size={'xs'}/>
        <AddTopicInput/>

        <Divider direction={'x'} size={'xs'}/>
        <TopicsList/>
    </div>
};
import React from 'react';
import {Divider} from "../../../../component/divider/Divider";
import {Textarea} from "../../../../component/textarea/Textarea";
import {modelSlice} from "../../../../redux/feature/model/modelSlice";
import {CardContainer} from "./CardContainer";
import {CardContainerLabel} from "./CardContainerLabel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {InputMessage} from "../../../../component/input-message/InputMessage";
import {ModelThemeValidator} from "../../../../validator/ModelThemeValidator";

type ThemeProps = { defaultValue?: string }

export const Theme = (props: ThemeProps) => {
    const dispatch = useAppDispatch()
    const modelTheme = useAppSelector((state) => state.modelSlice.theme)

    let errorMessage = null;
    const validator = new ModelThemeValidator()
    if (modelTheme !== null && !validator.isValid(modelTheme)) {
        errorMessage = validator.getMessage()
    }

    return <CardContainer>
        <CardContainerLabel htmlFor={'theme'}>Sur quel thème devrais-je essentiellement rédiger ?</CardContainerLabel>
        <Divider direction={'x'} size={'sm'}/>

        <Textarea
            onChange={(e) => dispatch(modelSlice.actions.handleTheme(e.target.value))}
            placeholder="Anexdot est une application qui permet  aux entrepreneur de maintenir un taux de publication de contenu au même niveau, leur permettant ainsi de se concenter sur différents aspects de leur multiple business. "
            id={'theme'}
            value={modelTheme || ''}
        />
        {errorMessage !== null && <InputMessage>{errorMessage}</InputMessage>}
    </CardContainer>
};
import {AppErrorResponse, AppSuccessResponse, asAppResponse} from "./AppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ApiClient} from "../client/ApiClient";
import {TokenStorage} from "../../../service/storage/TokenStorage";

type Output<T> = AppSuccessResponse<T> | AppErrorResponse;

export abstract class AppClient<I, Data extends object> extends ApiClient<I, Output<Data>> {
    protected getDomainTarget(): string {
        return String(process.env.REACT_APP_APP_API_DOMAIN);
    }

    async handle(params?: I): Promise<Output<Data>> {
        try {
            const response = await this.request(params)
            return asAppResponse<Data>(await response.json())
        } catch (error: any) {
        }

        return new AppErrorResponse(
            AppCodeException.UNKNOWN,
            AppCodeException.CLIENT_ERROR,
        )
    }

    async handleWithCredential(params?: I): Promise<Output<Data>> {
        const token = new TokenStorage().getData()?.value;

        if (!token) {
            return new AppErrorResponse(
                AppCodeException.CLIENT_TOKEN_NOT_FOUND,
                AppCodeException.CLIENT_ERROR,
            )
        }
        this.headers['Authorization'] = `Bearer ${token}`;
        return this.handle(params)
    }
}
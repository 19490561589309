import {AppClient} from "../AppClient";
import {Seed} from "../../../../model/Seed";


interface Input {
    model: string;
    content: string;
}

type OutputData = {
    value: Required<Pick<Seed, 'id' | 'content' | 'createdAt'>>
};

export class AppAddSeedClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('seed'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface usernameSliceState {
    value: string | null,
}

const initialState: usernameSliceState = {
    value: null,
}


export const usernameSlice = createSlice({
    name: 'authUsername',
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<usernameSliceState>) => ({
            value : action.payload.value
        }),
        reset: () => initialState,
    },
})
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {AppUpdateVerificationClient} from "../../../http/api/app/verification/AppUpdateVerificationClient";
import {verificationSlice} from "../../feature/verification/verificationStatusSlice";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {TokenStorage} from "../../../service/storage/TokenStorage";

export const updateVerificationThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: {
        verificationId: string;
        device: string;
        onError: () => void;
        onSuccess: () => void;
    }
) => {

    const state = getState().verificationSlice

    if (state.loading !== "idle") {
        return
    }

    dispatch(verificationSlice.actions.handleLoading('loading'))

    const client = new AppUpdateVerificationClient()
    const response = await client.handle({
        verificationId: api.verificationId,
        device: api.device,
    })

    if (isAppErrorResponse(response)) {
        let message = 'Une erreur est survenue';

        if(response.code === AppCodeException.IDENTITY_NO_FOUND) {
            message  = 'Verification impossible'
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })

        dispatch(verificationSlice.actions.handleLoading('idle'))
        api.onError()
        return;
    }

    dispatch(verificationSlice.actions.handleLoading('loaded'))

    new TokenStorage().saveData({
        value : response.data.value.access_token
    })

    ToastHelper.add({
        content: 'Email vérifié' ,
        type: 'success',
    })

    api.onSuccess()
}
import React from 'react';
import {GeneratedPost} from "../../../../model/Post";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {StringHelper} from "../../../../helper/StringHelper";
import {Divider} from "../../../../component/divider/Divider";

type PostListItemContentProps = {
    post: GeneratedPost,
    scrollable: boolean,
    onOpen: (open: boolean) => void
    open: boolean
}

export const PostListItemContent = (
    {
        post,
        scrollable,
        onOpen,
        open

    }: PostListItemContentProps) => {

    const maxLength = open ? post.content.length : 255
    const content = StringHelper.truncate(post.content, maxLength, {
        addDots: !open
    })

    return <>
        <div className={'w-full'}>
            <Paragraph
                className={`select-none w-full pr-2 whitespace-pre-line ${scrollable ? 'overflow-y-auto max-h-96' : ''}`}>
                {content}<span onClick={() => onOpen(!open)}
                               className={' underline p-2 cursor-pointer font-medium text-gray-600 italic'}>{open ? 'Voir moins' : 'Voir plus'}</span>
            </Paragraph>

            <Divider direction={'x'} size={"xs"}/>
        </div>

        <Divider direction={'y'} size={"xs"}/>
    </>
};
import {AppDispatch, RootState} from "../../store";
import {AppAddSeedClient} from "../../../http/api/app/seed/AppAddSeedClient";
import {addSeedValueSlice} from "../../feature/seed/addSeedValueSlice";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {seedsListsSlice} from "../../feature/seed/seedsListsSlice";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";
import {seedCountSlice} from "../../feature/seed/seedCountSlice";

export const createSeedThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState()
    const model = state.activeModelSlice.id
    const addSeedValueState = state.addSeedValueSlice

    if (!model || addSeedValueState.value === null || addSeedValueState.loading === 'loading') {
        return;
    }

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(addSeedValueSlice.actions.handleLoading('loading'))

    const client = new AppAddSeedClient();
    const response = await client.handleWithCredential({
        model: model,
        content: addSeedValueState.value,
    })

    dispatch(addSeedValueSlice.actions.handleLoading('idle'))
    dispatch(optionActionSlice.actions.handleLoading('idle'))

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: 'Une erreur est survenue',
            type: 'warning',
        })
        return;
    }

    dispatch(addSeedValueSlice.actions.reset())
    dispatch(seedsListsSlice.actions.addItem({
        id: model,
        value : {
            ...response.data.value,
            selected: false,
        },
    }))

    const seedCount = ListHelper.getItem(model,  state.seedCountSlice.values)
    if(seedCount &&  seedCount.count !== null) {
        dispatch(seedCountSlice.actions.handleItemValue({
            model: model,
            count: seedCount.count + 1
        }))
    }

    ToastHelper.add({
        content: 'Inspiration ajouté' ,
        type: 'success',
    })


}
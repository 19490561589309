import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {AppRemoveTopicsClient} from "../../../http/api/app/topic/AppRemoveTopicsClient";
import {ToastHelper} from "../../../helper/ToastHelper";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";

export const removeTopicsByIdThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState()

    const model = state.activeModelSlice.id
    if (!model) return;

    const topicsListState = state.topicsListsSlice;
    const topics = topicsListState.lists.find(list => list.model === model)?.topics?.filter(t => t.selected);
    if (
        !topics ||
        topics.length === 0 ||
        topicsListState.loading === 'loading'
    ) {
        return;
    }

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(topicsListsSlice.actions.handleLoading('loading'))

    const client = new AppRemoveTopicsClient();
    const response = await client.handleWithCredential({
        model: model,
        topics: topics.map(t => t.id)
    })

    dispatch(topicsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(topicsListsSlice.actions.handleRemoveItems({
        model: model,
    }))

    dispatch(topicsListsSlice.actions.handleUnselectAll({
        model: model,
    }))
    dispatch(topicsListsSlice.actions.handleSelectable(false))

    const topicCount = ListHelper.getItem(model, state.topicCountSlice.values)
    if(topicCount &&  topicCount.count !== null) {
        dispatch(topicCountSlice.actions.handleItemValue({
            model: model,
            count: topicCount.count - response.data.count
        }))
    }

    dispatch(optionActionSlice.actions.reset())

    ToastHelper.add({
        content: `Votre assistant a retiré ${topics.length} Sujet${topics.length > 1 ? 's' : ''}`,
        type: 'success',
    })
}


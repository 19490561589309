import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UserPasswordConfirmSliceState {
    value: string | null,
}

const initialState: UserPasswordConfirmSliceState = {
    value: null,
}


export const passwordConfirmSlice = createSlice({
    name: 'authPasswordConfirm',
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<UserPasswordConfirmSliceState>) => ({
            value : action.payload.value
        }),
        reset: () => initialState,
    },
})
import React, {useEffect, useState} from 'react';
import {Dialog} from "../../../../component/dialog/Dialog";
import {feedbackSlice} from "../../../../redux/feature/feedback/feedbackSlice";
import {Textarea} from "../../../../component/textarea/Textarea";
import {InputMessage} from "../../../../component/input-message/InputMessage";
import {Divider} from "../../../../component/divider/Divider";
import {Button} from "../../../../component/button/Button";
import {createFeedBackThunk} from "../../../../redux/thunk/feedback/create-feedback-thunk";
import {Loader} from "../../../../component/loader/Loader";
import {PostContentValidator} from "../../../../validator/PostContentValidator";
import {useAppDispatch} from "../../../../redux/store";
import {updatePostContentThunk} from "../../../../redux/thunk/post/update-post-content-thunk";
import {GeneratedPost} from "../../../../model/Post";

type UpdatePostProps = {
    open: boolean;
    initValue: string;
    post: GeneratedPost
    onOpenChange: (open: boolean) => void
}

export const UpdatePost = (
    {
        post,
        open,
        initValue,
        onOpenChange
    }: UpdatePostProps
) => {

    const [value, setValue] = useState<string | null>(initValue)
    // const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const isDisabled = !initValue || !value;

    const dispatch = useAppDispatch();

    let errorMessage = null;
    const validator = new PostContentValidator()
    if (value !== null && !validator.isValid(value)) {
        errorMessage = validator.getMessage()
    }

    function onSave() {
        if (!value) {
            return;
        }

        if(value === initValue) {
            onOpenChange(false);
            return;
        }
        dispatch((dispatch, getState) => updatePostContentThunk(dispatch, getState, {
            post: post,
            content: value,
            onLoaderChange: (load) => setLoading(load),
            onSuccess: () => onOpenChange(false)
        }))
    }

    return <div>
        <Dialog
            dialogCnProps={{
                onOpenChange: (open) => onOpenChange(open),
                open: open
            }}
            title={"Modifier ce contenu"}
        >
            <Textarea
                className={'text-sm font-normal tracking-normal border-none'}
                id={`edit-post-field`}
                name={`edit-post-field`}
                onChange={(e) => setValue(e.target.value)}
                value={value || ''}
            />
            {errorMessage !== null && <InputMessage>{errorMessage}</InputMessage>}

            <Divider direction={'x'} size={"xs"}/>
            <div>
                <Button
                    base={{
                        onClick: () => onSave(),
                        disabled: isDisabled,
                    }}
                    custom={{color: 'neutral'}}
                >
                    {loading ? <Loader/> : "Submit"}
                </Button>
            </div>
        </Dialog>
    </div>
};
import {CreditCost} from "../../model/CreditCost";

export class CreditService {
    constructor(private count: number) {}

    public consumePostCreation() {
        this.count -= CreditCost.POST;
    }

    public consumeApproachCreation() {
        this.count -= CreditCost.APPROACH;
    }

    public consumeModelCreation() {
        this.count -= CreditCost.MODEL;
    }

    public consumeTopicCreation() {
        this.count -= CreditCost.TOPIC;
    }

    public getCount() {
        return this.count;
    }
}
import React, {useEffect, useState} from 'react';
import {Card} from "../../../../component/card/Card";
import {LucideCopy, LucidePen, ThumbsDown} from "lucide-react";
import {Divider} from "../../../../component/divider/Divider";
import {GeneratedPost} from "../../../../model/Post";
import {useAppDispatch} from "../../../../redux/store";
import {updatePostRelevanceThunk} from "../../../../redux/thunk/post/update-post-relevance-thunk";
import {UpdatePost} from "./UpdatePost";
import {updatePostPublishedAtThunk} from "../../../../redux/thunk/post/update-post-published-at-thunk";
import {PostListItemContent} from "./PostListItemContent";

type PostListItemProps = {
    post: GeneratedPost
    scrollable: boolean
    suffix?: string
}

export const PostListItem = (
    {
        post,
        scrollable
    }: PostListItemProps
) => {
    const content = post.content
    const [edit, setEdit] = useState(false)
    const dispatch = useAppDispatch();
    const isPublished = post.publishedAt !== null

    const [open, setOpen] = useState<boolean>(post.publishedAt === null)

    useEffect(() => {
        setOpen(!isPublished)
    }, [isPublished])
    const computePublishedClassName = () => {
        if (!isPublished) return ''
        return ''
    }

    function onClickUpdateRelevance(relevant: boolean) {
        dispatch((dispatch, getState) => updatePostRelevanceThunk(dispatch, getState, {
            relevant: relevant,
            post: post,
        }))
    }

    function irrelevantPost() {
        dispatch((dispatch, getState) => updatePostRelevanceThunk(dispatch, getState, {
            relevant: false,
            post: post,
        }))
    }

    return <Card base={{
        className: `pt-2 whitespace-pre-line relative border-1 ${edit ? 'border-blue-600' : ''} ${computePublishedClassName()}`
    }}>
        <div className={'flex justify-end text-gray-800 text-xs mb-4'} onClick={() => onClickUpdateRelevance(false)}>
            <span className={`underline text-red-500 cursor-pointer ${!open ? 'hidden' : ''}`}>Dire à votre assistant que ce contenu ne vous convient pas ?</span>
        </div>

        <UpdatePost
            post={post}
            initValue={content}
            open={edit}
            onOpenChange={(open) => setEdit(open)}
        />

        <div className={'flex'}>

            <PostListItemContent
                open={open}
                onOpen={(open) => setOpen(open)}
                post={post} scrollable={scrollable}
            />

            <div className={'max-w-max w-10 flex justify-start flex-col'}>

                {open && <div className={"p-1  border-1 rounded box-content shadow-xl border-white"}>
                    <div className={'flex center'}>
                        <button onClick={() => setEdit(!edit)}>
                            {!edit && <LucidePen size={24}/>}
                        </button>
                    </div>

                    <Divider direction={'x'} size={"xs"}/>

                    {!edit && <>
                        <div>
                            <input type={'hidden'} readOnly={true} id={`post_${post.id}`} value={content}/>
                            <button className="btn" data-clipboard-target={`#post_${post.id}`}
                                    onClick={() => dispatch((dispatch, getState) => updatePostPublishedAtThunk(dispatch, getState, {
                                        post: post,
                                        publishedAt: new Date(),
                                    }))}>
                                <LucideCopy/>
                            </button>
                        </div>
                        <Divider direction={'x'} size={"xs"}/>
                    </>}


                    {!edit && <>
                        <div>
                            <button onClick={irrelevantPost}>
                                <ThumbsDown />
                            </button>
                        </div>
                        <Divider direction={'x'} size={"xs"}/>
                    </>}
                </div>}

            </div>
        </div>
    </Card>
};
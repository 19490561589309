import IDataStorage from "./core/IDataStorage";
import {StorageKey} from "./core/StorageKey";
import Cookies from 'js-cookie';

type Data = {
    value: string
}

export class TokenStorage extends IDataStorage<Data> {
    protected version: number = 1;
    protected key: StorageKey = StorageKey.userToken;

    getStorage() {
        return {
            set: (key: StorageKey, value: string) => Cookies.set(key, value, {
                // Expire in 3 days
                expires: 3,
                secure: process.env.NODE_ENV === "production"
            }),
            get: (key: StorageKey) => Cookies.get(key) || null,
            remove: (key: StorageKey) => Cookies.remove(key),
        };
    }
}
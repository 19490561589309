import {AppClient} from "../AppClient";


type Input = {
    model: string
    seeds: string[]
}

type OutputData = {
    count: number
};

export class AppRemoveSeedsClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`seed`), {
            method: 'DELETE',
            headers: this.headers,
            body : JSON.stringify(params),
        })
    }
}


import React from 'react';
import {NativeProps} from "../../type/component/native-props";

type Custom = {
    active: boolean
}

type OverlayProps = NativeProps<Custom, React.ButtonHTMLAttributes<HTMLDivElement>>

export const Overlay = (props: OverlayProps) => {
    return props.custom?.active ? <div {...props.base} className={`absolute inset-0 bg-gray-900/5 ${props.base?.className || ''}`}></div> : <></>
};
import {AppDispatch, RootState} from "../../store";
import {AppUpdatePostClient} from "../../../http/api/app/post/AppUpdatePostClient";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {GeneratedPost} from "../../../model/Post";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";

export const updatePostPublishedAtThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: { post: GeneratedPost, publishedAt: Date | null }
) => {
    const state = getState();
    const model = state.activeModelSlice.id;

    if (!model) {
        return;
    }

    const post = api.post;

    if (post.publishedAt !== null) {
        return;
    }

    const publicationDate = new Date();
    dispatch(postsListsSlice.actions.handleItem({
        model: model,
        post: {
            ...api.post,
            relevant: true,
            publishedAt: publicationDate,
        }
    }))

    const client = new AppUpdatePostClient()
    const response = await client.handleWithCredential({
        id: api.post.id,
        relevant: true,
        publishedAt: publicationDate,
    })

    if (isAppErrorResponse(response)) {
        let message = 'An error was occured'
        if (response.code) {
            message = `${message} ${response.code}`;
        } else {
            message = `${message} on copy`;
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }
}
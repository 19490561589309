import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {FeedbackSliceState} from "../feedback/feedbackSlice";

export interface ManualApproachSliceState {
    loading: LoadingState;
    value: string|null;
    open: boolean
}

const initialState: ManualApproachSliceState = {
    loading: 'idle',
    value: null,
    open: false
}

export const addApproachValueSlice = createSlice({
    name: 'addApproach',
    initialState: initialState,
    reducers: {
        handleLoading: (state, action: PayloadAction<ManualApproachSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleValue: (state, action: PayloadAction<ManualApproachSliceState['value']>) => {
            state.value = action.payload
        },
        handleOpen: (state, action: PayloadAction<FeedbackSliceState['open']>) => {
            state.open = action.payload
        },
        reset: () => initialState
    },
})
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../type/component/loading-state";


export interface OptionActionSliceState {
    active: boolean;
    loading: Exclude<LoadingState, 'loaded'>,
}

const initialState: OptionActionSliceState = {
    active: false,
    loading: 'idle',
}

export const optionActionSlice = createSlice({
    name: 'optionAction',
    initialState: initialState,
    reducers: {
        handleSelect: (state) => {
            state.active = true;
        },
        handleLoading: (state, action: PayloadAction<OptionActionSliceState['loading']>) => {
            state.loading = action.payload
        },
        reset: () => initialState
    },
})
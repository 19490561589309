import React, {useEffect} from 'react';
import {useAppModel} from "../../hook/useAppModel";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {optionActionSlice} from "../../redux/feature/optionActionSlice";
import {Divider} from "../divider/Divider";

type OptionActionProps = {
    onDelete: () => void
}

export const OptionAction = (props: OptionActionProps) => {
    const model = useAppModel()
    const option = useAppSelector(state => state.optionActionSlice)
    const isDisabled = option.loading === 'loading'

    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(optionActionSlice.actions.reset())
        }
    }, [dispatch]);

    if (!model.id) {
        return <></>
    }

    const onClick = () => {
        if (isDisabled) {
            return
        }
        dispatch(!option.active ? optionActionSlice.actions.handleSelect() : optionActionSlice.actions.reset())
    }

    const onClose = () => {
        if(isDisabled) {
            return;
        }
        props.onDelete();
    }

    return <div className={'flex justify-end'}>
        {option.active && <>
            <span
                onClick={onClose}
                className={`select-none ${isDisabled ? 'text-red-500/60 cursor-not-allowed' : 'text-red-500  cursor-pointer'}`}>
                Supprimer
            </span>
            <Divider direction={'y'} size={'xs'}/>
        </>}

        <span
            onClick={onClick}
            className={`select-none ${isDisabled ? 'text-gray-900/60 cursor-not-allowed' : 'text-gray-900 cursor-pointer '}`}>
                {!option.active ? 'Selectionner' : "Deselectionner"}
            </span>
    </div>
};
import {AppClient} from "../AppClient";

type Input = {
    "email": string;
    "username": string;
    "password": string;
    "confirmPassword": string;
    "device": string;
}

type OutputData = {};

export class AppRegisterClient extends AppClient<Input, OutputData> {

    async request(params: Input) {
        return await fetch(this.buildEndpoint('auth/signup'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


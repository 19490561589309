import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";
import {AppUpdateTopicModelClient} from "../../../http/api/app/topic/AppUpdateTopicModelClient";

export const updateTopicModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: {
        relevant?: boolean;
        id: string;
    }
) => {
    const state = getState()
    const manualTopicState = state.manualTopicSlice;
    const model = state.activeModelSlice.id

    if (model === null || manualTopicState.loading === 'loading') {
        return;
    }

    const client = new AppUpdateTopicModelClient();
    const response = await client.handleWithCredential({
        id: api.id,
        model: model,
        relevant: api.relevant,
    })

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    if(api.relevant !== undefined) {
        ToastHelper.add({
            content: `Votre assistant va s'améliorer`,
            type: 'success',
        })
    }

    dispatch(topicsListsSlice.actions.handleRemoveItem({
        model: model,
        id: response.data.value.id,
        relevant : response.data.value.relevant,
    }))

    const topicCount = ListHelper.getItem(model, state.topicCountSlice.values)
    if (topicCount && topicCount.count !== null) {
        dispatch(topicCountSlice.actions.handleItemValue({
            model: model,
            count: topicCount.count - 1
        }))
    }
}
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {CreditService} from "../../../service/credit/CreditService";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {CreditCountStorage} from "../../../service/storage/CreditCountStorage";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {AppGeneratePostClient} from "../../../http/api/app/post/AppGeneratePostClient";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {postCountSlice} from "../../feature/post/postCountSlice";

export const generatePostThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const postState = state.postsListsSlice;
    const model = state.activeModelSlice.id

    if (model === null) {
        return;
    }

    dispatch(postsListsSlice.actions.handleLoading('loading'))

    const client = new AppGeneratePostClient();
    const response = await client.handleWithCredential({
        model: model,
    })

    dispatch(postsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        if (response.code === AppCodeException.INSUFFICIENT_CREDIT) {
            message = 'Credit insuffisant';
        }

        if(response.code === AppCodeException.TOPICS_REQUIRED) {
            message = 'Veuillez ajouter des topics';
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    const creditCount = state.creditCountSlice.value;
    if (creditCount) {
        const creditService = new CreditService(creditCount);
        creditService.consumePostCreation();
        const newCountValue = creditService.getCount();
        dispatch(creditCountSlice.actions.handleValue(newCountValue))
        new CreditCountStorage().saveData({
            value: newCountValue,
        })
    }

    const posts = postState.lists.find(list => list.model === model)?.posts || []
    dispatch(postsListsSlice.actions.handleItems({
        model: model,
        posts: [{
            ...response.data.value,
            selected: false,
        }, ...posts]

    }))

    const postCount = ListHelper.getItem(model, state.postCountSlice.values)
    if(postCount &&  postCount.count !== null) {
        dispatch(postCountSlice.actions.handleItemValue({
            model: model,
            count: postCount.count + 1
        }))
    }

}
import {AppDispatch, RootState} from "../../store";
import {AppUpdatePostClient} from "../../../http/api/app/post/AppUpdatePostClient";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {GeneratedPost} from "../../../model/Post";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {postCountSlice} from "../../feature/post/postCountSlice";

export const updatePostRelevanceThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: { post: GeneratedPost, relevant: boolean }
) => {
    const state = getState();
    const model = state.activeModelSlice.id;

    if (!model) {
        return;
    }

    if (!api.relevant) {
        dispatch(postsListsSlice.actions.removeItem({
            model: model,
            post: api.post
        }))
    } else {
        dispatch(postsListsSlice.actions.handleItem({
            model: model,
            post: {
                ...api.post,
                relevant: api.relevant,
            }
        }))
    }

    const client = new AppUpdatePostClient()
    const response = await client.handleWithCredential({
        id: api.post.id,
        relevant: api.relevant,
    })

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: 'An error was occured',
            type: 'warning',
        })
        return;
    }

    const postCount = ListHelper.getItem(model, state.postCountSlice.values)
    if(postCount &&  postCount.count !== null && !api.relevant) {
        dispatch(postCountSlice.actions.handleItemValue({
            model: model,
            count: postCount.count  - 1
        }))
    }

    ToastHelper.add({
        content: "Votre assistant va s'améliorer",
        type: 'success',
    })
}
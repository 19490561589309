import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {AppListTopicsModelClient} from "../../../http/api/app/topic/AppListTopicsModelClient";
import {ToastHelper} from "../../../helper/ToastHelper";

export const getTopicsByModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: { model: string }
) => {
    const topicsListState = getState().topicsListsSlice;

    const topics = topicsListState.lists.find(list => list.model === api.model)?.topics;

    if (topics) {
        return;
    }

    if (topicsListState.loading === 'loading') {
        return;
    }

    dispatch(topicsListsSlice.actions.handleLoading('loading'))

    const client = new AppListTopicsModelClient();
    const response = await client.handleWithCredential({
        model: api.model
    })

    dispatch(topicsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(topicsListsSlice.actions.handleItems({
        model: api.model,
        topics: response.data.values.map(d => ({
            ...d,
            selected: false,
            model: api.model,
        }))
    }))
}


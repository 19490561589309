import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Button} from "../../../../component/button/Button";
import {routerPath} from "../../../routerPath";
import {useLocation} from "react-router";
import {useAppModel} from "../../../../hook/useAppModel";
import {generateApproachesThunk} from "../../../../redux/thunk/approach/generate-approaches-thunk";
import {useAppCredit} from "../../../../hook/useAppCredit";
import {CreditCost} from "../../../../model/CreditCost";
import {ToastHelper} from "../../../../helper/ToastHelper";
import {generateTopicsModelThunk} from "../../../../redux/thunk/topic/generate-topics-model-thunk";
import {useNavigate} from "react-router-dom";

export const AddMoreApproaches = () => {
    const model = useAppModel()
    const approchesLoaderState = useAppSelector(state => state.approachesListsSlice.loading)
    const addApproachValueState = useAppSelector(state => state.addApproachValueSlice)
    const optionActionState = useAppSelector(state => state.optionActionSlice)
    const credit = useAppCredit()
    const navigate = useNavigate()

    const location = useLocation();

    const isDisabled = model.id === null ||
        approchesLoaderState === "loading" ||
        addApproachValueState.loading === 'loading' ||
        optionActionState.active ||
        optionActionState.loading === "loading"


    const isHidden = location.pathname !== routerPath.approach.base || !model.hasModels

    const dispatch = useAppDispatch();

    const onClick = () => {
        if (credit.value !== null && credit.value < CreditCost.APPROACH) {
            ToastHelper.add({
                type: 'warning',
                content: "Crédit insuffisant",
                action: {
                    label: 'Recharger',
                    onClick: () => navigate(routerPath.credit.base),
                }
            })
            return;
        }
        dispatch(generateApproachesThunk)
    }


    if (isHidden) {
        return <></>
    }

    return <Button
        custom={{
            color: 'blue',
        }}
        base={{
            className: 'center max-w-max',
            disabled: isDisabled,
            onClick: onClick,
        }}
    >
        Générer
    </Button>

};
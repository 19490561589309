import {AppDispatch, RootState} from "../../store";
import {AppUpdatePostClient} from "../../../http/api/app/post/AppUpdatePostClient";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {GeneratedPost} from "../../../model/Post";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";

export const updatePostContentThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: {
        post: GeneratedPost,
        content: string;
        onLoaderChange : (state:boolean) => void,
        onSuccess: () =>  void
    }
) => {
    const state = getState();
    const model = state.activeModelSlice.id;

    if (!model) {
        return;
    }

    dispatch(postsListsSlice.actions.handleItem({
        model: model,
        post: {
            ...api.post,
            relevant: true,
            content: api.content,
        }
    }))

    api.onLoaderChange(true)

    const client = new AppUpdatePostClient()
    const response = await client.handleWithCredential({
        id: api.post.id,
        relevant: true,
        content: api.content,
    })

    api.onLoaderChange(false)

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: `Une erreur est survenue - ${response.code}`,
            type: 'warning',
        })
        return;
    }

    api.onSuccess()
}
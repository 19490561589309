export class TokenService {
    constructor(
        private readonly token: string,
    ) {
    }

    private decode() {
        const base64Url = this.token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        return JSON.parse(jsonPayload);
    }

    isValid(): boolean {
        const token = this.decode()
        return 'iat' in token &&
            'sub' in token &&
            'username' in token &&
            typeof token.iat === "number" &&
            typeof token.sub === "string" &&
            typeof token.username === "string"

    }

    isExired(): boolean {
        const token = this.decode();
        const datetimeIat = token.iat * 1_000;
        const datetimeNow =  Date.now() * 1_000
        return datetimeNow <= datetimeIat;
    }
}
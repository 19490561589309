import {IValidator} from "./IValidator";

export class EmailValidator extends IValidator<string> {
    isValid(value: string)  : boolean {

        if(value.trim().length === 0) {
            this.message = 'Valeur requise'
            return false;
        }

        const isEmail = String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        if (!isEmail) {
            this.message = 'Invalid email address';
            return false;
        }
        return true
    }
}
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Card} from "../../../../component/card/Card";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {Square, SquareCheckBig, ThumbsDown,} from "lucide-react";
import {topicsListsSlice} from "../../../../redux/feature/topic/topicsListsSlice";
import {RequiredTopic} from "../../../../model/Topic";
import {useAppModel} from "../../../../hook/useAppModel";
import {updateTopicModelThunk} from "../../../../redux/thunk/topic/update-topic-model-thunk";

type TopicListItemProps = {
    topic: RequiredTopic
}

export const TopicListItem = (
    {
        topic,
    }: TopicListItemProps
) => {
    const model = useAppModel()

    const optionActionState = useAppSelector(state => state.optionActionSlice)

    // const selectable = useAppSelector(state => state.furthersInformationListsSlice.selectable)
    const topicsListsLoaderState = useAppSelector(state => state.topicsListsSlice.loading)
    const manualTopicValue = useAppSelector(state => state.manualTopicSlice.loading)
    const isDisabled = topicsListsLoaderState === 'loading' ||
        manualTopicValue === 'loading' ||
        optionActionState.loading === 'loading'

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (optionActionState.active) {
            return
        }

        if (!model.id) {
            return;
        }

        dispatch(topicsListsSlice.actions.handleUnselectAll({
            model: model.id,
        }))

    }, [dispatch, optionActionState.active, model.id]);

    const onToggleSelection = () => {
        if (isDisabled || !optionActionState.active) {
            return;
        }

        dispatch(topicsListsSlice.actions.handleSelectItem({
            model: topic.model,
            topic: topic.id
        }))
    }

    return <div className={`overflow-hidden rounded-md`}>
        <div className={'w-full'}>
            <Card
                base={{
                    className: `w-full transition-colors ${topic.selected ? 'bg-blue-600 text-white hover:bg-blue-500' : ''}  ${optionActionState.active ? 'cursor-pointer' : ''} ${isDisabled ? 'text-gray-400' : ''}`,
                    onClick: onToggleSelection,
                }}

            >
                <div className="flex justify-between">
                    <Paragraph className={'w-full'}>{topic.value}</Paragraph>
                    <div className={'center'}>
                        {optionActionState.active && !isDisabled ? topic.selected ? <SquareCheckBig width={15}/> :
                            <Square width={15}/> : <></>}
                    </div>
                    {!optionActionState.active && <button
                        onClick={() => dispatch((dispatch, getState) => updateTopicModelThunk(dispatch, getState, {
                            relevant: false,
                            id: topic.id
                        }))} className={'cursor-pointer p-1'}
                    >
                        <ThumbsDown className={'w-4 h-4'}/>
                    </button>}

                </div>
            </Card>
        </div>
    </div>
};
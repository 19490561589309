import React, {useEffect} from 'react';
import {useAppDispatch} from "../../../redux/store";
import {listModelsThunk} from "../../../redux/thunk/model/list-models-thunk";
import {ModelsList} from "./component/ModelsList";
import {ModelDetail} from "./component/ModelDetail";
import {Divider} from "../../../component/divider/Divider";
import {modelsListSlice} from "../../../redux/feature/model/modelsListSlice";
import {Alert} from "../../../component/alert/Alert";

type ListModelsProps = {}

export const ListModelsPage = (props: ListModelsProps) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(listModelsThunk)
        return () => {
            dispatch(modelsListSlice.actions.handleDetail(null))
        }
    }, [dispatch]);

    return <div>
        <div className={'pr-4'}>
            <Alert
                type={'neutral'}
                title={'Assistants'}
                message={'Configurez vos assistants pour les rendre autonome dans la création de contenu. Dédiez un assistant par thème pour automatiser votre contenu'}
            />
            <Divider direction={'x'} size={'md'} />
        </div>
        <ModelsList/>
        <ModelDetail/>
    </div>
};
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ListItemModel} from "../../../model/Model";


export interface ListModelsSliceState {
    loading: "idle" | 'loading' | 'loaded';
    values: ListItemModel[]
    detail: ListItemModel | null
}

const initialState: ListModelsSliceState = {
    loading: 'idle',
    values: [],
    detail: null,
}

export const modelsListSlice = createSlice({
    name: 'listModels',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<ListModelsSliceState['values']>) => {
            state.values = action.payload
        },
        addItem: (state, action: PayloadAction<ListModelsSliceState['values'][number]>) => {
            const index = state.values.findIndex(item => item.id === action.payload.id)
            if(index > 0) {
                return;
            }

            state.values = [action.payload, ...state.values]
        },
        handleItem: (state, action: PayloadAction<ListModelsSliceState['values'][number]>) => {
            const index = state.values.findIndex(item => item.id === action.payload.id)
            if(index < 0) {
                return;
            }

            state.values[index] = action.payload
        },
        handleLoading: (state, action: PayloadAction<ListModelsSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleDetail: (state, action: PayloadAction<ListModelsSliceState['detail']>) => {
            state.detail = action.payload
        },
        reset: () => initialState
    },
})
import React from 'react';
import {Divider} from "../../../component/divider/Divider";
import {AddApproachInput} from "./component/AddApproachInput";
import {ApproachesList} from "./component/ApproachesList";
import {Alert} from "../../../component/alert/Alert";

type ApproachPageProps = {}

export const ApproachPage = (props: ApproachPageProps) => {
    return <div className={'pr-4 relative'}>
        <div className={'pr-4'}>
            <Alert
                type={'neutral'}
                title={'Inspiration'}
                message={"Gérez vos angles d'approches et les manière particulières dont votre assistant pourra aborder un thème. Vous pouvez en générer ou les créer manuellement"}
            />
        </div>

        <Divider direction={'x'} size={"xs"}/>

        <AddApproachInput/>
        <ApproachesList/>
    </div>
};
import {AppClient} from "../AppClient";
import {GeneratedPost} from "../../../../model/Post";

type Input = {
    model: string
}

type OutputData = {
    values: GeneratedPost[];
};

export class AppGetPostsClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`post?model=${params.model}`), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


import React, {PropsWithChildren} from 'react';
import {
    DialogCn,
    DialogContentCn,
    DialogDescriptionCn,
    DialogHeaderCn,
    DialogTitleCn,
    DialogTriggerCn,
} from "./Component"
import {Divider} from "../divider/Divider";
import {DialogProps, DialogTriggerProps} from "@radix-ui/react-dialog";

type AppDialogProps = {
    trigger?: {label: string, props?: DialogTriggerProps};
    title: string;
    open?: boolean;
} & PropsWithChildren & { dialogCnProps?: DialogProps }

export const Dialog = (props: AppDialogProps) => {

    return <DialogCn
        {...props.dialogCnProps}
    >
        {props.trigger && <DialogTriggerCn {...props.trigger.props}>{props.trigger.label}</DialogTriggerCn>}
        <DialogContentCn>
            <DialogHeaderCn>
                <DialogTitleCn>{props.title}</DialogTitleCn>
                <Divider direction={'x'} size={'xs'}/>

                {typeof props.children === "string" ?
                    <DialogDescriptionCn>{props.children}</DialogDescriptionCn>
                    : <div aria-describedby={'model'}>
                        {props.children}
                    </div>
                }
            </DialogHeaderCn>
        </DialogContentCn>
    </DialogCn>
};
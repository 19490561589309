import {AppClient} from "../AppClient";

type Input = {
    quantity: number,
    productId : string,
    type: "credit"
}

type OutputData = {
    checkoutUrl: string
};

export class AppCreateCheckoutSessionClient extends AppClient<Input, OutputData> {

    async request(params: Input) {
        return await fetch(this.buildEndpoint('checkout/create-session'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';

type ListViewProps = {} & PropsWithChildren

export const ListView = (props: ListViewProps) => {
    const [height, setHeight] = useState<number | null>(null);
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const onResizeScreen = (element: HTMLDivElement | null) => {
            if (!element) {
                return
            }
            onUpdateHeight(ref.current)
        }
        onResizeScreen(ref.current)
        window.addEventListener('resize', () => onResizeScreen(ref.current))
        return () => window.removeEventListener('resize', () => onResizeScreen(null))
    }, [ref, props.children]);


    function onUpdateHeight(element: HTMLDivElement | null) {
        if (!element) {
            return;
        }
        const screenHeight = window.innerHeight;
        const top = element.getBoundingClientRect().top
        setHeight(screenHeight - top)
    }

    return <div
        style={{height: `${height || 0}px`}}
        ref={ref}
        className={'w-full overflow-y-auto pb-8'}
    >
        {props.children}
    </div>
};
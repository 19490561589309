import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {AppGenerateTopicsModelClient} from "../../../http/api/app/topic/AppGenerateTopicsModelClient";
import {CreditService} from "../../../service/credit/CreditService";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {CreditCountStorage} from "../../../service/storage/CreditCountStorage";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ToastHelper} from "../../../helper/ToastHelper";
import {topicCountSlice} from "../../feature/topic/topicCountSlice";
import {ListHelper} from "../../../helper/list-helper/ListHelper";

export const generateTopicsModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const model = state.activeModelSlice.id

    if (model === null) {
        return;
    }

    const topicState = state.topicsListsSlice;
    const toipcCountState = state.topicCountSlice

    dispatch(topicsListsSlice.actions.handleLoading('loading'))

    const client = new AppGenerateTopicsModelClient();
    const response = await client.handleWithCredential({
        model: model,
    })

    dispatch(topicsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        if (response.code === AppCodeException.INSUFFICIENT_CREDIT) {
            message = 'Credit insuffisant';
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    const creditCount = getState().creditCountSlice.value;
    if (creditCount) {
        const creditService = new CreditService(creditCount);
        creditService.consumeTopicCreation();
        const newCountValue = creditService.getCount();
        dispatch(creditCountSlice.actions.handleValue(newCountValue))
        new CreditCountStorage().saveData({
            value: newCountValue,
        })
    }

    const topics = topicState.lists.find(list => list.model === model)?.topics || []
    const newTopics = response.data.values.map(t => ({
        ...t,
        model: model,
        selected: false,
    }))

    dispatch(topicsListsSlice.actions.handleItems({
        model: model,
        topics: [...newTopics, ...topics]
    }))

    const currentCount = ListHelper.getItem(model, toipcCountState.values)
    if(currentCount && currentCount.count) {
        dispatch(topicCountSlice.actions.handleItemValue({
            model : model,
            count : currentCount.count + response.data.values.length,
        }))
    }

    const count = response.data.values.length
    ToastHelper.add({
        content: `${count} Sujet${count > 1 ? 's' : ''} crée${count > 1 ? 's' : ''}`,
        type: 'success',
    })
}
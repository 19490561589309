import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UserEmailSliceState {
    value: string | null,
}

const initialState: UserEmailSliceState = {
    value: null,
}


export const emailSlice = createSlice({
    name: 'authEmail',
    initialState: initialState,
    reducers: {
        update: (state, action: PayloadAction<UserEmailSliceState>) => ({
            value : action.payload.value
        }),
        reset: () => initialState,
    },
})
import React, {PropsWithChildren} from 'react';
import {Outlet, redirect} from "react-router-dom";
import {TokenStorage} from "../../service/storage/TokenStorage";
import {useLocation} from "react-router";
import {LoginPage} from "../page/login/LoginPage";
import {routerPath} from "../routerPath";

export function loader() {
    const token = new TokenStorage().getData()?.value

    if (token) {
        return redirect(routerPath.authentication.base)
    }

    return null;
}

export const UnauthenticatedRoute = () => {
    const location = useLocation();


    const Container = ({children} : PropsWithChildren) => <div className={'min-h-screen px-2 py-12 md:px-4 md:py-24'}>{children}</div>
    if (location.pathname === routerPath.authentication.base) {
        return <Container><LoginPage/></Container>
    }

    return <Container><Outlet/></Container>
};
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Gender} from "../../../model/Gender";
import {ListItemModel} from "../../../model/Model";
import {Platform} from "../../../model/Platform";
import {Tone} from "../../../model/Tone";
import {DEFAULT_TONES_ITEM} from "../../../route/page/update-model/component/Tone";

export type ToneState = Required<Pick<Tone, "label" | "recurrence" | 'id'>>;

type ModelState = Omit<ListItemModel, 'id' | 'name' | 'createdAt'|'theme'> & {
    name: string | null;
    theme: string | null;
    id: string | null;
    createdAt: Date | null;
}
export type ModelSliceState = ModelState & {
    loading: boolean;
}

const initialState: ModelSliceState = {
    gender: Gender.MALE,
    theme: null,
    thou: true,
    platform: Platform.LINKEDIN,
    loading: false,
    name: null,
    id: null,
    createdAt: null,
    suffix: null,
}

export const modelSlice = createSlice({
    name: 'model',
    initialState: initialState,
    reducers: {
        // handleTone: (state, action: PayloadAction<ModelSliceState['tones'][number]>) => {
        //     const index = state.tones.findIndex(t => t.id === action.payload.id)
        //     if (index < 0) {
        //         state.tones = state.tones.concat(action.payload)
        //     } else {
        //         state.tones[index] = action.payload;
        //     }
        // },
        handleGender: (state, action: PayloadAction<ModelSliceState['gender']>) => {
            state.gender = action.payload
        },
        handleTheme: (state, action: PayloadAction<ModelSliceState['theme']>) => {
            state.theme = action.payload
        },
        handleThou: (state, action: PayloadAction<ModelSliceState['thou']>) => {
            state.thou = action.payload
        },
        handlePlatform: (state, action: PayloadAction<ModelSliceState['platform']>) => {
            state.platform = action.payload
        },
        handleLoading: (state, action: PayloadAction<ModelSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleSuffix(state, action: PayloadAction<ModelSliceState['suffix']>) {
            state.suffix = action.payload
        },
        handleName: (state, action: PayloadAction<ModelSliceState['name']>) => {
            state.name = action.payload
        },
        handleModel(state, action: PayloadAction<ModelState>) {
            state.id = action.payload.id;
            state.gender = action.payload.gender;
            state.theme = action.payload.theme
            state.thou = action.payload.thou
            state.platform = action.payload.platform
            state.loading = false;
            state.name = action.payload.name
            state.suffix  = action.payload.suffix
        },
        reset: () => initialState
    },
})
import {IValidator} from "./IValidator";

export class ApproachValidator extends IValidator<string> {
    isValid(value: string) {

        if (value.trim().length === 0) {
            this.message = 'Valeur requise';
            return false;
        }

        const max = 1000
        if (value.length > max) {
            this.message = `Max atteint ${value.length} / ${max}`;
            return false;
        }

        return true;
    }
}
import {IApiClient} from "../IApiClient";

export abstract class ApiClient<I, O> implements IApiClient<I, O> {
    protected headers: { [key: string]: string } = {
        "Content-Type": "application/json",
    };

    abstract handle(params?: I): Promise<O>;

    protected abstract getDomainTarget(): string;

    protected buildEndpoint(uri: string) {
        return `${this.getDomainTarget()}/${uri}`
    }

    /**
     * Request which must be processed by handle function
     */
    protected abstract request(params: I|undefined): Promise<Response>;
}
import React from 'react';
import {modelSlice} from "../../../../redux/feature/model/modelSlice";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {CardContainer} from "./CardContainer";
import {Input} from "../../../../component/input/Input";
import {ModelNameValidator} from "../../../../validator/ModelNameValidator";
import {CardContainerLabel} from "./CardContainerLabel";

type NameProps = {}

export const Name = (props: NameProps) => {
    const dispatch = useAppDispatch()
    const modelName = useAppSelector((state) => state.modelSlice.name)

    let errorMessage = null;
    const validator = new ModelNameValidator()
    if (modelName !== null && !validator.isValid(modelName)) {
        errorMessage = validator.getMessage()
    }

    const onChange = (value: string) => {
        dispatch(modelSlice.actions.handleName(value))
    }

    return <CardContainer>
        <CardContainerLabel htmlFor={'model-name'}>Nom de l'assistant</CardContainerLabel>
        <Input
        custom={{
            errorMessage : errorMessage,
        }}
        base={{
            placeholder: 'Anexdot',
            name: 'model-name',
            value: modelName || '',
            onChange: (e) => onChange(e.target.value),
        }}
    />
    </CardContainer>
};
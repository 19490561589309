import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {GeneratedPost} from "../../../model/Post";

export interface PostsSliceState {
    loading: LoadingState;
    lists: { model: string, posts: GeneratedPost[] }[]
    selectable: boolean

}

const initialState: PostsSliceState = {
    loading: 'idle',
    lists: [],
    selectable: false,
}

export const postsListsSlice = createSlice({
    name: 'postsLists',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<PostsSliceState['lists'][number]>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                lists.push({
                    model: action.payload.model,
                    posts: action.payload.posts,
                })
                return
            }

            lists[listIndex].posts = action.payload.posts;
            // state.lists = lists
        },
        handleItem: (state, action: PayloadAction<{
            model: string
            post: GeneratedPost;
        }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            const index = lists[listIndex].posts.findIndex(post => post.id === action.payload.post.id)
            if (index < 0) {
                return;
            }

            state.lists[listIndex].posts[index] = action.payload.post;
        },
        removeItem: (state, action: PayloadAction<{
            model: string
            post: GeneratedPost;
        }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            const index = lists[listIndex].posts.findIndex(post => post.id === action.payload.post.id)
            if (index < 0) {
                return;
            }

            state.lists[listIndex].posts = state.lists[listIndex].posts.filter(p => p.id !== action.payload.post.id);
        },
        handleLoading: (state, action: PayloadAction<PostsSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleSelectable: (state, action: PayloadAction<PostsSliceState['selectable']>) => {
            state.selectable = action.payload
        },
        handleSelectedItem: (state, action: PayloadAction<{ post: string, model: string }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            const index = lists[listIndex].posts.findIndex(post => post.id === action.payload.post)
            if (index < 0) {
                return;
            }

            lists[listIndex].posts[index].selected = !lists[index].posts[index].selected;
            state.lists = lists
        },
        reset: () => initialState
    },
})
import {AppClient} from "../AppClient";

type Input = {
    "email": string;
    "password": string;
    "device": string;
}

type OutputData = {
    access_token: string;
};

export class AppLoginClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('auth/signin'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import React, {useEffect} from 'react';
import {Divider} from "../../../../component/divider/Divider";
import {RadioGroup} from "../../../../component/radio-group/RadioGroup";
import {modelSlice} from "../../../../redux/feature/model/modelSlice";
import {CardContainerLabel} from "./CardContainerLabel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Gender as EnumGender} from "../../../../model/Gender";
import {CardContainer} from "./CardContainer";

type GenderProps = {}

export const Gender = (props: GenderProps) => {
    const dispatch = useAppDispatch()
    const modelGender = useAppSelector((state) => state.modelSlice.gender)

    useEffect(() => {
    }, [modelGender]);

    return <CardContainer className={'flex'}>
        <CardContainerLabel>Quel type d'expression ?</CardContainerLabel>
        <Divider direction={'y'} size={'sm'}/>
        <RadioGroup
            className={'flex'}
            onClick={(item) => dispatch(modelSlice.actions.handleGender(item.value === EnumGender.MALE ? EnumGender.MALE : EnumGender.FEMALE))}
            items={[
                {label: 'Masculin', value: EnumGender.MALE},
                {label: 'Feminin', value: EnumGender.FEMALE},
            ]}
            defaultValue={modelGender}
        />
    </CardContainer>
};
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {AppGetCreditCountClient} from "../../../http/api/app/credit/AppGetCreditCountClient";
import {CreditCountStorage} from "../../../service/storage/CreditCountStorage";
import {ToastHelper} from "../../../helper/ToastHelper";


export const getCreditCountThunk = async (
    dispatch: AppDispatch,
    _: () => RootState,
) => {
    dispatch(creditCountSlice.actions.handleLoading('loading'))

    const client = new AppGetCreditCountClient();
    const response = await client.handleWithCredential()

    dispatch(creditCountSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = `Une erreur est survenue - ${response.code}`;

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    new CreditCountStorage().saveData({
        value: response.data.count
    })

    dispatch(creditCountSlice.actions.handleValue(response.data.count))
}
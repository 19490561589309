import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {Listable} from "../../../helper/lists-helper/Listable";
import {ApproachListItem} from "../../../model/Approach";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";

type ApproachList = Listable<ApproachListItem>
type ApproachLists = ApproachList[]

export interface ApproachesListsSliceState {
    loading: LoadingState;
    values: ApproachLists
}

const initialState: ApproachesListsSliceState = {
    loading: 'idle',
    values: [],
}

export const approachesListsSlice = createSlice({
    name: 'approachesLists',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<ApproachList>) => {
            state.values = ListsHelper.updateList<ApproachListItem>({
                lists: state.values,
                list: action.payload,
            });
        },
        addItem: (state, action: PayloadAction<{
            id: ApproachList['id'],
            value: ApproachList['values'][number],

        }>) => {
            state.values = ListsHelper.addItem<ApproachListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
                options: {
                    appendStart: true,
                }
            })
        },
        handleItem: (state, action: PayloadAction<{
            id: ApproachList['id'],
            value: ApproachList['values'][number],
        }>) => {

            const values = ListsHelper.updateListableItemToList<ApproachListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
            })

            if (!values) {
                return
            }

            state.values = values;
        },
        handleLoading: (state, action: PayloadAction<ApproachesListsSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleUnselectAll: (state, action: PayloadAction<{ model: ApproachList['id'] }>) => {

            const listable = ListsHelper.getList({
                lists: state.values,
                id: action.payload.model,
            })

            if (!listable) {
                return
            }

            state.values = ListsHelper.updateList<ApproachListItem>({
                lists: state.values,
                list: {
                    ...listable,
                    values: listable.values.map((list) => {
                        list.selected = false;
                        return list
                    })
                },
            });
        },
        handleRemoveItem(state, action: PayloadAction<{
            id: ApproachList['id'],
            value: ApproachList['values'][number],
        }>) {
            state.values = ListsHelper.removeItem<ApproachListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
            })
        },
        reset: () => initialState
    },
})
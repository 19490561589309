import React from 'react';
import {Input} from "../../../../component/input/Input";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {PasswordValidator} from "../../../../validator/PasswordValidator";
import {passwordSlice} from "../../../../redux/feature/auth/passwordSlice";

type PasswordInputProps = {
    autoComplete: 'new-password' | 'current-password';
}
export const PasswordInput = (props: PasswordInputProps) => {
    const state = useAppSelector((state) => state.passwordSlice)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const dispatch = useAppDispatch()

    let errorMessage = null;
    const validator = new PasswordValidator()

    if (state.value !== null && !validator.isValid(state.value)) {
        errorMessage = validator.getMessage()
    }

    return <div>
        <Input
            custom={{
                label: 'Password',
                errorMessage: errorMessage,
            }}
            base={{
                name: 'password',
                onChange: (e) => dispatch(passwordSlice.actions.update({
                    value: e.target.value,
                })),
                placeholder: 'Tapez votre mot de passe',
                type: 'password',
                value: state.value ?? '',
                autoComplete: props.autoComplete,
                disabled: loading,
            }}
        />
    </div>
};
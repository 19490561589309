import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {seedsListsSlice} from "../../feature/seed/seedsListsSlice";
import {AppListSeedsClient} from "../../../http/api/app/seed/AppListSeedsClient";

export const getSeedsThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state=  getState();
    const model = state.activeModelSlice.id;

    if(!model) {
        return;
    }

    const seedsListState = getState().seedsListsSlice;
    const seeds = ListsHelper.getList({
        lists: seedsListState.values,
        id: model
    })

    if (seeds || seedsListState.loading === 'loading') {
        return;
    }
    dispatch(seedsListsSlice.actions.handleLoading('loading'))

    const client = new AppListSeedsClient();
    const response = await client.handleWithCredential({
        model: model
    })

    dispatch(seedsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        ToastHelper.add({
            content: 'Une erreur est survenue',
            type: 'warning',
        })
        return;
    }

    dispatch(seedsListsSlice.actions.handleItems({
        id: model,
        values: response.data.values.map(d => ({
            ...d,
            selected: false,
            model: model,
        }))
    }))
}
import React from 'react';
import {useAppSelector} from "../../../../redux/store";
import {LucideInfo} from "lucide-react";
import {Divider} from "../../../../component/divider/Divider";
import {PostsSliceState} from "../../../../redux/feature/post/postsListsSlice";

type NoPostProps = {
    list?: PostsSliceState['lists'][number]
}

export const NoPost = ({list}: NoPostProps) => {
    const loader = useAppSelector(state => state.postsListsSlice.loading)

    if (loader !== "idle" || (list && list.posts?.length > 0)) {
        return <></>
    }

    return <div className={'center h-96'}>
        <LucideInfo/>
        <Divider direction={'y'} size={'xs'}/>
        <i>Aucun contenu n'a été encore été crée</i>
    </div>
};
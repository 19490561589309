import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {getTopicsByModelThunk} from "../../../../redux/thunk/topic/get-topics-by-model-thunk";
import {TopicListItem} from "./TopicListItem";
import {Divider} from "../../../../component/divider/Divider";
import {useAppModel} from "../../../../hook/useAppModel";
import {LucideInfo} from "lucide-react";
import {OptionAction} from "../../../../component/option-action/OptionAction";
import {removeTopicsByIdThunk} from "../../../../redux/thunk/topic/remove-topics-by-id-thunk";
import {TopicListLoader} from "./TopicListLoader";
import {ListView} from "../../../../component/list-view/ListView";

export const TopicsList = () => {
    const model = useAppModel()
    const lists = useAppSelector(state => state.topicsListsSlice.lists)
    const list = lists.find(list => list.model === model.id)

    const dispatch = useAppDispatch();

    useEffect(() => {
        const model_ = model.id
        if (!model_) return;
        dispatch((dispatch, getState) => getTopicsByModelThunk(dispatch, getState, {
            model: model_
        }))
    }, [dispatch, model]);


    return <div className={'h-full pr-4'}>
        {list?.topics && list.topics.length > 0 && <OptionAction  onDelete={() => dispatch(removeTopicsByIdThunk)}/>}

        <Divider direction={'x'} size={"xs"}/>

        <ListView>
            {list?.topics.map((topic, index) => <div key={index}>
                <TopicListItem
                    topic={topic}
                />
                <Divider direction={'x'} size={'xs'}/>
            </div>)}
        </ListView>

        <TopicListLoader />

        {(!model.id && list?.topics.length === 0) && <div className={'center h-96'}>
            <LucideInfo/>
            <Divider direction={'y'} size={'xs'}/>
            <i>Créez des sujets pour pouvoir créer du contenu</i>
        </div>}
    </div>
};
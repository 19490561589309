import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Button} from "../../../../component/button/Button";
import {generatePostThunk} from "../../../../redux/thunk/post/generate-post-thunk";
import {routerPath} from "../../../routerPath";
import {useLocation} from "react-router";
import {useAppModel} from "../../../../hook/useAppModel";
import {CreditCost} from "../../../../model/CreditCost";
import {useAppCredit} from "../../../../hook/useAppCredit";
import {ToastHelper} from "../../../../helper/ToastHelper";
import {useNavigate} from "react-router-dom";

type AddMoreTopicsProps = {}

export const AddMorePosts = (props: AddMoreTopicsProps) => {
    const model = useAppModel()
    const postsState = useAppSelector(state => state.postsListsSlice)
    const location = useLocation()
    const credit = useAppCredit()
    const navigate = useNavigate()

    const isDisabled = model.id === null || postsState.loading === "loading"
    const isHidden = location.pathname !== routerPath.post.base || !model.hasModels

    const dispatch = useAppDispatch();

    const onClick = () => {
        if (credit.value !== null && credit.value < CreditCost.POST) {
            ToastHelper.add({
                type: 'warning',
                content: "Crédit insuffisant",
                action: {
                    label: 'Recharger',
                    onClick: () => navigate(routerPath.credit.base),
                }
            })
            return;
        }
        dispatch(generatePostThunk)
    }

    if (isHidden) {
        return <></>
    }

    return <Button
        custom={{
            color: 'blue',
        }}
        base={{
            className: 'center max-w-max',
            disabled: isDisabled,
            onClick: onClick
        }}
    >
        Générer
    </Button>

};
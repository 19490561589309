import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {AppReadEntityCountClient, ReadCountEntityType} from "../../../http/api/app/count/AppReadEntityCountClient";
import {LoadingState} from "../../../type/component/loading-state";


export const readCountThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: {
        entity: ReadCountEntityType,
        state: () => { load: LoadingState, value: number | null }
        setLoadingState: () => void
        setLaodedState: () => void
        setValue: (value: number | null) => void
    }
) => {
    const state = getState()

    const modelDetail = state.modelsListSlice.detail

    if (!modelDetail) {
        return;
    }

    const entityState = api.state()

    if(entityState.value !== null) {
        return
    }

    if (entityState.load === "loaded") {
        return;
    }

    api.setLoadingState();

    const client = new AppReadEntityCountClient();
    const response = await client.handleWithCredential({
        model: modelDetail.id,
        entity: api.entity,
    });

    api.setLaodedState();

    if (isAppErrorResponse(response)) {
        let message: string = `Une erreur est survenue - ${response.code}`;

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    api.setValue(response.data.value);
}
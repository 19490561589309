import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SeedListItem} from "../../../model/Seed";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {Listable} from "../../../helper/lists-helper/Listable";

type SeedList = Listable<SeedListItem>
type SeedLists = SeedList[]

export interface SeedsListsSliceState {
    loading: "idle" | 'loading' | 'loaded';
    values: SeedLists
}

const initialState: SeedsListsSliceState = {
    loading: 'idle',
    values: [],
}

export const seedsListsSlice = createSlice({
    name: 'seedsLists',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<SeedList>) => {
            state.values = ListsHelper.updateList<SeedListItem>({
                lists: state.values,
                list: action.payload,
            });
        },
        addItem: (state, action: PayloadAction<{
            id: SeedList['id'],
            value: SeedList['values'][number],

        }>) => {
            state.values = ListsHelper.addItem<SeedListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
                options: {
                    appendStart:  true,
                }
        })
        },
        handleItem: (state, action: PayloadAction<{
            id: SeedList['id'],
            value: SeedList['values'][number],
        }>) => {

            const values = ListsHelper.updateListableItemToList<SeedListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
            })

            if (!values) {
                return
            }

            state.values = values;
        },
        handleLoading: (state, action: PayloadAction<SeedsListsSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleUnselectAll: (state, action: PayloadAction<{ model: SeedList['id'] }>) => {

            const listable = ListsHelper.getList({
                lists: state.values,
                id: action.payload.model,
            })

            if (!listable) {
                return
            }

            state.values = ListsHelper.updateList<SeedListItem>({
                lists: state.values,
                list: {
                    ...listable,
                    values: listable.values.map((list) => {
                        list.selected = false;
                        return list
                    })
                },
            });
        },
        reset: () => initialState
    },
})
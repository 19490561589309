import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingStateWithoutLoaded} from "../../../type/component/loading-state";

export interface FeedbackSliceState {
    value: string | null;
    loader: LoadingStateWithoutLoaded;
    open: boolean;
}

const initialState: FeedbackSliceState = {
    value: null,
    loader: 'idle',
    open: false,
}

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: initialState,
    reducers: {
        handleValue: (state, action: PayloadAction<FeedbackSliceState['value']>) => {
            state.value = action.payload
        },
        handleLoader: (state, action: PayloadAction<FeedbackSliceState['loader']>) => {
            state.loader = action.payload
        },
        handleOpen: (state, action: PayloadAction<FeedbackSliceState['open']>) => {
            state.open = action.payload
        },
        reset: () => initialState,
    },
})
import IDataStorage from "./core/IDataStorage";
import {StorageKey} from "./core/StorageKey";

export class LocalStorage<Data> extends IDataStorage<Data> {
    protected version: number = 1;
    protected key: StorageKey = StorageKey.userToken;

    getStorage() {
        return {
            set: (key: StorageKey, value: string) => window.localStorage.setItem(key, value),
            get: (key: StorageKey) => window.localStorage.getItem(key) || null,
            remove: (key: StorageKey) => window.localStorage.removeItem(key),
        };
    }
}
import {
    createBrowserRouter,
} from "react-router-dom";
import {RegisterPage} from "./page/register/RegisterPage";
import {LoginPage} from "./page/login/LoginPage";
import React from "react";
import {AuthenticatedRoute, loader as authenticatedLoader} from "./root/AuthenticatedRoute";
import {UnauthenticatedRoute, loader as unauthenticatedLoader} from "./root/UnauthenticatedRoute";
import {UpdateModelPage, loader as updateModelLoader} from "./page/update-model/UpdateModelPage";
import {ListModelsPage} from "./page/list-models/ListModelsPage";
import {routerPath} from "./routerPath";
import {CreditPage} from "./page/credit/CreditPage";
import {ErrorPage} from "./page/error/ErrorPage";
import {TopicsPage} from "./page/topics/TopicsPage";
import {PostPage} from "./page/post/PostPage";
import {SeedsPage} from "./page/seed/SeedsPage";
import {ApproachPage} from "./page/approach/ApproachPage";
import {VerificationPage} from "./page/verification/VerificationPage";


export const router = createBrowserRouter([
    {
        path: routerPath.authentication.base,
        element: <AuthenticatedRoute/>,
        loader: authenticatedLoader,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: routerPath.model.base,
                element: <ListModelsPage/>,
            },
            {
                path: routerPath.topic.base,
                element: <TopicsPage/>,
            },
            {
                path: routerPath.post.base,
                element: <PostPage/>,
            },
            {
                path: routerPath.model.create,
                element: <UpdateModelPage/>,
            },
            {
                path: routerPath.model.update,
                element: <UpdateModelPage/>,
                loader: ({params}) => updateModelLoader(params),
            },
            {
                path: routerPath.credit.base,
                element: <CreditPage/>,
            },
            {
                path: routerPath.seed.base,
                element: <SeedsPage />,
            },
            {
                path: routerPath.approach.base,
                element: <ApproachPage/>,
            },
        ]
    },
    {
        path: routerPath.authentication.base,
        element: <UnauthenticatedRoute/>,
        loader: unauthenticatedLoader,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: routerPath.authentication.login,
                element: <LoginPage/>,
            },
            {
                path: routerPath.authentication.register,
                element: <RegisterPage/>,
            },
        ]
    },
    {
        path: routerPath.verification.base,
        element: <VerificationPage/>,
    },
]);

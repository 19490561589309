import {AppClient} from "../AppClient";
import {Seed} from "../../../../model/Seed";


type Input = {
    model: string
}
type OutputData = {
    values: Required<Pick<Seed, 'id'| 'content'| 'createdAt'>>[]
};

export class AppListSeedsClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`seed?model=${params.model}`), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


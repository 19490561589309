import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppListModelsClient} from "../../../http/api/app/model/AppListModelsClient";
import {modelsListSlice} from "../../feature/model/modelsListSlice";
import {ToastHelper} from "../../../helper/ToastHelper";

export const listModelsThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const modelsListState = getState().modelsListSlice;

    if (modelsListState.loading === 'loading') {
        return;
    }

    if(modelsListState.values.length > 0) {
        return;
    }

    dispatch(modelsListSlice.actions.handleLoading('loading'))

    const client = new AppListModelsClient();
    const response = await client.handleWithCredential()

    dispatch(modelsListSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(modelsListSlice.actions.handleItems(response.data.values));
}
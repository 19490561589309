import {StorageKey} from "./core/StorageKey";
import {LocalStorage} from "./LocalStorage";

type Data = {
    value: number
}

export class CreditCountStorage extends LocalStorage<Data> {
    protected version: number = 1;
    protected key: StorageKey = StorageKey.creditCount;
}
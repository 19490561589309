import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {RequiredTopic} from "../../../model/Topic";

type TopicItem = RequiredTopic

export interface TopicsSliceState {
    loading: LoadingState;
    lists: { model: string, topics: TopicItem[] }[]
    selectable: boolean
}

const initialState: TopicsSliceState = {
    loading: 'idle',
    lists: [],
    selectable: false,
}

export const topicsListsSlice = createSlice({
    name: 'topicsLists',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<TopicsSliceState['lists'][number]>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                lists.push({
                    model: action.payload.model,
                    topics: action.payload.topics,
                })
                return
            }

            lists[listIndex].topics = action.payload.topics;
        },
        handleLoading: (state, action: PayloadAction<TopicsSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleSelectable: (state, action: PayloadAction<TopicsSliceState['selectable']>) => {
            state.selectable = action.payload
        },
        handleSelectItem: (state, action: PayloadAction<{
            topic: TopicItem['id'],
            model: TopicItem['model']
        }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            const topicIndex = lists[listIndex].topics.findIndex(topic => topic.id === action.payload.topic)
            if (topicIndex < 0) {
                return;
            }
            lists[listIndex].topics[topicIndex].selected = !lists[listIndex].topics[topicIndex].selected;
        },
        handleRemoveItem: (state, action: PayloadAction<{
            id: TopicItem['id'],
            relevant:boolean
            model: TopicItem['model']
        }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            lists[listIndex].topics = lists[listIndex].topics.filter(topic => topic.id !== action.payload.id)
        },
        handleRemoveItems(state, action: PayloadAction<{ model: TopicItem['model'] }>) {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            lists[listIndex].topics = lists[listIndex].topics.filter(topic => !topic.selected);
        },
        handleUnselectAll: (state, action: PayloadAction<{ model: TopicItem['model'] }>) => {
            const lists = state.lists;
            const listIndex = lists.findIndex(list => list.model === action.payload.model);

            if (listIndex < 0) {
                return
            }

            lists[listIndex].topics = lists[listIndex].topics.map(topic => {
                topic.selected = false;
                return topic
            });
        },
        reset: () => initialState
    },
})
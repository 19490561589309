import {Listable} from "./Listable";
import {ListableItem} from "./ListableItem";

export abstract class ListsHelper {

    static updateList<Item extends ListableItem>({lists, list}: {
        lists: Listable<Item>[],
        list: Listable<Item>,
    }) {
        const lists_ = [...lists]
        const listIndex = lists_.findIndex(l => l.id === list.id);

        if (listIndex < 0) {
            lists_.push({
                id: list.id,
                values: list.values,
            })
            return lists_;
        }

        lists_[listIndex].values = list.values
        return lists_;
    }

    static addItem<Item extends ListableItem>({lists, id, item, options}: {
        lists: Listable<Item>[],
        id: Listable<Item>['id'],
        item: Listable<Item>['values'][number],
        options?: { appendStart?: boolean }
    }) {
        const lists_ = lists
        const listIndex = lists_.findIndex(l => l.id === id);

        if (listIndex < 0) {
            lists_.push({
                id: id,
                values: [item]
            })
        } else {
            const currentList = lists_[listIndex].values
            lists_[listIndex].values = options?.appendStart === true
                ? [item, ...currentList]
                : [...currentList, item]
        }

        return lists_;
    }

    static removeItem<Item extends ListableItem>({lists, id, item}: {
        lists: Listable<Item>[],
        id: Listable<Item>['id'],
        item: Listable<Item>['values'][number],
    }) {
        const lists_ = lists
        const listIndex = lists_.findIndex(l => l.id === id);

        if(listIndex < 0) {
            return lists_
        }

        lists_[listIndex].values = lists_[listIndex].values.filter(l => l.id !== item.id)
        return lists_;
    }

    static updateListableItemToList<Item extends ListableItem>({lists, id, item}: {
        lists: Listable<Item>[],
        id: Listable<Item>['id'],
        item: Listable<Item>['values'][number],
    }) {
        const lists_ = lists
        const listIndex = lists_.findIndex(l => l.id === id);

        if (listIndex < 0) {
            return;
        }

        const valueIndex = lists_[listIndex].values.findIndex(lItem => lItem.id === item.id)

        if (valueIndex < 0) {
            return;
        }

        lists_[listIndex].values[valueIndex] = item;
        return lists_;
    }


    static getList<Item extends ListableItem>({lists, id}: {
        lists: Listable<Item>[],
        id: Listable<Item>['id'],
    }) {
        return lists.find(list => list.id === id);
    }

}
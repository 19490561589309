import {AppClient} from "../AppClient";

type Input = {
    verificationId: string;
    device: string;
}

type OutputData = {
    value:  {
        isVerified: boolean;
        access_token: string;
    };
}

export class AppUpdateVerificationClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('verification'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import React, {useEffect} from 'react';
import {useAppModel} from "../../../../hook/useAppModel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Divider} from "../../../../component/divider/Divider";
import {getSeedsThunk} from "../../../../redux/thunk/seed/get-seeds-thunk";
import {SeedListItem} from "./SeedListItem";
import {OptionAction} from "../../../../component/option-action/OptionAction";
import {removeSeedsThunk} from "../../../../redux/thunk/seed/remove-seeds-thunk";
import {SeedListLoader} from "./SeedListLoader";
import {ListView} from "../../../../component/list-view/ListView";

type SeedsListProps = {}

export const SeedsList = (props: SeedsListProps) => {
    const model = useAppModel()
    const lists = useAppSelector(state => state.seedsListsSlice.values)
    const list = lists.find(list => list.id === model.id)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const model_ = model.id
        if (!model_) return;
        dispatch(getSeedsThunk)
    }, [dispatch, model]);


    return <div className={'pr-4 relative'}>
        {list?.values && list.values.length > 0 && <OptionAction onDelete={() => dispatch(removeSeedsThunk)}/>}

        <Divider direction={'x'} size={"xs"}/>

        <ListView>
            {list?.values.map((seed, i) => <div key={seed.id}>
                <SeedListItem key={list.id} seed={seed}/>
                {i < list?.values.length && <Divider direction={'x'} size={'xs'}/>}
            </div>)}
        </ListView>

        <SeedListLoader />
    </div>
};
import {AppClient} from "../AppClient";
import {FurtherInformationListItem} from "../../../../model/FurtherInformation";


type InputData  = {
    model: string;
    values: string[]
}

type OutputData = {
    values: FurtherInformationListItem[]
};

export class AppAddFurthersInformationClient extends AppClient<InputData, OutputData> {
    async request(input: InputData) {
        return await fetch(this.buildEndpoint('further-information/add-multiple'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(input)
        })
    }
}


import React, {useEffect} from 'react';
import {
    CnComponent,
    CnSelectContent,
    CnSelectGroup,
    CnSelectItem,
    CnSelectLabel,
    CnSelectTrigger,
    CnSelectValue,
} from './Component'

export type SelectProps = {
    label: string;
    placeholder: string;
    items: { value: string; label: string; }[]
    onChange: (value: string) => void;
    defaultValue?: string;
    disabled?: boolean
}

export const Select = ({onChange, defaultValue, disabled, items, label, placeholder}: SelectProps) => {

    useEffect(() => {
        if (defaultValue) {
            onChange(defaultValue);
        }
    }, [onChange, defaultValue]);

    return <CnComponent defaultValue={defaultValue} onValueChange={onChange}>
        <CnSelectTrigger disabled={disabled} className="w-full">
            <CnSelectValue placeholder={placeholder}/>
        </CnSelectTrigger>
        <CnSelectContent>
            <CnSelectGroup>
                <CnSelectLabel>{label}</CnSelectLabel>
                {items.map((item, index) => <CnSelectItem
                    key={index}
                    value={item.value}
                >{item.label}
                </CnSelectItem>)}
            </CnSelectGroup>
        </CnSelectContent>
    </CnComponent>
};
import {IValidator} from "./IValidator";

export class PasswordValidator extends IValidator<string> {
    isValid(value: string)  : boolean {
        if(value.trim().length === 0) {
            this.message = "Valeur requise";
            return false;
        }
        if(value.length < 3) {
            this.message = 'Mot de passe trop court';
            return false;
        }

        /**
         * if(value.trim().length === 0) {
         *             this.message = "Valeur requise";
         *             return false;
         *         }
         *         if(value.length < 8) {
         *             this.message = 'Doit contenir au moins 8 caractères';
         *             return false;
         *         }
         *
         *         if(value.length > 50) {
         *             this.message = 'Doit contenir au maximum 50 caractères';
         *             return false;
         *         }
         *
         *         if(!value.match(/\d/)) {
         *             this.message = "Doit contenir au moins 1 chiffre"
         *             return false
         *         }
         *
         *         if(!value.match(/[A-Z]/)) {
         *             this.message = "Doit contenir au moins 1 lettre majuscule"
         *             return false
         *         }
         *
         *         if(!value.match(/[a-z]/)) {
         *             this.message = "Doit contenir au moins 1 lettre minuscule"
         *             return false
         *         }
         *
         *         if(!value.match(/\W/)) {
         *             this.message = "Doit contenir au moins 1 caractère spéciale"
         *             return false
         *         }
         *
         *         if(value.match(/\s/)) {
         *             this.message = "Ne doit pas conteni d'espace"
         *             return false
         *         }
         */
        return true
    }
}
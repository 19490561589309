import {IValidator} from "./IValidator";

export class FeedbackValidator extends IValidator<string> {
    isValid(value: string): boolean {
        if (value.trim().length === 0) {
            this.message = 'Valeur requise'
            return false;
        }

        const max = 5_000;
        if (value.length > max) {
            this.message = `Max atteint ${value.length} / ${max}`
            return false;
        }

        return true
    }
}
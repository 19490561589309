import React from 'react';
import ReactDOM from 'react-dom/client';
import 'asset/style/index.css';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {RouterProvider} from "react-router-dom";
import {router} from "./route/router";
import {Global} from "./component/global/Global";
import {NegativeCredit} from "./component/negative-credit/NegativeCredit";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Global/>
            <RouterProvider router={router}/>
            <NegativeCredit />
        </Provider>
    </React.StrictMode>
);
import {AppClient} from "../AppClient";
import {GeneratedPost} from "../../../../model/Post";


type Input = {
    model: string
}
type OutputData = {
    value: GeneratedPost
};

export class AppGeneratePostClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('post'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {CountListItem} from "../helper/count-helper";

export interface FurtherInformationCountSliceState {
    values: CountListItem[]
}

const initialState: FurtherInformationCountSliceState = {
    values: []
}

export const furtherInformationCountSlice = createSlice({
    name: 'furtherInformationCount',
    initialState: initialState,
    reducers: {
        addItem: (state, action: PayloadAction<CountListItem>) => {
            state.values = ListHelper.addItem(state.values, action.payload)
        },
        handleItem: (state, action: PayloadAction<CountListItem>) => {
            state.values = ListHelper.handleItem(state.values, action.payload);
        },
        handleItemLoad: (state, action: PayloadAction<{
            model: string;
            loading: LoadingState;
        }>) => {
            const item = ListHelper.getItem(action.payload.model, state.values)

            if (!item) {
                return;
            }

            state.values = ListHelper.handleItem(state.values, {
                ...item,
                loading: action.payload.loading,
            });
        },

        handleItemValue: (state, action: PayloadAction<{
            model: string;
            count: CountListItem['count'];
        }>) => {
            const item = ListHelper.getItem(action.payload.model, state.values)

            if (!item) {
                return;
            }

            state.values = ListHelper.handleItem(state.values, {
                ...item,
                count: action.payload.count,
            });
        },
    },
})
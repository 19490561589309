import React from 'react';

type DividerProps = {
    direction: 'x' | 'y';
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const Divider = (props: DividerProps) => {
    let className = 'block ';
    className += props.direction === 'x' ? 'h' : 'w'
    className += '-'

    switch (props.size) {
        case 'xs':
            className = className.concat('2')
            break;
        case 'sm':
            className = className.concat('4')
            break;
        case 'md':
            className = className.concat('6')
            break;
        case 'lg':
            className = className.concat('8')
            break;
        case 'xl':
            className = className.concat('10')
            break;
    }


    return <span className={className}></span>
};
import React from 'react';
import {PostsList} from "./component/PostsList";
import {Divider} from "../../../component/divider/Divider";
import {Alert} from "../../../component/alert/Alert";

type PostPageProps = {}

export const PostPage = (props: PostPageProps) => {
    return <div>
        <Alert
            type={'neutral'}
            title={'Contenu'}
            message={"Voici le contenu crée par vos différents assistants. Validez les afin de les aiguiller dans la bonne éxécution de leurs tâches"}
        />
        <Divider direction={'x'} size={'xs'}/>
        <PostsList/>
    </div>
};
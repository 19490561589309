import React from 'react';
import {Input} from "../../../../component/input/Input";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {UsernameValidator} from "../../../../validator/UsernameValidator";
import {usernameSlice} from "../../../../redux/feature/auth/usernameSlice";

type UsernameInputProps = {}

export const UsernameInput = (props: UsernameInputProps) => {
    const state = useAppSelector((state) => state.usernameSlice)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const dispatch = useAppDispatch()

    let errorMessage = null;
    const validator = new UsernameValidator()
    if (state.value !== null && !validator.isValid(state.value)) {
        errorMessage = validator.getMessage()
    }

    return <Input
        custom={{
            label: 'Username',
            errorMessage: errorMessage,
        }}
        base={{
            placeholder: 'John Doe',
            name: 'username',
            value: state.value ?? '',
            onChange: (e) => dispatch(usernameSlice.actions.update({
                value: e.target.value,
            })),
            autoComplete: 'name',
            disabled: loading,
        }}
    />
};
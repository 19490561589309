import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";
import {Listable} from "../../../helper/lists-helper/Listable";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {FurtherInformationListItem} from "../../../model/FurtherInformation";

type FurtherInformationList = Listable<FurtherInformationListItem>
type FurtherInformationLists = FurtherInformationList[]

export interface FurtherInformationSliceState {
    loading: LoadingState;
    values: FurtherInformationLists
}

const initialState: FurtherInformationSliceState = {
    loading: 'idle',
    values: [],
}

export const furthersInformationListsSlice = createSlice({
    name: 'furthersInformationLists',
    initialState: initialState,
    reducers: {
        handleItems: (state, action: PayloadAction<FurtherInformationList>) => {
            state.values = ListsHelper.updateList<FurtherInformationListItem>({
                lists: state.values,
                list: action.payload,
            });
        },
        addItem: (state, action: PayloadAction<{
            id: FurtherInformationList['id'],
            value: FurtherInformationList['values'][number],

        }>) => {
            state.values = ListsHelper.addItem<FurtherInformationListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
                options: {
                    appendStart:  true,
                }
            })
        },
        handleItem: (state, action: PayloadAction<{
            id: FurtherInformationList['id'],
            value: FurtherInformationList['values'][number],
        }>) => {

            const values = ListsHelper.updateListableItemToList<FurtherInformationListItem>({
                lists: state.values,
                id: action.payload.id,
                item: action.payload.value,
            })

            if (!values) {
                return
            }

            state.values = values;
        },
        handleLoading: (state, action: PayloadAction<FurtherInformationSliceState['loading']>) => {
            state.loading = action.payload
        },
        reset: () => initialState
    },
})
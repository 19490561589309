import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Button} from "../../../../component/button/Button";
import {Loader} from "../../../../component/loader/Loader";
import {ChildrenButton} from "../../auth/component/ChildrenButton";
import {LucideUpload} from "lucide-react";
import {updateModelThunk} from "../../../../redux/thunk/model/update-model-thunk";
import {Model} from "../../../../model/Model";
import {useNavigate} from "react-router-dom";
import {routerPath} from "../../../routerPath";
import {useAppCredit} from "../../../../hook/useAppCredit";
import {ModelNameValidator} from "../../../../validator/ModelNameValidator";
import {ModelThemeValidator} from "../../../../validator/ModelThemeValidator";
import {ModelSuffixValidator} from "../../../../validator/ModelSuffixValidator";

type SubmitModelUpdateProps = {
    defaultValue?: Required<Model>
}

export const SubmitModelUpdate = (props: SubmitModelUpdateProps) => {
    const model = useAppSelector(state => state.modelSlice);
    const credit = useAppCredit()
    const dispatch = useAppDispatch();

    let navigate = useNavigate();
    const isDisabled = hasError() || model.loading

    const handleSubmit = () => {

        if (credit.noCredit) {
            credit.alertInsufficentCredit(() => navigate(routerPath.credit.base))
            return;
        }

        if (isDisabled) {
            return;
        }

        dispatch((dispatch, getState) => updateModelThunk(dispatch, getState, {
            onSuccess: () => {
                navigate(routerPath.model.base)
            }
        }))
    }

    function hasError() {
        if(!model.name || !model.theme) {
            return true;
        }

        const nameValidator = new ModelNameValidator()
        const themeValidator = new ModelThemeValidator()
        const suffixValidator = new ModelSuffixValidator()

        return !nameValidator.isValid(model.name) ||  !themeValidator.isValid(model.name) || (model.suffix && !suffixValidator.isValid(model.suffix)) ;
    }

    return <div className={'center max-w-2xl mx-auto'}>
        <Button
            base={{
                onClick: handleSubmit,
                "aria-disabled": isDisabled,
                disabled: isDisabled
            }}
        >
            {model.loading ? <Loader/> : <ChildrenButton icon={LucideUpload} label={model.id ? 'Modifier' : 'Creer'}/>}
        </Button>
    </div>
};
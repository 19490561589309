import React from 'react';
import {Dialog} from "../dialog/Dialog";
import {FeedbackValidator} from "../../validator/FeedbackValidator";
import {InputMessage} from "../input-message/InputMessage";
import {Textarea} from "../textarea/Textarea";
import {Button} from "../button/Button";
import {Divider} from "../divider/Divider";
import {createFeedBackThunk} from "../../redux/thunk/feedback/create-feedback-thunk";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {feedbackSlice} from "../../redux/feature/feedback/feedbackSlice";
import {Loader} from "../loader/Loader";

type FeedbackButtonProps = {}

export const Feedback = (props: FeedbackButtonProps) => {
    const state = useAppSelector(state => state.feedbackSlice)
    const dispatch = useAppDispatch();

    let errorMessage = null;
    const validator = new FeedbackValidator()
    if (state.value !== null && !validator.isValid(state.value)) {
        errorMessage = validator.getMessage()
    }

    const loading = state.loader === "loading"
    const buttonDisabled = state.value === null || loading || errorMessage !== null

    return <div>
        <Dialog
            dialogCnProps={{
                onOpenChange: (open) => dispatch(feedbackSlice.actions.handleOpen(open)),
                open: state.open
            }}
            title={"Dis nous tout 🙂"}
            trigger={{
                label: "Donner un feedback ?", props: {
                    className: "text-blue-600 font-bold"
                }
            }}
        >
            <Textarea
                onChange={(e) => dispatch(feedbackSlice.actions.handleValue(e.currentTarget.value))}
                defaultValue={state.value || ''}
                disabled={loading}
            />

            {errorMessage !== null && <InputMessage>{errorMessage}</InputMessage>
            }

            <Divider direction={'x'} size={"xs"}/>
            <div>
                <Button
                    base={{
                        onClick: () => dispatch(createFeedBackThunk),
                        disabled: buttonDisabled,
                    }}
                    custom={{color: 'neutral'}}
                >
                    {loading ? <Loader/> : "Submit"}
                </Button>
            </div>
        </Dialog>
    </div>
};
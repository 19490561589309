import React from 'react';
import {AutosizeTextarea, AutosizeTextAreaProps} from './component'

type TextareaProps = AutosizeTextAreaProps

export const Textarea = (props: TextareaProps) => {
    return <AutosizeTextarea
        maxHeight={props.maxHeight || 400}
        {...props}
    />
};
import React from 'react';
import {Divider} from "../../../../component/divider/Divider";
import {Textarea} from "../../../../component/textarea/Textarea";
import {modelSlice} from "../../../../redux/feature/model/modelSlice";
import {CardContainer} from "./CardContainer";
import {CardContainerLabel} from "./CardContainerLabel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {InputMessage} from "../../../../component/input-message/InputMessage";
import {ModelSuffixValidator} from "../../../../validator/ModelSuffixValidator";

type SuffixProps = { defaultValue?: string }

export const Suffix = (props: SuffixProps) => {
    const dispatch = useAppDispatch()
    const modelSuffix = useAppSelector((state) => state.modelSlice.suffix)

    let errorMessage = null;
    const validator = new ModelSuffixValidator()
    if (modelSuffix !== null && !validator.isValid(modelSuffix)) {
        errorMessage = validator.getMessage()
    }

    return <CardContainer>
        <CardContainerLabel htmlFor={'theme'}>Ajouter du contenu à chaque fin de création ?</CardContainerLabel>
        <Divider direction={'x'} size={'sm'}/>

        <Textarea
            onChange={(e) => dispatch(modelSlice.actions.handleSuffix(e.target.value))}
            placeholder="Ne tardez plus à améliorer votre productivité sur anexdot.com"
            id={'theme'}
            value={modelSuffix || ''}
        />
        {errorMessage !== null && <InputMessage>{errorMessage}</InputMessage>}
    </CardContainer>
};
import React from 'react';
import {Button} from "../../../../component/button/Button";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {EmailValidator} from "../../../../validator/EmailValidator";
import {PasswordValidator} from "../../../../validator/PasswordValidator";
import {Loader} from "../../../../component/loader/Loader";
import {loginThunk} from "../../../../redux/thunk/auth/login-thunk";
import {ChildrenButton} from "../../auth/component/ChildrenButton";
import {useAppDetectDevice} from "../../../../hook/useAppDetectDevice";
import {useNavigate} from "react-router-dom";
import {LucideLogIn} from "lucide-react";
import {routerPath} from "../../../routerPath";

export const SubmitFormButton = () => {

    const email = useAppSelector((state) => state.userEmailSlice.value)
    const password = useAppSelector((state) => state.passwordSlice.value)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const device = useAppDetectDevice();

    const emailValidator = new EmailValidator();
    const passwordValidator = new PasswordValidator();

    const hasError = !emailValidator.isValid(email ?? '') ||
        !passwordValidator.isValid(password ?? '')

    function handleSubmit() {

        if (hasError) {
            return;
        }

        if (
            email === null ||
            password === null
        ) {
            return;
        }

        dispatch((dispatch, getState) => loginThunk(
                dispatch,
                getState,
                {
                    device: device.name,
                    onSuccess: () => navigate(routerPath.authentication.base)
                },
            )
        )
    }

    return <div className={'center'}>
        <Button
            base={{
                onClick: handleSubmit,
                "aria-disabled": hasError,
                disabled: hasError || loading,
            }}
            custom={{
                color: 'blue',
            }}
        >
            {loading ? <Loader/> : <ChildrenButton icon={LucideLogIn} label={'Login'}/>}
        </Button>
    </div>
};
import {AppClient} from "../AppClient";
import { Topic} from "../../../../model/Topic";


interface Input {
    model: string;
    topic: string;
}

type OutputData = {
    value : Required<Pick<Topic, 'id' |'value'|'createdAt'>>
};

export class AppAddOneTopicModelClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('topic'), {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(params)
        })
    }
}


import React from 'react';
import {CnRadioGroup, CnRadioGroupItem} from "./component"
import {CnLabel} from "../label/component";

export type RadioGroupItemType = { label: string; value: string }
type RadioGroupProps = {
    items: RadioGroupItemType[];
    onClick: (item: RadioGroupItemType) => void
    defaultValue?: string
    className?: string
}

export const RadioGroup = (props: RadioGroupProps) => {
    return <CnRadioGroup defaultValue={props.defaultValue} className={props.className}>
        {props.items.map((item, index) => <div key={index} className="flex items-center space-x-2">
            <CnRadioGroupItem
                className={'cursor-pointer'}
                checked={item.value=== props.defaultValue}
                onClick={() => props.onClick(item)} value={item.value}
                id={`${item.label}-${item.value}`}
            />
            <CnLabel className={'cursor-pointer'} htmlFor={`${item.label}-${item.value}`}>{item.label}</CnLabel>
        </div>)}
    </CnRadioGroup>
};
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingStateWithoutLoaded} from "../../../type/component/loading-state";
import {FeedbackSliceState} from "../feedback/feedbackSlice";


export interface AddSeedValueSliceState {
    loading: LoadingStateWithoutLoaded;
    value: string|null;
    open: boolean
}

const initialState: AddSeedValueSliceState = {
    loading: 'idle',
    value: null,
    open: false,
}

export const addSeedValueSlice = createSlice({
    name: 'addSeedValue',
    initialState: initialState,
    reducers: {
        handleLoading: (state, action: PayloadAction<AddSeedValueSliceState['loading']>) => {
            state.loading = action.payload
        },
        handleValue: (state, action: PayloadAction<AddSeedValueSliceState['value']>) => {
            state.value = action.payload
        },
        handleOpen: (state, action: PayloadAction<FeedbackSliceState['open']>) => {
            state.open = action.payload
        },
        reset: () => initialState
    },
})
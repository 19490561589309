import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {AppCreateCheckoutSessionClient} from "../../../http/api/app/checkout-session/AppCreateCheckoutSessionClient";
import {ToastHelper} from "../../../helper/ToastHelper";


type  CreateCheckoutSessionThunkParams  = {
    quantity: number,
    productId : string,
    onError: () => void
    onSuccess: (url:string) =>  void
}

export const createCheckoutSessionThunk = async (
    dispatch: AppDispatch,
    _: () => RootState,
    api : CreateCheckoutSessionThunkParams,
) => {
    const client = new AppCreateCheckoutSessionClient();
    const response = await client.handleWithCredential({
        productId : api.productId,
        type: 'credit',
        quantity: api.quantity,
    })

    dispatch(creditCountSlice.actions.handleLoading("loading"))

    if (isAppErrorResponse(response)) {
        let message: string = `Une erreur est survenue - ${response.code}`;

        ToastHelper.add({
            content: message,
            type: 'warning',
        })

        api.onError()
        return;
    }

    api.onSuccess(response.data.checkoutUrl);
}
import {AppClient} from "../AppClient";


type Input = {
    model: string
    topics: string[]
}

type OutputData = {
    count: number
};

export class AppRemoveTopicsClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`topic`), {
            method: 'DELETE',
            headers: this.headers,
            body : JSON.stringify(params),
        })
    }
}


import React, {PropsWithChildren} from "react";
import {NativeProps} from "../../type/component/native-props";
import {Colors} from "../../type/component/Colors";

type Custom = { color?: typeof Colors[number] }
type ButtonProps = NativeProps<Custom, React.ButtonHTMLAttributes<HTMLButtonElement>> & PropsWithChildren

export const Button = (props: ButtonProps) => {
    const isDisabled = props.base?.disabled === true;
    const color = props.custom?.color || 'blue'

    const disabledClassName = isDisabled ? 'bg-transparent cursor-not-allowed text-gray-400' : ``
    let value = ''
    if (!isDisabled) {
        value += ' cursor-pointer '
        value = color === "neutral" ? 'bg-white text-gray-900 hover:bg-black hover:text-white' : `text-white bg-${color}-600 hover:bg-${color}-500`
    }

    return <button
        {...props.base}
        className={`inline-flex items-center justify-center p-2 text-base font-medium text-center transition-colors border-1 border-gray-300 ${disabledClassName} rounded-lg focus:ring-2 focus:ring-blue-300 w-full ${value} `}
    >
        {props.children}
    </button>
}
import React from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {EqualValidator} from "../../../../validator/EqualValidator";
import {passwordConfirmSlice} from "../../../../redux/feature/auth/passwordConfirmSlice";
import {Input} from "../../../../component/input/Input";

type ConfirmPasswordProps = {}

export const PasswordConfirm = (props: ConfirmPasswordProps) => {
    const passwordState = useAppSelector((state) => state.passwordSlice)
    const confirmPasswordState = useAppSelector((state) => state.passwordConfirmSlice)
    const loading = useAppSelector((state) => state.loadingSlice.value)

    const dispatch = useAppDispatch()

    const validator = new EqualValidator()

    let errorMessage = null;

    if (
        passwordState.value !== null &&
        confirmPasswordState.value !== null &&
        !validator.isValid({
            valueA: passwordState.value,
            valueB: confirmPasswordState.value
        })
    ) {
        errorMessage = validator.getMessage()
    }

    return <div>
        <Input
            custom={{
                label: 'Confirmer le mot de passe',
                errorMessage: errorMessage,
            }}
            base={{
                onChange: (e) => dispatch(passwordConfirmSlice.actions.update({
                    value: e.target.value,
                })),
                placeholder: 'Tapez votre mot de passe',
                type: 'password',
                value: confirmPasswordState.value ?? '',
                autoComplete: 'new-password',
                disabled: loading,
            }}
        />
    </div>
};
import {AppDispatch, RootState} from "../../store";
import {loadingSlice} from "../../feature/auth/loadingSlice";
import {AppRegisterClient} from "../../../http/api/app/auth/AppRegisterClient";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ToastHelper} from "../../../helper/ToastHelper";
import {usernameSlice} from "../../feature/auth/usernameSlice";
import {passwordSlice} from "../../feature/auth/passwordSlice";
import {passwordConfirmSlice} from "../../feature/auth/passwordConfirmSlice";

type RegisterParams = {
    device: string;
    onSuccess : () => void;
}

export const registerThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {device, onSuccess}: RegisterParams,
) => {
    dispatch(loadingSlice.actions.active())

    const email = getState().userEmailSlice.value
    const username = getState().usernameSlice.value
    const password = getState().passwordSlice.value
    const confirmPassword = getState().passwordConfirmSlice.value

    if (
        email === null ||
        username === null ||
        password === null ||
        confirmPassword === null
    ) {
        return;
    }

    const client = new AppRegisterClient();

    const response = await client.handle({
        email, username, password, device, confirmPassword
    })

    dispatch(loadingSlice.actions.inactive())

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        if (response.code === AppCodeException.ACCOUNT_ALREADY_EXIST) {
            message = 'Cet email est déjà associé à un compte'
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    ToastHelper.add({
        content: 'Un email de confirmation vous a été envoyé à l\'adresse indiquée' ,
        type: 'success',
    })

    dispatch(usernameSlice.actions.reset())
    dispatch(passwordSlice.actions.reset())
    dispatch(passwordConfirmSlice.actions.reset())
    dispatch(loadingSlice.actions.reset())
}

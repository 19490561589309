import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {listModelsThunk} from "../../../redux/thunk/model/list-models-thunk";
import {Select} from "../../../component/select/Select";
import {Divider} from "../../../component/divider/Divider";
import {activeModelSlice} from "../../../redux/feature/model/activeModelSlice";
import {useAppModel} from "../../../hook/useAppModel";
import {useLocation} from "react-router";
import {routerPath} from "../../routerPath";

type SelectModelProps = {}

export const SelectModel = (props: SelectModelProps) => {
    const model = useAppModel()
    const modelsLoaderState = useAppSelector(state => state.modelsListSlice.loading)

    const seedsLoaderState = useAppSelector(state => state.seedsListsSlice.loading)
    const approachesLoaderState = useAppSelector(state => state.approachesListsSlice.loading)
    const postsLoaderState = useAppSelector(state => state.postsListsSlice.loading);
    const topicLoaderState = useAppSelector(state => state.topicsListsSlice.loading);
    const manualTopicState = useAppSelector(state => state.manualTopicSlice)
    const optionAction = useAppSelector(state => state.optionActionSlice)

    const isDisabled = manualTopicState.loading === 'loading' ||
        topicLoaderState === "loading" ||
        optionAction.loading === 'loading' ||
        seedsLoaderState === 'loading' ||
        postsLoaderState === 'loading' ||
        approachesLoaderState === 'loading' ||
        model.noModels
    ;

    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modelsLoaderState !== 'idle') {
            return;
        }

        dispatch(listModelsThunk)
    }, [dispatch, model, modelsLoaderState]);

    const isHidden = ![
        routerPath.topic.base,
        routerPath.post.base,
        routerPath.seed.base,
        routerPath.approach.base,
    ].includes(location.pathname) || modelsLoaderState !== 'loaded'

    if (isHidden) {
        return <></>
    }

    return <>
        <Select
            disabled={isDisabled}
            defaultValue={model.id || undefined}
            onChange={(value) => dispatch(activeModelSlice.actions.handleModel(model.models.find(model => model.id === value)?.id || null))}
            label={'Quel assistant est concerné ?'}
            items={model.models.map(model => ({
                label: model.name,
                value: model.id,
            }))}
            placeholder={'Choisir un assistant'}/>
        <Divider direction={'x'} size={'xs'}/>
    </>
};
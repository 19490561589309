import {IValidator} from "./IValidator";

export class PostContentValidator extends IValidator<string> {
    isValid(value: string) {
        if (value.trim().length === 0) {
            this.message = 'Valeur requise';
            return false;
        }

        const max  = 10_000
        if (value.length > max) {
            this.message = `Nombre de caractères maximum atteint (${max})`;
            return false;
        }

        return true;
    }
}
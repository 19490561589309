import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";

export interface CreditCountSliceState {
    loading: LoadingState;
    value: number|null;
}

const initialState: CreditCountSliceState = {
    loading: 'idle',
    value: null,
}

export const creditCountSlice = createSlice({
    name: 'creditCount',
    initialState: initialState,
    reducers: {
        handleValue: (state, action: PayloadAction<CreditCountSliceState['value']>) => {
            state.value = action.payload;
        },
        handleLoading: (state, action: PayloadAction<CreditCountSliceState['loading']>) => {
            state.loading = action.payload
        },

    },
})
import React from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {TokenStorage} from "../../../../service/storage/TokenStorage";
import {logoutThunk} from "../../../../redux/thunk/auth/logout-thunk";
import {Loader} from "../../../../component/loader/Loader";
import {ChildrenButton} from "../../auth/component/ChildrenButton";
import {LucideLogIn} from "lucide-react";
import {routerPath} from "../../../routerPath";
import {NavigationBarItem} from "../../../../component/menu-side-bar/Component";
import {Button} from "../../../../component/button/Button";
import {Divider} from "../../../../component/divider/Divider";

type LogoutProps = {}

export const Logout = (props: LogoutProps) => {
    const loading = useAppSelector((state) => state.loadingSlice.value)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function logout() {
        if(loading) {
            return;
        }
        const tokenStorage = new TokenStorage();
        tokenStorage.removeData();
        dispatch((dispatch, state) => logoutThunk(dispatch, state, {
            onSuccess: () => navigate(routerPath.authentication.login),
        }))
    }

    return <div className={'pb-4'} onClick={logout} >
        <Button base={{disabled: loading}} custom={{color: 'neutral'}}>
            <span><LucideLogIn size={15} /></span>
            <span className={'hidden md:inline-block'}>
                <Divider direction={'y'} size={'sm'} />
            </span>
            <span className={'hidden md:inline-block'}>Deconnexion</span>
        </Button>
    </div>
};
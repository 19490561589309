import React, {useState} from 'react';
import {Input} from "../../../../component/input/Input";
import {Divider} from "../../../../component/divider/Divider";
import {Button} from "../../../../component/button/Button";
import {useAppDispatch} from "../../../../redux/store";
import {createCheckoutSessionThunk} from "../../../../redux/thunk/checkout/create-checkout-session-thunk";
import {CreditCountValidator} from "../../../../validator/CreditCountValidator";
import {CreditCost} from "../../../../model/CreditCost";

type CreditInputProps = {}

export const CreditInput = (props: CreditInputProps) => {
    const basePrice = 2.5;
    const [value, setValue] = useState<number>(CreditCost.TOTAL_COST_FOR_TRIAL)
    const [loading, setLaoding] = useState(false)
    const dispatch = useAppDispatch()

    let errorMessage = null;
    const validator = new CreditCountValidator()
    if (!validator.isValid(value || 0)) {
        errorMessage = validator.getMessage()
    }

    const onChange = (value: string) => {
        const v = parseInt(value.trim())

        if(value.trim().length === 0) {
            setValue(0)
            return;
        }

        if (isNaN(v)) {
            return
        }

        const maxItems = 99999;
        if (v > maxItems) {
            setValue(maxItems)
            return;
        }
        setValue(v)
    }

    const onSubmit = () => {
        setLaoding(true);
        dispatch((dispatch, getState) => createCheckoutSessionThunk(dispatch, getState, {
            productId: String(process.env.REACT_APP_CREDIT_PRODUCT_ID),
            quantity: value,
            onError: () => {
                setLaoding(false);
            },
            onSuccess: (data) => window.location.href = data
        }))
    }

    return <div className={'w-full max-w-96 border-1 p-3 rounded-md'}>
        <Input
            custom={{
                label : `Ajouter des credits €(${value * basePrice})`,
                errorMessage: errorMessage,
            }}
            base={{
                name:'add-credit',
                type: 'string',
                step: 2,
                value: value,
                onChange: (e) => onChange(e.target.value),
                style: {
                    paddingRight: '1.25em'
                },
                disabled: loading,
            }}

        />
        <Divider direction={'x'} size={'xs'}/>
        <Button base={{
            onClick: onSubmit,
            disabled: loading || errorMessage !== null,
        }}>Ajouter</Button>
    </div>
};
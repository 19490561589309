import React from 'react';
import {SelectModel} from "../../route/page/component/SelectModel";
import {AddMorePosts} from "../../route/page/post/component/AddMorePosts";
import {Divider} from "../divider/Divider";
import {AddMoreTopics} from "../../route/page/topics/component/AddMoreTopics";
import {useLocation} from "react-router";
import {routerPath} from "../../route/routerPath";
import {AddMoreApproaches} from "../../route/page/approach/component/AddMoreApproaches";
import {Feedback} from "./Feedback";
import {CreditCount} from "../../route/page/update-model/component/CreditCount";
import {CardContainer} from "../../route/page/update-model/component/CardContainer";

export const TopBar = () => {
    const location = useLocation();

    return <div>
        <div className={'flex justify-end items-center'}>
            <Feedback/>
            <Divider direction={"y"} size={'xs'}/>
            <CardContainer className={'px-4 py-2 max-w-max'}>
                <div className={'flex'}><span className={"inline-block mr-2"}>Crédits : </span><CreditCount/></div>
            </CardContainer>
        </div>
        <Divider direction={"x"} size={'xs'}/>
        <div className="w-full flex">
            <div className={'w-full'}>
                <SelectModel/>
            </div>
            {[routerPath.topic.base, routerPath.post.base, routerPath.approach.base].includes(location.pathname) &&
                <Divider direction={'y'} size={'xs'}/>}
            <div>
                <AddMorePosts/>
                <AddMoreTopics/>
                <AddMoreApproaches/>
            </div>
        </div>
    </div>
};
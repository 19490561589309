import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {Loader} from "../../../component/loader/Loader";
import {Divider} from "../../../component/divider/Divider";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {updateVerificationThunk} from "../../../redux/thunk/verification/update-verification-thunk";
import {useAppDetectDevice} from "../../../hook/useAppDetectDevice";
import {useNavigate} from "react-router-dom";
import {routerPath} from "../../routerPath";

type VerificationPageProps = {}

export const VerificationPage = (props: VerificationPageProps) => {
    const {account} = useParams()
    const {name} = useAppDetectDevice()
    const navigate = useNavigate();

    const verificationState = useAppSelector(state => state.verificationSlice);
    const dispatch  = useAppDispatch()

    useEffect(() => {
        if(!account || !name || verificationState.loading === "loading") {
            return
        }
        dispatch((dispatch, getState) => updateVerificationThunk(dispatch, getState, {
            verificationId: account,
            device: name,
            onSuccess : () => navigate(routerPath.model.create),
            onError: () => navigate(routerPath.authentication.register)
        }))
    }, [account, dispatch, name]);

    return <div className={'fixed center inset-0'}>
        <div className={'center flex-col'}>
            <span className={`${verificationState.loading === "loading" ? 'animate-pulse' : ""} text-blue-600`}>Verification en cours</span>
            <Divider direction={'x'} size={'xs'} />
            {verificationState.loading === "loading" && <Loader/>}
        </div>
    </div>
};
import {AppClient} from "../AppClient";
import {Topic} from "../../../../model/Topic";


type Input = {
    model: string
}
type OutputData = {
    values: Required<Pick<Topic, 'id'| 'value'| 'createdAt'| 'relevant'>>[]
};

export class AppListTopicsModelClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint(`topic?model=${params.model}`), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


import React from 'react';
import {modelsListSlice} from "../../../../redux/feature/model/modelsListSlice";
import {Card} from "../../../../component/card/Card";
import {Divider} from "../../../../component/divider/Divider";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {ListItemModel} from "../../../../model/Model";
import {useAppDispatch} from "../../../../redux/store";
import {LucideEye, LucidePen} from "lucide-react";
import {Link} from "react-router-dom";
import {routerPath} from "../../../routerPath";

type ModelListItemProps = {
    model: ListItemModel
}

export const ModelListItem = ({model}: ModelListItemProps) => {
    const dispatch = useAppDispatch()
    return <div key={model.id}>
        <div className={'w-full'}>
            <Card
                base={{
                    className: 'w-full flex items-center'
                }}
            >
                <Divider direction={'y'} size={'xs'}/>
                <Paragraph
                    className={'m-0 truncate text-wrap w-full'}>{model.name}</Paragraph>

                <div className={"flex"}>
                    <div className={'flex center'}>
                        <Link to={routerPath.model.createUpdateRoute(model.id)}>
                            <LucidePen  className={"text-gray-700 hover:text-gray-500"} size={24}/>
                        </Link>
                    </div>
                    <Divider direction={'y'} size={"sm"} />
                    <div className={'flex center'}>
                        <div onClick={() => dispatch(modelsListSlice.actions.handleDetail(model))}>
                            <LucideEye className={"text-gray-700 cursor-pointer hover:text-gray-500"} size={24}/>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
        <Divider direction={'x'} size={'xs'}/>
    </div>
};
import {IValidator} from "./IValidator";

export class TopicValidator extends IValidator<string> {
    isValid(value: string)  : boolean {
        if(value.trim().length  === 0) {
            this.message = 'Valeur requise';
            return false;
        }

        const maxLength = 200;
        if(value.length > 200) {
            this.message = `La valeur maximale est de ${maxLength} caractères`
            return false;
        }

        return true
    }
}
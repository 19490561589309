import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LoadingState} from "../../../type/component/loading-state";

export interface ModelCountSliceState {
    loading: LoadingState;
    value: number|null;
}

const initialState: ModelCountSliceState = {
    loading: 'idle',
    value: null,
}

export const modelCountSlice = createSlice({
    name: 'modelCount',
    initialState: initialState,
    reducers: {
        handleValue: (state, action: PayloadAction<ModelCountSliceState['value']>) => {
            state.value = action.payload;
        },
        handleLoading: (state, action: PayloadAction<ModelCountSliceState['loading']>) => {
            state.loading = action.payload
        },

    },
})
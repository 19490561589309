import {useAppSelector} from "../redux/store";

export const useAppModel = () => {
    const model = useAppSelector(state => state.activeModelSlice.id)
    const models = useAppSelector(state => state.modelsListSlice.values)
    const modelsLoaderState = useAppSelector(state => state.modelsListSlice.loading)
    const currentModel = models.find((m) => m.id === model)

    return {
        id: model,
        entity : currentModel || null,
        hasModels : modelsLoaderState === "loaded" && models.length > 0,
        noModels : modelsLoaderState === "loaded" && models.length === 0 ,
        isLoading: modelsLoaderState === "loading",
        isLoaded: modelsLoaderState === "loaded",
        models : models,
    }
};
import {useAppSelector} from "../redux/store";
import {ToastHelper} from "../helper/ToastHelper";

export const useAppCredit = () => {
    const credit = useAppSelector(state => state.creditCountSlice)

    function alertInsufficentCredit(onClick: () => void) {
        ToastHelper.add({
            type: 'warning',
            content: 'Crédits insuffisants',
            action: {
                label: 'Ajouter',
                onClick: onClick
            }
        })
    }

    return {
        hasCredit: credit.loading === "loaded" && credit.value !== null && credit.value > 0,
        noCredit: credit.loading === "loaded" && credit.value !== null && credit.value === 0,
        hasNegativeCredit: credit.loading === "loaded" && credit.value !== null && credit.value < 0,
        value: credit.value,
        isLoaded: credit.loading === "loaded",
        isLoading: credit.loading === "loading",
        alertInsufficentCredit,
    }
};
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {AppGetPostsClient} from "../../../http/api/app/post/AppGetPostsClient";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {ToastHelper} from "../../../helper/ToastHelper";

export const getPostsByModelThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
    api: { model: string }
) => {
    const postsListsState = getState().postsListsSlice;
    const posts = postsListsState.lists.find(list => list.model === api.model)?.posts;

    if (posts) {
        return;
    }

    if (postsListsState.loading === 'loading') {
        return;
    }

    dispatch(postsListsSlice.actions.handleLoading('loading'))

    const client = new AppGetPostsClient();
    const response = await client.handleWithCredential({
        model: api.model,
    })

    dispatch(postsListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(postsListsSlice.actions.handleItems({
        model: api.model,
        posts: response.data.values.map(d => ({
            ...d,
            selected: false,
        }))
    }))
}


import {AppDispatch, RootState} from "../../store";
import {loadingSlice} from "../../feature/auth/loadingSlice";
import {TokenStorage} from "../../../service/storage/TokenStorage";
import {ToastHelper} from "../../../helper/ToastHelper";
import {topicsListsSlice} from "../../feature/topic/topicsListsSlice";
import {postsListsSlice} from "../../feature/post/postsListsSlice";
import {seedsListsSlice} from "../../feature/seed/seedsListsSlice";
import {emailSlice} from "../../feature/auth/emailSlice";
import {usernameSlice} from "../../feature/auth/usernameSlice";
import {passwordSlice} from "../../feature/auth/passwordSlice";
import {passwordConfirmSlice} from "../../feature/auth/passwordConfirmSlice";
import {approachesListsSlice} from "../../feature/approach/approachesListsSlice";
import {modelsListSlice} from "../../feature/model/modelsListSlice";
import {modelSlice} from "../../feature/model/modelSlice";
import {activeModelSlice} from "../../feature/model/activeModelSlice";

type LogoutParams = {
    onSuccess: () => void
}

export const logoutThunk = async (
    dispatch: AppDispatch,
    _: () => RootState,
    {onSuccess}: LogoutParams
) => {
    dispatch(loadingSlice.actions.active())

    //TODO revoke token
    await new Promise(resolve => setTimeout(resolve, 500));
    new TokenStorage().removeData();

    ToastHelper.add({
        content: 'Deconnecté',
        type: 'success',
    })

    dispatch(loadingSlice.actions.reset())
    dispatch(topicsListsSlice.actions.reset())
    dispatch(postsListsSlice.actions.reset())
    dispatch(seedsListsSlice.actions.reset())
    dispatch(approachesListsSlice.actions.reset())
    dispatch(modelsListSlice.actions.reset())
    dispatch(modelSlice.actions.reset())
    dispatch(activeModelSlice.actions.reset())


    dispatch(emailSlice.actions.reset())
    dispatch(usernameSlice.actions.reset())
    dispatch(passwordSlice.actions.reset())
    dispatch(passwordConfirmSlice.actions.reset())

    onSuccess()
}
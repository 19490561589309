import React, {useEffect} from 'react';
import {useAppModel} from "../../../../hook/useAppModel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {Divider} from "../../../../component/divider/Divider";
import {OptionAction} from "../../../../component/option-action/OptionAction";
import {removeApproachesThunk} from "../../../../redux/thunk/approach/remove-approaches-thunk";
import {ApproachListItem} from "./ApproachListItem";
import {ApproachListLoader} from "./ApproachListLoader";
import {getApproachesThunk} from "../../../../redux/thunk/approach/get-approaches-thunk";
import {ListView} from "../../../../component/list-view/ListView";

export const ApproachesList = () => {
    const model = useAppModel()
    const lists = useAppSelector(state => state.approachesListsSlice.values)
    const list = lists.find(list => list.id === model.id)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const model_ = model.id
        if (!model_) return;
        dispatch(getApproachesThunk)
    }, [dispatch, model]);


    return <div className={'pr-4 relative'}>
        {list?.values && list.values.length > 0 && <OptionAction onDelete={() => dispatch(removeApproachesThunk)}/>}

        <Divider direction={'x'} size={"xs"}/>

        <ListView>
            {list?.values.map((approach, i) => <div key={approach.id}>
                <ApproachListItem key={list.id} approach={approach}/>
                {i < list?.values.length && <Divider direction={'x'} size={'xs'}/>}
            </div>)}
        </ListView>

        <ApproachListLoader/>
    </div>
};
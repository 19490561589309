import {AppClient} from "../AppClient";

type OutputData = {
    count: number;
};

export class AppGetCreditCountClient extends AppClient<undefined, OutputData> {

    async request() {
        return await fetch(this.buildEndpoint('credit/count'), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


import React from 'react';
import {NavigationBarItem} from "./Component";
import {Card} from "../card/component";
import {Logout} from "../../route/page/update-model/component/Logout";
import {Divider} from "../divider/Divider";
import {routerPath} from "../../route/routerPath";
import {useLocation} from "react-router";
import logo from "../../asset/media/logo.png"

import {
    Lightbulb,
    MessageCircle,
    Signpost, SmartphoneCharging,
    Sparkles,
    UserRoundPlus,
    Users,
} from "lucide-react";

type NavigationBarProps = {}

export const MenuSideBar = (props: NavigationBarProps) => {
    const location = useLocation();

    return <Card
        className={'max-w-max min-h-min m-4 bg-white w-full rounded-md border-300 flex flex-col justify-between px-2 border-1'}>
        <div>

            <div className={'w-12 h-12 flex items-center'}>
                <img className={'w-12 h-12'} src={logo} alt="applicaiton logo"/>
                <span className={'hidden md:block'}>Anexdot</span>
            </div>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={UserRoundPlus}
                href={routerPath.model.create}
                active={location.pathname === routerPath.model.create}
            >Creer un assistant</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={Users}
                href={routerPath.model.base}
                active={location.pathname === routerPath.model.base || location.pathname === routerPath.authentication.base}
            >Assistants</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={Lightbulb}
                href={routerPath.topic.base}
                active={location.pathname.startsWith(routerPath.topic.base)}
            >Sujets</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={Signpost}
                href={routerPath.approach.base}
                active={location.pathname.startsWith(routerPath.approach.base)}
            >Angles d'approche</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={Sparkles}
                href={routerPath.seed.base}
                active={location.pathname.startsWith(routerPath.seed.base)}
            >Inspirations</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={MessageCircle}
                href={routerPath.post.base}
                active={location.pathname.startsWith(routerPath.post.base)}
            >Contenu</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>

            <NavigationBarItem
                icon={SmartphoneCharging}
                href={routerPath.credit.base}
               active={location.pathname.startsWith(routerPath.credit.base)}>Credit</NavigationBarItem>
            <Divider direction={'x'} size={'xs'}/>
        </div>

        <div>
            <Logout/>
        </div>

    </Card>
};
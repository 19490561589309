import React, {useEffect} from 'react';
import {useAppModel} from "../../../../hook/useAppModel";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";

import {Card} from "../../../../component/card/Card";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {Square, SquareCheckBig, ThumbsDown} from "lucide-react";
import {approachesListsSlice, ApproachesListsSliceState} from "../../../../redux/feature/approach/approachesListsSlice";
import {updateApproachThunk} from "../../../../redux/thunk/approach/update-approach-thunk";

type ApproachListItemProps = { approach: ApproachesListsSliceState['values'][number]['values'][number]}

export const ApproachListItem = ({approach}: ApproachListItemProps) => {
    const model = useAppModel()
    const optionActionState = useAppSelector(state => state.optionActionSlice)
    const approachesListsLoaderState = useAppSelector(state => state.approachesListsSlice.loading)
    const addSeedInputLoaderState = useAppSelector(state => state.addSeedValueSlice.loading)
    const isDisabled = approachesListsLoaderState === 'loading' ||
        addSeedInputLoaderState === 'loading' ||
        optionActionState.loading === "loading";

    const dispatch = useAppDispatch();

    useEffect(() => {

        if (optionActionState.active) {
            return
        }

        if (!model.id) {
            return;
        }

        dispatch(approachesListsSlice.actions.handleUnselectAll({
            model: model.id,
        }))

    }, [dispatch,optionActionState.active, model.id]);

    const onToggleSelection = () => {
        if (isDisabled || !optionActionState.active || !model.id) {
            return;
        }

        dispatch(approachesListsSlice.actions.handleItem({
            id: model.id,
            value: {
                ...approach,
                selected: !approach.selected,
            }
        }))
    }


    return <div className={`overflow-hidden rounded-md`}>
        <div className={'w-full'}>
            <Card
                base={{
                    className: `whitespace-pre-line max-h-max w-full transition-colors ${approach.selected && optionActionState.loading === "idle" ? 'bg-blue-600 text-white hover:bg-blue-500' : ''}  ${optionActionState.active && optionActionState.loading === 'idle' ? 'cursor-pointer' : ''} ${optionActionState.loading === 'loading' ? "cursor-not-allowed" : ''} ${isDisabled ? 'text-gray-400' : ''}`,
                    onClick: onToggleSelection,
                }}

            >
                <div className="flex justify-between items-center">
                    <Paragraph className={'w-full'}>{approach.value}</Paragraph>
                    <div className={'flex items-start'}>
                        {!optionActionState.active && <SquareCheckBig className={'text-transparent'} width={15}/>}
                        {optionActionState.active && !isDisabled ? approach.selected ? <SquareCheckBig width={15}/> :
                            <Square width={15}/> : <></>}
                    </div>
                    {!optionActionState.active && <button
                        onClick={() => dispatch((dispatch, getState) => updateApproachThunk(dispatch, getState, {
                            relevant: false,
                            id: approach.id
                        }))} className={'cursor-pointer p-1'}
                    >
                        <ThumbsDown className={'w-4 h-4'}/>
                    </button>}
                </div>
            </Card>
        </div>
    </div>
};
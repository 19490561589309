import React, {useEffect} from 'react';
import {seedsListsSlice, SeedsListsSliceState} from "../../../../redux/feature/seed/seedsListsSlice";
import {Card} from "../../../../component/card/Card";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {useAppModel} from "../../../../hook/useAppModel";
import {Paragraph} from "../../../../component/paragraph/Paragraph";
import {Square, SquareCheckBig} from "lucide-react";

type SeedListItemProps = { seed: SeedsListsSliceState['values'][number]['values'][number] }

export const SeedListItem = ({seed}: SeedListItemProps) => {
    const model = useAppModel()
    const optionActionState = useAppSelector(state => state.optionActionSlice)
    const seedsListsLoaderState = useAppSelector(state => state.seedsListsSlice.loading)
    const addSeedInputLoaderState = useAppSelector(state => state.addSeedValueSlice.loading)
    const isDisabled = seedsListsLoaderState === 'loading' ||
        addSeedInputLoaderState === 'loading' ||
        optionActionState.loading === "loading";

    const dispatch = useAppDispatch();

    useEffect(() => {

        if (optionActionState.active) {
            return
        }

        if (!model.id) {
            return;
        }

        dispatch(seedsListsSlice.actions.handleUnselectAll({
            model: model.id,
        }))

    }, [dispatch,optionActionState.active, model.id]);

    const onClick = () => {
        if (isDisabled || !optionActionState.active || !model.id) {
            return;
        }

        dispatch(seedsListsSlice.actions.handleItem({
            id: model.id,
            value: {
                ...seed,
                selected: !seed.selected,
            }
        }))
    }


    return <div className={`relative overflow-hidden rounded-md`}>
        <div className={'w-full'}>
            <Card
                base={{
                    className: `whitespace-pre-line max-h-max p-4 w-full transition-colors ${seed.selected && optionActionState.loading === "idle" ? 'bg-blue-600 text-white hover:bg-blue-500' : ''}  ${optionActionState.active && optionActionState.loading === 'idle' ? 'cursor-pointer' : ''} ${optionActionState.loading === 'loading' ? "cursor-not-allowed" : ''} ${isDisabled ? 'text-gray-400' : ''}`,
                    onClick: onClick,
                }}

            >
                <div className="flex justify-between">
                    <Paragraph className={'w-full'}>{seed.content}</Paragraph>
                    <div className={'flex items-start p-3'}>
                        {!optionActionState.active && <SquareCheckBig className={'text-transparent'} width={15}  />}
                        {optionActionState.active && !isDisabled ? seed.selected ? <SquareCheckBig width={15}  /> :
                            <Square width={15} /> : <></>}
                    </div>
                </div>
            </Card>
        </div>
    </div>
};
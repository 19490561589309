import {IValidator} from "./IValidator";

type Equal = { valueA: string, valueB: string }

export class EqualValidator extends IValidator<Equal> {
    isValid(value: Equal) {
       if(value.valueA !== value.valueB) {
           this.message = 'Les mots de passes doivent être les mêmes'
           return  false;
       }

        return true;
    }
}
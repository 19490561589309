import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {ToastHelper} from "../../../helper/ToastHelper";
import {ListsHelper} from "../../../helper/lists-helper/ListsHelper";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {approachesListsSlice} from "../../feature/approach/approachesListsSlice";
import {AppRemoveApproachesClient} from "../../../http/api/app/approach/AppRemoveApproachesClient";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {approachCountSlice} from "../../feature/approach/approchCountSlice";

export const removeApproachesThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const seedsListState = state.approachesListsSlice;
    const model = state.activeModelSlice.id

    if (!model) {
        return
    }

    const seeds = ListsHelper.getList({
        lists: seedsListState.values,
        id: model,
    })

    if (
        !seeds ||
        seeds.values.length === 0 ||
        seedsListState.loading === 'loading'
    ) {
        return;
    }

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(approachesListsSlice.actions.handleLoading('loading'))

    const ids = seeds.values.filter(v => v.selected).map(t => t.id)
    const client = new AppRemoveApproachesClient();
    const response = await client.handleWithCredential({
        model: model,
        values: ids
    })

    dispatch(approachesListsSlice.actions.handleLoading('loaded'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    dispatch(approachesListsSlice.actions.handleItems({
        values: seeds.values.filter(seed => !seed.selected),
        id: model,
    }))

    dispatch(approachesListsSlice.actions.handleUnselectAll({
        model: model,
    }))

    const approachCount = ListHelper.getItem(model, state.approachCountSlice.values)
    if(approachCount &&  approachCount.count !== null) {
        dispatch(approachCountSlice.actions.handleItemValue({
            model: model,
            count: approachCount.count - response.data.count
        }))
    }


    dispatch(optionActionSlice.actions.reset())

    ToastHelper.add({
        content: `Votre assistant a retiré ${ids.length} Approche${ids.length > 1 ? 's' : ''}`,
        type: 'success',
    })

}

export const routerPath = {
    authentication: {
        base: '/',
        register: '/register',
        login: '/login',
    },
    model: {
        base: '/model',
        create: '/model/create',
        update: '/model/:id',
        createUpdateRoute : (id:string) => `/model/${id}`
    },
    account: {
        base: '/account',
    },
    credit: {
        base: '/credit',
    },
    topic: {
        base: '/topic',
    },
    post: {
        base: '/post',
    },
    seed: {
        base: '/inspiration',
    },
    approach: {
        base: '/approach',
    },
    verification: {
        base: '/verification/:account',
    }
}
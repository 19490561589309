import {ListItem} from "./ListItem";

export abstract class ListHelper {
    static addItem<T extends ListItem>(values: T[], value: T) {
        return values.concat(value)
    }

    static handleItem<T extends ListItem>(values: T[], item: T) {
        const index = values.findIndex(value => value.id === item.id)

        if (index < 0) {
            return values;
        }

        const list = [...values]

        list[index] = item;
        return list;
    }

    static getItem<T extends ListItem>(id:string, values: T[]) {
        const index = values.findIndex(value => value.id === id)

        if (index < 0) {
            return null;
        }

        return values[index]
    }
}
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {getPostsByModelThunk} from "../../../../redux/thunk/post/get-posts-by-model-thunk";
import {useAppModel} from "../../../../hook/useAppModel";
import {PostListItem} from "./PostListItem";
import {Divider} from "../../../../component/divider/Divider";
import ClipboardJS from "clipboard";
import {ToastHelper} from "../../../../helper/ToastHelper";
import {PostListLoader} from "./PostListLoader";
import {NoPost} from "./NoPost";
import {ListView} from "../../../../component/list-view/ListView";

type PostsListProps = {}

export const PostsList = (props: PostsListProps) => {
    const model = useAppModel()
    const model_ = model.id
    const lists = useAppSelector(state => state.postsListsSlice.lists)
    const list = lists.find(list => list.model === model.id)

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!model_) return;
        dispatch((dispatch, getState) => getPostsByModelThunk(dispatch, getState, {
            model: model_,
        }))
    }, [dispatch, model]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        const clipboard = new ClipboardJS('.btn');

        clipboard.on('success', (e) => {
            ToastHelper.add({
                type: 'success',
                content: 'Copié avec succès'
            })
        });
        clipboard.on('error', (e) => {
            ToastHelper.add({
                type: 'warning',
                content: 'Impossible de copier'
            })
        });

        return () => {
            clipboard.destroy()
        }
    }, [dispatch]);

    if(!model_) return <></>

    return <ListView>
        {list?.posts.map((post, index) => <div key={post.id}>
            <PostListItem post={post}  scrollable={false}/>
            <Divider direction={'x'} size={'xs'}/>
        </div>)}


        <NoPost list={list} />

        <PostListLoader />
    </ListView>
};
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {getCreditCountThunk} from "../../../../redux/thunk/credit/get-credit-count-thunk";
import {Loader} from "../../../../component/loader/Loader"
type CreditCountProps = {}

export const CreditCount = (props: CreditCountProps) => {
    const creditState = useAppSelector(state => state.creditCountSlice)
    const dispatch  = useAppDispatch();

    useEffect(() => {
        dispatch(getCreditCountThunk)
    }, [dispatch]);

    if(creditState.loading !== 'loaded') {
        return <Loader />
    }

    if(creditState.value === null) {
        return <span>Error</span>
    }
    return <span>{creditState.value}</span>
};
import React from 'react';
import {AddSeedInput} from "./component/AddSeedInput";
import {SeedsList} from "./component/SeedsList";
import {Alert} from "../../../component/alert/Alert";
import {Divider} from "../../../component/divider/Divider";

type InspirationPageProps = {}

export const SeedsPage = (props: InspirationPageProps) => {

    return <div>
        <Alert
            type={'neutral'}
            title={'Inspiration'}
            message={"Donnez ici du contenu à décortiquer à votre assistant pour que celui-ci s'adapte à des styles spécifiques"}
        />
        <Divider direction={'x'} size={'xs'}/>
        <AddSeedInput/>
        <SeedsList/>
    </div>
};
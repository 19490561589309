import React from 'react';
import {Input} from "../../../../component/input/Input";
import {Divider} from "../../../../component/divider/Divider";
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {addOneTopicModelThunk} from "../../../../redux/thunk/topic/add-one-topic-model-thunk";
import {TopicValidator} from "../../../../validator/TopicValidator";
import {manualTopicSlice} from "../../../../redux/feature/topic/manualTopicValueSlice";
import {Dialog} from "../../../../component/dialog/Dialog";
import {Button} from "../../../../component/button/Button";
import {Loader} from "../../../../component/loader/Loader";

type AddTopicInputProps = {}

export const AddTopicInput = (props: AddTopicInputProps) => {
    const model = useAppSelector(state => state.activeModelSlice.id);
    const topicLoaderState = useAppSelector(state => state.topicsListsSlice.loading);
    const manualTopicState = useAppSelector(state => state.manualTopicSlice)
    const selectable = useAppSelector(state => state.topicsListsSlice.selectable)

    const dispatch = useAppDispatch();

    const loading = manualTopicState.loading === "loading"
    const isInputDisabled = model === null || manualTopicState.loading === 'loading' || topicLoaderState === "loading" || selectable;
    const isButtonDisabled = !model ||
        manualTopicState.value === null ||
        manualTopicState.value.length === 0 ||
        manualTopicState.loading === 'loading' ||
        topicLoaderState === "loading" || selectable;
    ;

    let errorMessage = null;
    const validator = new TopicValidator()

    if (manualTopicState.value !== null && !validator.isValid(manualTopicState.value)) {
        errorMessage = validator.getMessage()
    }


    const onSubmitManualTopic = () => {
        if (isInputDisabled || isButtonDisabled) return;
        dispatch(addOneTopicModelThunk)
    }

    if(!model) {
        return <></>
    }

    return <div className={'mr-4'}>
        <div className={'flex justify-end'}>
            <Dialog
                dialogCnProps={{
                    onOpenChange: (open) => dispatch(manualTopicSlice.actions.handleOpen(open)),
                    open: manualTopicState.open
                }}
                title={"Sujet"}
                trigger={{
                    label: "Ajouter un sujet", props: {
                        className: 'font-xs font-light'
                    }
                }}
            >
                <Input
                    custom={{
                        label: 'Une idée de sujet ? Ajoutez le',
                        errorMessage: errorMessage
                    }}
                    base={{
                        disabled: isInputDisabled,
                        placeholder: "Exemple: Experience sociale",
                        name: 'new-topic',
                        className: 'w-full',
                        onChange: (e) => dispatch(manualTopicSlice.actions.handleValue(e.target.value)),
                        value: manualTopicState.value || '',
                        onKeyUp: (e) => e.key.toLowerCase() === "enter" ? onSubmitManualTopic() : undefined
                    }}
                />

                <Divider direction={'x'} size={"xs"}/>
                <div>
                    <Button
                        base={{
                            onClick: () => onSubmitManualTopic(),
                            disabled: isButtonDisabled,
                        }}
                        custom={{color: 'neutral'}}
                    >
                        {loading ? <Loader/> : "Submit"}
                    </Button>
                </div>
            </Dialog>

        </div>

        <Divider direction={'x'} size={'md'}/>

        {/*<Card className={'flex p-4'}>*/}
        {/*    <div className={'w-full'}>*/}
        {/*        <Input*/}
        {/*            custom={{*/}
        {/*                label: 'Une idée de sujet ? Ajoutez le',*/}
        {/*                errorMessage: errorMessage*/}
        {/*            }}*/}
        {/*            base={{*/}
        {/*                disabled: isInputDisabled,*/}
        {/*                placeholder: "Exemple: Experience sociale",*/}
        {/*                name: 'new-topic',*/}
        {/*                className: 'w-full',*/}
        {/*                onChange: (e) => dispatch(manualTopicSlice.actions.handValue(e.target.value)),*/}
        {/*                value: manualTopicState.value || '',*/}
        {/*                onKeyUp: (e) => e.key.toLowerCase() === "enter" ? onSubmitManualTopic() : undefined*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*    <div className={'pb-1 pl-1'}>*/}
        {/*        <div className={'h-6'}></div>*/}
        {/*        <button*/}
        {/*            className={`rounded-md p-1.5 pb-0.5 mt-2  ${isButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 cursor-pointer'}`}*/}
        {/*            disabled={isButtonDisabled}*/}
        {/*            onClick={onSubmitManualTopic}*/}
        {/*        >*/}
        {/*            <LucideArrowUp color={'white'}/>*/}
        {/*        </button>*/}
        {/*    </div>*/}
        {/*</Card>*/}
        {/*<Divider direction={'x'} size={'sm'}/>*/}
    </div>
};
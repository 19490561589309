import React from 'react';
import {LucideProps, User2} from "lucide-react";
import * as react from "react";
import {ListItemModel} from "../../../../model/Model";
import {activeModelSlice} from "../../../../redux/feature/model/activeModelSlice";
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../../../redux/store";

type CountProps = {
    icon: react.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & react.RefAttributes<SVGSVGElement>>
    count: number | null
    model: ListItemModel
    label: {
        prefix: string;
        plural: string;
        singular: string;
        zeroValue: string
    },
    isLoading: boolean
    link: string;
}

export const CountLinkAction = (props: CountProps) => {
        const dispatch = useAppDispatch()

        if (props.isLoading || props.count === null) {
            return <div className={'h-5 w-full bg-gray-100 animate-pulse rounded mb-1'}></div>
        }

        let label: string;

        if (props.count === 0) {
            label = props.label.zeroValue;
        } else if (props.count === 1) {
            label = `${props.label.prefix} ${props.count} ${props.label.singular}`
        } else {
            label = `${props.label.prefix} ${props.count} ${props.label.plural}`;
        }

        return <p className={'m-0 flex items-center'}>
            <props.icon className={`${props.count === 0 ? 'text-red-600' : ''} w-4`}/>
            <span className={'mx-1'}></span>
            <Link to={props.link}
                  onClick={() => dispatch(activeModelSlice.actions.handleModel(props.model.id))}
                  className={`${props.count === 0 ? 'text-red-600' : ''} text-sm mb-0.5`}
            >{label}</Link>
        </p>
    }
;
import React, {useEffect} from 'react';
import {Theme} from "./component/Theme";
import {Gender} from "./component/Gender";
import {SubmitModelUpdate} from "./component/SubmitModelUpdate";
import {useAppDispatch} from "../../../redux/store";
import {modelSlice} from "../../../redux/feature/model/modelSlice";
import {useLoaderData} from "react-router";
import {Params} from "@remix-run/router/utils";
import {AppGetModelClient} from "../../../http/api/app/model/AppGetModelClient";
import {isAppErrorResponse, isAppSuccessResponse} from "../../../http/api/app/AppResponse";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ListItemModel} from "../../../model/Model";
import {Name} from "./component/Name";
import {NoCredit} from "./component/NoCredit";
import {useAppCredit} from "../../../hook/useAppCredit";
import {Suffix} from "./component/Suffix";
import {ListView} from "../../../component/list-view/ListView";

type UpdateModelPageProps = {}

export async function loader({id}: Params) {
    if (id) {
        const client = new AppGetModelClient();
        const response = await client.handleWithCredential({
            id: id,
        })

        if (isAppErrorResponse(response)) {
            if (response.code === AppCodeException.MODEL_NOT_FOUND_FOR_USER) {
                throw new Response("Not Found", {status: 404, statusText: 'Assistant not found'});
            }
        }
        if (isAppSuccessResponse(response)) {
            return response.data.value;
        }

        throw new Response("Not Found", {status: 500, statusText: 'An error was occured'});
    }
    
    return null;
}

export const UpdateModelPage = (props: UpdateModelPageProps) => {
    const model = useLoaderData() as Required<ListItemModel> | null
    const credit = useAppCredit()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!model) {
            dispatch(modelSlice.actions.reset())
            return;
        }

        dispatch(modelSlice.actions.handleModel({
            id: model.id,
            theme: model.theme,
            platform: model.platform,
            gender: model.gender,
            thou: model.thou,
            name: model.name,
            createdAt: model.createdAt,
            suffix: model.suffix,
        }))

    }, [dispatch, model]);

    return <div>

        {credit.isLoaded && credit.noCredit && <NoCredit/>}

        <ListView>
            {credit.isLoaded && credit.hasCredit && <>
                <Name/>
                <Gender/>
                <Theme/>
                <Suffix/>
                {/*<FurtherInformation />*/}
                {/*<Platform/>*/}
                <SubmitModelUpdate/>
            </>}
        </ListView>

    </div>
};
import {AppClient} from "../AppClient";

export const READ_COUNT_ENTITIES = [
    'model',
    'post',
    'topic',
    'seed',
    'approach',
    'furtherInformation',
] as const;

export type ReadCountEntityType =  typeof READ_COUNT_ENTITIES[number]

type InputData = {
    model: string
    entity: ReadCountEntityType;
};

type OutputData = {
    value:  number;
};

export class AppReadEntityCountClient extends AppClient<InputData, OutputData> {
    async request(input: InputData) {
        return await fetch(this.buildEndpoint(`count?model=${input.model}&entity=${input.entity}`), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


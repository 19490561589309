import {IValidator} from "./IValidator";

export class ModelNameValidator extends IValidator<string> {
    isValid(value: string) {
        if (value.trim().length === 0) {
            this.message = 'Valeur requise';
            return false;
        }

        if(!value.match(/^[a-zA-Z0-9_-]+$/)) {
            this.message = 'Format incorrect caractères autorisés a-zA-Z0-9_-'
            return false;
        }

        const max = 255;
        if (value.length > max) {
            this.message = `Maximum autorisé ${max} caractères`;
            return false;
        }

        return true;
    }
}
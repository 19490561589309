import {AppClient} from "../AppClient";
import {ListItemModel} from "../../../../model/Model";

type OutputData = {
    values: ListItemModel[];
};

export class AppListModelsClient extends AppClient<undefined, OutputData> {
    async request() {
        return await fetch(this.buildEndpoint('ai-model'), {
            method: 'GET',
            headers: this.headers,
        })
    }
}


import React from 'react';
import {FormContainer} from "component/form-container/FormContainer";
import {Divider} from "component/divider/Divider";
import {EmailInput} from "../auth/component/EmailInput";
import {UsernameInput} from "../auth/component/UsernameInput";
import {PasswordInput} from "../auth/component/PasswordInput";
import {PasswordConfirm} from "../auth/component/PasswordConfirm";
import {SubmitFormButton} from "./component/SubmitFormButton";
import {Title} from "component/title/Title";
import {Paragraph} from "component/paragraph/Paragraph";
import {Link} from "component/link/Link";
import {routerPath} from "../../routerPath";
import {useAppDispatch} from "../../../redux/store";
import {passwordSlice} from "../../../redux/feature/auth/passwordSlice";
import {passwordConfirmSlice} from "../../../redux/feature/auth/passwordConfirmSlice";

export const RegisterPage = () => {
    const dispatch = useAppDispatch();

    const resetFields = () => {
        dispatch(passwordSlice.actions.reset())
        dispatch(passwordConfirmSlice.actions.reset())
    }
    return <div>
        <Title className={'text-center'}>Creer un compte</Title>


        <Paragraph className={'text-center'}>Bienvenue sur Anexdot, dites moi de quoi parler et je le ferais pour vous tous les jours</Paragraph>

        <Divider direction={'x'} size={'md'}/>

        <FormContainer>
            <EmailInput/>
            <Divider direction={'x'} size={'sm'}/>

            <UsernameInput/>
            <Divider direction={'x'} size={'sm'}/>

            <PasswordInput autoComplete={'new-password'}/>
            <Divider direction={'x'} size={'sm'}/>

            <PasswordConfirm/>
            <Divider direction={'x'} size={'sm'}/>

            <SubmitFormButton/>
            <Divider direction={'x'} size={'sm'}/>

            <div className={'center'}>
                <span className={'mr-2'}>Vous avez déjà un compte ?</span>
                <Link onClick={resetFields} href={routerPath.authentication.login}> Se connecter</Link>
            </div>
        </FormContainer>

    </div>
};
export abstract class StringHelper {
    static firstUpper(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    static truncate(value: string, count: number, options: {
        addDots?: boolean,
        concatWith?: string,
    }): string {
        if (value.length > count) {
            const v = value.slice(0, count)
            const append = options?.concatWith || "..."
            return options?.addDots ? v + append : v;
        }
        return value;
    }


    static hasEmoji(value?: string) {
        if(!value) {
            return null;
        }
        return value.match(/\p{Emoji}/gu) !== null
    }
}
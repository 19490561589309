import {createSlice, PayloadAction} from '@reduxjs/toolkit'


export interface ActiveModelSliceState {
    loading: "idle" | 'loading' | 'loaded';
    id: string | null
}

const initialState: ActiveModelSliceState = {
    loading: 'idle',
    id: null,
}

export const activeModelSlice = createSlice({
    name: 'activeModel',
    initialState: initialState,
    reducers: {
        handleModel: (state, action: PayloadAction<ActiveModelSliceState['id']>) => {
            state.id = action.payload
        },
        handleLoading: (state, action: PayloadAction<ActiveModelSliceState['loading']>) => {
            state.loading = action.payload
        },
        reset: () => initialState
    },
})
import {AppDispatch, RootState} from "../../store";
import {isAppErrorResponse} from "../../../http/api/app/AppResponse";
import {CreditService} from "../../../service/credit/CreditService";
import {creditCountSlice} from "../../feature/credit/creditCountSlice";
import {CreditCountStorage} from "../../../service/storage/CreditCountStorage";
import {AppCodeException} from "../../../type/exception/AppCodeException";
import {ToastHelper} from "../../../helper/ToastHelper";
import {approachesListsSlice} from "../../feature/approach/approachesListsSlice";
import {optionActionSlice} from "../../feature/optionActionSlice";
import {AppGenerateApproachesClient} from "../../../http/api/app/approach/AppGenerateApproachesClient";
import {ListHelper} from "../../../helper/list-helper/ListHelper";
import {approachCountSlice} from "../../feature/approach/approchCountSlice";

export const generateApproachesThunk = async (
    dispatch: AppDispatch,
    getState: () => RootState,
) => {
    const state = getState();
    const approachListsState = state.approachesListsSlice;
    const model = state.activeModelSlice.id

    if (model === null) {
        return;
    }

    const approachCountState = state.topicCountSlice

    dispatch(optionActionSlice.actions.handleLoading('loading'))
    dispatch(approachesListsSlice.actions.handleLoading('loading'))

    const client = new AppGenerateApproachesClient();
    const response = await client.handleWithCredential({
        model: model,
    })

    dispatch(approachesListsSlice.actions.handleLoading('loaded'))
    dispatch(optionActionSlice.actions.handleLoading('idle'))

    if (isAppErrorResponse(response)) {
        let message: string = 'Une erreur est survenue';

        if (response.code === AppCodeException.INSUFFICIENT_CREDIT) {
            message = 'Credit insuffisant';
        }

        ToastHelper.add({
            content: message,
            type: 'warning',
        })
        return;
    }

    const creditCount = state.creditCountSlice.value;
    if (creditCount) {
        const creditService = new CreditService(creditCount);
        creditService.consumeApproachCreation();
        const newCountValue = creditService.getCount();
        dispatch(creditCountSlice.actions.handleValue(newCountValue))
        new CreditCountStorage().saveData({
            value: newCountValue,
        })
    }

    const approaches = approachListsState.values.find(list => list.id === model)?.values || []
    const newApproaches = response.data.values.map(t => ({
        ...t,
        model: model,
        selected: false,
    }))

    dispatch(approachesListsSlice.actions.handleItems({
        id: model,
        values: [...newApproaches, ...approaches]
    }))

    const currentCount = ListHelper.getItem(model, approachCountState.values)
    if(currentCount && currentCount.count) {
        dispatch(approachCountSlice.actions.handleItemValue({
            model : model,
            count : currentCount.count + response.data.values.length,
        }))
    }

    const count = response.data.values.length
    ToastHelper.add({
        content: `${count} Angle${count > 1 ? 's' : ''} crée${count > 1 ? 's' : ''}`,
        type: 'success',
    })
}
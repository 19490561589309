import {Title} from "component/title/Title";
import {Paragraph} from "component/paragraph/Paragraph";
import {FormContainer} from "component/form-container/FormContainer";
import {EmailInput} from "../auth/component/EmailInput";
import {Divider} from "component/divider/Divider";
import {PasswordInput} from "../auth/component/PasswordInput";
import {Link} from "component/link/Link";
import React from "react";
import {SubmitFormButton} from "./component/SubmitFormButton";
import {routerPath} from "../../routerPath";
import {passwordSlice} from "../../../redux/feature/auth/passwordSlice";
import {passwordConfirmSlice} from "../../../redux/feature/auth/passwordConfirmSlice";
import {useAppDispatch} from "../../../redux/store";

export const LoginPage = () => {
    const dispatch = useAppDispatch();


    const resetFields = () => {
        dispatch(passwordSlice.actions.reset())
        dispatch(passwordConfirmSlice.actions.reset())
    }
    return <div>
        <Title className={'text-center'}>Se connecter</Title>

        <Divider direction={'x'} size={'sm'}/>

        <Paragraph className={'text-center p-0 m-0'}>Bienvenue sur Anexdot, connectez-vous pour augmenter votre
            productivité</Paragraph>

        <Divider direction={'x'} size={'md'}/>

        <FormContainer>
            <EmailInput/>
            <Divider direction={'x'} size={'sm'}/>

            <PasswordInput autoComplete={'current-password'}/>
            <Divider direction={'x'} size={'sm'}/>

            <SubmitFormButton/>
            <Divider direction={'x'} size={'sm'}/>

            <div className={'center'}>
                <span className={'mr-2'}>Pas encore de compte ?</span><Link onClick={resetFields} href={routerPath.authentication.register}>Créer un
                compte</Link>
            </div>
        </FormContainer>
    </div>
}
import {AppClient} from "../AppClient";

type Input = {
    id: string;
    relevant?: boolean
    content?: string,
    publishedAt?: Date|null,
}

type OutputData = {
    value: {
        relevant?: boolean,
        content?: string,
        publishedAt?: Date|null,
    }
};

export class AppUpdatePostClient extends AppClient<Input, OutputData> {
    async request(params: Input) {
        return await fetch(this.buildEndpoint('post'), {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(params),
        })
    }
}

